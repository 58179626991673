import PropTypes from "prop-types";

import useSlider from "../../../../../../utils/hooks/useSlider";

import OrderItem from "../OrderItem";
import CustomArrowButton from "../../../../../../components/CustomArrowButton";

import cn from "classnames";
import styles from "./styles.module.scss";

const OrderItemsSlider = (props) => {
  const {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext,
  } = useSlider({
    shouldBeLooped: true,
    shouldAutoSwitched: false,
  });

  return (
    <>
      <div ref={sliderRef} className={cn("keen-slider")}>
        {props.offers.map((item, idx) => {
          return (
            <OrderItem
              key={`${item.id}-${idx}`}
              item={item}
              amount={item.count}
              containerStyles="keen-slider__slide"
            />
          );
        })}
      </div>
      <div className={styles.pagination}>
        {isLoaded && instanceRef.current && (
          <CustomArrowButton
            duration="left"
            className={cn(styles.arrow, styles["arrow--left"])}
            onClick={onClickPrev}
          />
        )}
        <div className={styles.dots}>
          {availableDots.map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={cn(styles.dot, {
                  [styles["dot--active"]]: currentSlide === idx,
                  [styles["dot--small"]]: props.offers?.length > 6,
                })}
              >
              </button>
            );
          })}
        </div>
        {isLoaded && instanceRef.current && (
          <CustomArrowButton
            duration="right"
            className={cn(styles.arrow, styles["arrow--right"])}
            onClick={onClickNext}
          />
        )}
      </div>
    </>
  );
};

const propTypes = {
  offers: PropTypes.array.isRequired,
};

OrderItemsSlider.propTypes = propTypes;

export default OrderItemsSlider;