import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { productsApi } from "../../api/productsApi";

import { getQueryStringForRequest } from "../../utils/helpers/catalogPageHelper";
import useCatalogFilters from "./hooks/useCatalogFilters";
import useCatalogPagination from "./hooks/useCatalogPagination";
import useSearchParamsHandler from "../../utils/hooks/useSearchParamsHandler";
import { toggleSearchTrigger } from "../../store/catalog";
import useCatalogProducts from "./hooks/useCatalogProducts";

import CatalogContent from "./components/CatalogContent";
import LastProductsViewBlock from "./components/LastProductsViewBlock";
import DesktopSelectedFilters from "./components/CatalogFilters/DesktopSelectedFilters";
import DesktopFilterSection from "./components/CatalogFilters/DesktopFilterSection";
import SortButton from "./components/SortButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import MobileSelectedFiltersButton from "./components/MobileSelectedFiltersButton";
import { HeadHelmet } from "../../utils";
import { meta } from "./metaDataCatalog";
import { h1TextVariables } from "./h1TextVariables";

import cn from "classnames";
import styles from "./styles.module.scss";

const TITLES = {
  kosmetika: "Косметика",
  parfumeria: "Парфюмерия",
};

const SCROLL_OFFSET_TOP = 100;

const Catalog = () => {
  const catalog = useSelector(state => state.catalog);
  const deviceType = useSelector(state => state.user.deviceType);
  const maxProductsPerPage = deviceType.isMobile ? 10 : 18;
  const isNotMobileDevice = !deviceType.isMobile && !deviceType.isTablet;
  const dispatch = useDispatch();
  const [ metaData, setMetaData ] = useState({ title: "", description: "" });
  const [ h1Text, setH1Text ] = useState("");

  const { searchParams, onSaveParams, onChangeSearchParams } =
    useSearchParamsHandler();

  useCatalogFilters();

  const {
    products,
    productsAmount,
    isLoadingProducts,
    handleSetProducts,
    handleChangeShouldGetProductsStatus,
  } = useCatalogProducts();

  const {
    limit,
    offset,
    currentPage,
    onChangeOffset,
    onChangeCurrentPage,
    onResetCurrentPageAndOffset,
  } = useCatalogPagination({
    initLimit: +searchParams.get("limit") || maxProductsPerPage,
    initOffset: +searchParams.get("offset") || 0,
  });

  const contentRef = useRef(null);

  const onScrollContentToTop = useCallback(() => {
    const contentOffsetTop = contentRef?.current.offsetTop;
    window.scrollTo({
      top: contentOffsetTop - SCROLL_OFFSET_TOP,
      behavior: "smooth",
    });
  }, []);

  const searchOptionsForRequest = useMemo(() => {
    return {
      ...catalog.selectedFilters,
      limit: String(limit),
      offset: String(offset),
      "sort_by": catalog.additionalParams["sort_by"],
      "order_by": catalog.additionalParams["order_by"],
      categories: catalog.category,
      "min_price": catalog.additionalParams["min_price"],
      "max_price": catalog.additionalParams["max_price"],
    };
  }, [
    catalog.filters,
    limit,
    offset,
    catalog.additionalParams,
    catalog.category,
  ]);

  const breadcrumbsValues = useMemo(() => {
    if (!catalog.category) {
      return [];
    }

    return [
      {
        value: TITLES[catalog.category],
        link: `/catalog/${catalog.category}`,
      },
    ];
  }, [catalog.category]);

  useEffect(() => {
    const getFiltersOnPopState = () => {
      dispatch(toggleSearchTrigger());
    };

    window.addEventListener("popstate", getFiltersOnPopState);
    return () => {
      window.removeEventListener("popstate", getFiltersOnPopState);
    };
  }, []);

  useEffect(() => {
    if ((products?.length <= 6 && products && isNotMobileDevice)) {
      onScrollContentToTop();
    } else if (!products && isNotMobileDevice) {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }
  },[products]);

  const handleChangePage = page => {
    const offsetValue = page * limit - limit;
    const shouldRemoveParams = page === 1 && limit === maxProductsPerPage;

    onChangeCurrentPage(page);
    onChangeOffset(offsetValue);
    onChangeSearchParams({
      limit: shouldRemoveParams ? undefined : [limit],
      offset: shouldRemoveParams ? undefined : [offsetValue],
    });
    onScrollContentToTop();
    dispatch(toggleSearchTrigger());
  };

  const handleShowMoreProducts = async () => {
    try {
      const newOffset = offset + limit;

      if (productsAmount < newOffset) return;

      const options = {
        ...searchOptionsForRequest,
        offset: String(newOffset),
      };

      onChangeCurrentPage(currentPage + 1);
      onChangeOffset(newOffset);
      onSaveParams({
        offset: newOffset,
      });

      const requestString = getQueryStringForRequest(options);
      const data = await productsApi.getCatalogWithSearchOptions({
        requestString,
      });

      if (data.meta.error) {
        throw new Error();
      }

      handleSetProducts(prev => [...prev, ...data.response.items]);
    } catch (err) {
      // TODO
    }
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    const pathnameMeta = meta[currentUrl.pathname.replace(/\/$/, "")];
    let title = [];
    let description = [];

    if (pathnameMeta && pathnameMeta.root) {
      title.push(pathnameMeta.root.title);
      description.push(pathnameMeta.root.description);
    }

    queryParams.forEach((value, key) => {
      if (pathnameMeta) {
        if (pathnameMeta[key]) {
          title.push(pathnameMeta[key].title);
          description.push(pathnameMeta[key].description);
        }
        if (pathnameMeta[`${key}=${value}`]) {
          title.push(pathnameMeta[`${key}=${value}`].title);
          description.push(pathnameMeta[`${key}=${value}`].description);
        }
      }
    });

    const categoryCounts = products && products.length > 0 ? products.reduce((acc, product) => {
      const categoryName = product.category ? product.category.name : "";
      acc[categoryName] = (acc[categoryName] || 0) + 1;
      return acc;
    }, {}) : {};

    const categories = Object.keys(categoryCounts);
    let categoryText = "Продукция";
    if (categories.length === 1) {
      categoryText = categories[0];
    } else if (categories.length > 1) {
      const uniqueCategories = new Set(products.map(product => product.category ? product.category.name : ""));
      if (uniqueCategories.size === 1) {
        categoryText = uniqueCategories.values().next().value;
      }

    }

    title = title.map(item => item.replace("{category}", categoryText));
    description = description.map(item => item.replace("{category}", categoryText));

    const brandName = products?.[0]?.brands?.[0]?.brand?.name;
    if (brandName) {
      title = title.map(item => item.replace("{brand}", brandName));
      description = description.map(item => item.replace("{brand}", brandName));
    }

    let maxDiscount = 0;
    if (isOnSalePage && products) {
      products.forEach(product => {
        if (product.offers) {
          product.offers.forEach(offer => {
            if (offer.percent > maxDiscount) {
              maxDiscount = offer.percent;
            }
          });
        }
      });
    }

    title = title.map(item => item.replace("{percent}", maxDiscount.toString()));
    description = description.map(item => item.replace("{percent}", maxDiscount.toString()));

    if (pathnameMeta && pathnameMeta.title && pathnameMeta.description) {
      title = [pathnameMeta.title.replace("{percent}", maxDiscount)];
      description = [pathnameMeta.description.replace("{percent}", maxDiscount)];
    }

    const seria = queryParams.get("seria");
    if (seria) {
      const seriaCapitalized = seria.charAt(0).toUpperCase() + seria.slice(1);
      title = title.map(item => item.replace("{seria}", seriaCapitalized));
      description = description.map(item => item.replace("{seria}", seriaCapitalized));
    }

    const finalTitle = title.length > 0 ? title[title.length - 1] : "";
    const finalDescription = description.length > 0 ? description[description.length - 1] : "";

    setMetaData({ title: finalTitle, description: finalDescription });

  }, [products]);

  const isOnSalePage = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("badges") === "sale";
  }, [window.location.search]);

  useEffect(() => {

    if (!products || products.length === 0) return;

    const currentUrl = new URL(window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    const pathnameText = h1TextVariables[currentUrl.pathname.replace(/\/$/, "")];
    let h1Texts = [];

    if (pathnameText && pathnameText.root) {
      h1Texts.push(pathnameText.root.text);
    }

    queryParams.forEach((value, key) => {
      if (pathnameText) {
        if (pathnameText[key]) {
          h1Texts.push(pathnameText[key].text);
        }
        if (pathnameText[`${key}=${value}`]) {
          h1Texts.push(pathnameText[`${key}=${value}`].text);
        }
      }
    });

    const categoryCounts = products && products.length > 0 ? products.reduce((acc, product) => {
      const categoryName = product.category ? product.category.name : "";
      acc[categoryName] = (acc[categoryName] || 0) + 1;
      return acc;
    }, {}) : {};

    const categories = Object.keys(categoryCounts);
    let categoryText = "";
    if (categories.length === 1) {
      categoryText = categories[0] === "Косметика" ? "Косметика" : "Парфюмерия";
    } else if (categories.length > 1) {
      categoryText = "Оригинальные товары";
    }

    h1Texts = h1Texts.map(item => item.replace("{title}", categoryText));

    const brandName = products?.[0]?.brands?.[0]?.brand?.name;
    if (brandName) {
      h1Texts = h1Texts.map(item => item.replace("{brand}", brandName));
    }

    const seria = queryParams.get("seria");
    if (seria) {
      const seriaCapitalized = seria.charAt(0).toUpperCase() + seria.slice(1);
      h1Texts = h1Texts.map(item => item.replace("{seria}", seriaCapitalized));
    }

    const finalH1Text = h1Texts.length > 0 ? h1Texts[h1Texts.length - 1] : "";

    setH1Text(finalH1Text);

  }, [products, isOnSalePage]);

  return (
    <>
      <HeadHelmet title={metaData.title} description={metaData.description}/>
      <main className={styles.root}>
        <div className={styles.root__head}>
          {breadcrumbsValues.length > 0 && TITLES[catalog.category] && !isOnSalePage ? (
            <>
              <Breadcrumbs
                breadcrumbs={breadcrumbsValues}
                containerStyles={styles.breadcrumbs}
                showArrow
              />
              <h1 className={styles.seo}>
                {h1Text}
              </h1>
              <h2 className={styles.title}>{TITLES[catalog.category]}</h2>
            </>
          ) : (
            <h1 className={styles.seo}>{h1Text}</h1>
          )}
          <div
            className={cn(styles["selected-filters"], {
              [styles["selected-filters--gereral-page"]]:
                !TITLES[catalog.category],
            })}
          >
            {isNotMobileDevice ? (
              <DesktopSelectedFilters
                onResetCurrentPageAndOffset={onResetCurrentPageAndOffset}
              />
            ) : (
              <MobileSelectedFiltersButton
                handleChangeShouldGetProductsStatus={
                  handleChangeShouldGetProductsStatus
                }
              />
            )}
            <SortButton />
          </div>
        </div>
        <section
          className={cn(styles["content-wrapper"], {
            [styles["content-wrapper--empty"]]: !products?.length,
          })}
          ref={contentRef}
        >
          {isNotMobileDevice && (
            <DesktopFilterSection filtersFromRequest={catalog.filters}/>
          )}
          <CatalogContent
            productAmount={productsAmount}
            currentPage={currentPage}
            limit={limit}
            onClickChangePage={handleChangePage}
            onClickShowMoreProducts={handleShowMoreProducts}
            products={products}
            isInit={isLoadingProducts}
          />
        </section>
        <LastProductsViewBlock />
      </main>
    </>
  );
};

export default Catalog;
