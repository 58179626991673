import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";

import { Children } from "react";
import Portal from "../../../HOC/Portal";

const RedirectToOldVersionModal = (props) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__container} ref={props.modalRef}>
        {Children.map(props.children, (child) => (
          <div>{child}</div>
        ))}
      </div>
      <div className={styles.modal__background} />
    </div>
  );
};

const propTypes = {
  children: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  url: PropTypes.string,
};

RedirectToOldVersionModal.propTypes = propTypes;

export default Portal(RedirectToOldVersionModal);
