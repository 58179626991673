import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { productsApi } from "../../../api/productsApi";
import {
  getQueryStringForRequest,
  getTransformedValuesFromQuery,
} from "../../../utils/helpers/catalogPageHelper";
import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";

const useCatalogProducts = () => {
  const catalog = useSelector((state) => state.catalog);
  const deviceType = useSelector((state) => state.user.deviceType);

  const maxProductsPerPage = deviceType.isMobile ? 10 : 18;

  const [products, setProducts] = React.useState([]);
  const [productsAmount, setProductsAmount] = React.useState(maxProductsPerPage);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState(false);
  const location = useLocation();
  const { searchParams } = useSearchParamsHandler();

  const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);

  const abortController = React.useRef(null);
  const shouldGetProducts = React.useRef(true);

  React.useEffect(() => {
    if (deviceType.isMobile && !shouldGetProducts.current) {
      return;
    }

    (async () => {
      try {
        abortController.current = new AbortController();
        setIsLoadingProducts(true);

        const params = Object.fromEntries(searchParams);
        const options = getTransformedValuesFromQuery(params);

        const requestString = getQueryStringForRequest({
          ...options,
          limit: options.limit ?? String(maxProductsPerPage),
          offset: options.offset ?? "0",
          "sort_by": catalog.additionalParams["sort_by"],
          "order_by": catalog.additionalParams["order_by"],
          categories: currentMatch?.[0] ?? undefined,
        });

        const data = await productsApi.getCatalogWithSearchOptions({
          requestString,
          signal: abortController.current.signal,
        });

        setProducts(data.response.items);
        setProductsAmount(data.response.meta.total);
        setIsLoadingProducts(false);
      } catch (err) {
        // TODO
        if (err.message !== "The user aborted a request.") setIsLoadingProducts(false);
      }
    })();

    return () => {
      abortController.current.abort();
    };
  }, [catalog.searchTrigger]);

  const handleSetProducts = React.useCallback((products) => {
    setProducts(products);
  }, []);

  const handleChangeProductsAmount = React.useCallback((amount) => {
    setProductsAmount(amount);
  }, []);

  const handleChangeShouldGetProductsStatus = React.useCallback((value) => {
    shouldGetProducts.current = value;
  }, []);

  return {
    products,
    productsAmount,
    isLoadingProducts,
    handleSetProducts,
    handleChangeProductsAmount,
    handleChangeShouldGetProductsStatus,
  };
};

export default useCatalogProducts;
