import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import arrow from "../../assets/icons/svg/arrow.svg";
import SliderWithBanners from "./components/SliderWithBanners";
import StoriesBlock from "./components/StoriesBlock";
import DiscountsBlock from "./components/DiscountsBlock";
import PopularBlock from "./components/PopularBlock";
import TrendsBlock from "./components/TrendsBlock";
import ProfitBlock from "./components/ProfitBlock";
import HitsBlock from "./components/HitsBlock";
import ShoppingAdvantageBlock from "./components/ShoppingAdvantageBlock";
import Reviews from "./components/Reviews";
import AboutUsBlock from "./components/AboutUsBlock";
import OurNews from "./components/OurNews";

import { HeadHelmet } from "../../utils";

import styles from "./styles.module.scss";

const MainPage = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0, rotate: "0deg" });
  const refCursorItem = useRef(null);
  const { deviceType } = useSelector((state) => state.user);
  const mataTitle = "Оригинальная парфюмерия и косметика Chanel, Dior, Guerlain | Parfumart.ru";

  return (
    <>
      <HeadHelmet title={mataTitle} />
      <h1 className={styles.seo}>
      Parfumart.ru: оригинальная парфюмерия и косметика с доставкой по России
      </h1>
      <main className={styles.root}>
        <img src={arrow} ref={refCursorItem} className={styles.follow} style={{ transform: `translate(${mousePosition.x}px, ${mousePosition.y}px) rotate(${mousePosition.rotate})` }} />
        <SliderWithBanners refCursorItem={refCursorItem} setMousePosition={setMousePosition} />
        {process.env.REACT_APP_COMPONENT_ENABLE_STORIES !== "false" && (
          <StoriesBlock />
        )}
        <DiscountsBlock />
        <PopularBlock />
        <TrendsBlock />
        {!deviceType.isMobile && <ProfitBlock />}
        <HitsBlock />
        <ShoppingAdvantageBlock />
        {process.env.REACT_APP_COMPONENT_ENABLE_ABOUT_US !== "false" && (
          <AboutUsBlock />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_OUR_NEWS !== "false" && (
          <OurNews />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_CUSTOMER_FEEDBACK_MAIN_PAGE !==
          "false" && <Reviews />}
      </main>

    </>
  );
};

export default MainPage;
