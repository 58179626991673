import { useMemo } from "react";
import PropTypes from "prop-types";

import { usePagination, DOTS } from "../../utils/hooks/usePagination";

import cn from "classnames";
import styles from "./styles.module.scss";

const Pagination = ( props ) => {
  const {
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    onPageChange,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const isHidePagination = useMemo(() => {
    return currentPage === 0 || paginationRange.length < 2;
  }, [currentPage, paginationRange]);

  return (
    !isHidePagination &&
      <ul
        className={cn(styles["pagination-container"], props.containerStyles)}
      >
        {
          paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li
                  key={index}
                  className={styles["pagination-item__dots"]}
                >
                ....
                </li>
              );
            }
            return (
              <li
                key={index}
                className={cn(styles["pagination-item"], {
                  [styles["pagination-item--selected"]]: pageNumber === currentPage
                })}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })
        }
      </ul>
  );
};

const propTypes = {
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  containerStyles: PropTypes.string,
};

Pagination.propTypes = propTypes;

export default Pagination;