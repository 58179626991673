import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const ActionButton = (props) => {
  return (
    <button
      className={cn(styles.actions__button, props.containerStyles)}
      onClick={props.onClick}
    >
      {props.children}
      {
        !props.isMobile &&       <span className={styles.actions__title}>{props.title}</span>
      }
    </button>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  containerStyles: PropTypes.string,
  children: PropTypes.node,
};

ActionButton.propTypes = propTypes;

export default ActionButton;
