const getUserCity = async () => {
  const res = await fetch(`${process.env.REACT_APP_DELIVERY_URL}/api/public/city-by-ip`, {
    method: "GET",
  });

  return res.json();
};

const getCitiesBySearchValue = async (searchValue, { limit }) => {
  let limitText = "";

  if (limit) {
    limitText = "&limit=" + limit;
  }

  const res = await fetch(`${process.env.REACT_APP_DELIVERY_URL}/api/public/cities?query=${searchValue}${limitText}`, {
    method: "GET",
  });

  return res.json();
};

export const citiesApi = {
  getUserCity,
  getCitiesBySearchValue,
};