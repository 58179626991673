import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";

import { USER_INFO_FIELDS } from "../../../../../utils/constants/formItems";
import phoneNumberMask from "../../../../../utils/inputMasks/phoneNumberMask";

import CustomNotification from "../../../../../components/CustomNotification";
import CustomButton from "../../../../../components/CustomButton";

import cn from "classnames";
import "react-datetime/css/react-datetime.css";
import styles from "./styles.module.scss";

moment.locale("ru");

const MainUserInfo = (props) => {
  const dateRef = useRef(null);
  const outside = useRef(null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const yesterday = moment().subtract(1, "day");
  const valid = useCallback((current) => {
    return current.isBefore(yesterday);
  }, [yesterday]);

  const formatedDate = useMemo(() => {
    if (!props.formik.values.dob) return "";
    return new Date(props.formik.values.dob).toLocaleString("ru-RU", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }, [props.formik.values.dob]);

  const onClickOpenDateInput = () => {
    if (props.disabledDobSelect) {
      return;
    }
    if (!isDatePickerVisible) {
      setDatePickerVisible(true);
    }
  };

  const onChangeGender = (value) => {
    if (props.gender === value) {
      props.onClickSelectGender("");
      return;
    }
    props.onClickSelectGender(value);
  };

  const onChangeHandler = (e, inputType) => {
    if (inputType !== "tel") {
      return props.formik.handleChange(e);
    }
    phoneNumberMask(e);
    props.formik.handleChange(e);
  };

  const handleClickOutside = (event) => {
    if (outside.current && !outside.current.contains(event.target)) {
      setDatePickerVisible(false);
    }
  };

  const handleScroll = () => {
    setDatePickerVisible(false);
  };

  useEffect(() => {
    if (isDatePickerVisible) {
      document.addEventListener("click", handleClickOutside, true);
      window.addEventListener("scroll", handleScroll, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isDatePickerVisible]);

  const handleDateChange = useCallback((moment) => {
    props.formik.setFieldValue("dob", moment.format("YYYY-MM-DD"));
    setDatePickerVisible(false);
  }, [props.formik.setFieldValue, setDatePickerVisible]);

  return (
    <form
      method="post"
      onSubmit={props.formik.handleSubmit}
      className={styles.root}
      ref={props.mainUserInfoRef}
    >
      {USER_INFO_FIELDS.map((item) => {
        return (
          <div key={item.id} className={styles.row}>
            <p className={styles.row__title}>{item.title}</p>
            <div className={styles["row__input-wrapper"]}>
              <input
                name={item.name}
                type={item.inputType}
                value={props.formik.values[item.name]}
                onChange={(e) => onChangeHandler(e, item.inputType)}
                className={cn(styles.row__input, {
                  [styles.row__input_phone]: item.name === "phone",
                })}
                readOnly={item.isReadOnly}
              />
              {item.isRequiredField && (
                <span className={styles["row__required-symbol"]}>*</span>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles.row}>
        <span className={styles.row__title}>Пол</span>
        <div className={styles.gender}>
          <button
            type="button"
            className={cn(styles.gender__button, {
              [styles["gender__button--disabled"]]: props.disableGenderSelect,
            })}
            onClick={() => onChangeGender("male")}
          >
            <div className={styles.gender__indicator}>
              {props.gender === "male" && (
                <div className={styles["gender__indicator--active"]} />
              )}
            </div>
            <span className={styles.gender__title}>Мужской</span>
          </button>
          <button
            type="button"
            className={cn(styles.gender__button, {
              [styles["gender__button--disabled"]]: props.disableGenderSelect,
            })}
            onClick={() => onChangeGender("female")}
          >
            <div className={styles.gender__indicator}>
              {props.gender === "female" && (
                <div className={styles["gender__indicator--active"]} />
              )}
            </div>
            <span className={styles.gender__title}>Женский</span>
          </button>
        </div>
      </div>
      <div className={styles.row}>
        <span className={styles.row__title}>Дата рождения</span>
        <button
          type="button"
          onClick={onClickOpenDateInput}
          className={cn(styles["dob-button"], {
            [styles["dob-button--disabled"]]: props.disabledDobSelect,
          })}
        >
          <span
            className={cn(styles["dob-button__title"], {
              [styles["dob-button__title--selected"]]:
                !!props.formik.values.dob,
            })}
          >
            {formatedDate || "ДД.ММ.ГГ"}
          </span>
          <div
            ref={outside}
            className={cn("rdt", { rdtOpen: isDatePickerVisible })}
          >
            <Datetime
              ref={dateRef}
              value={props.formik.values.dob}
              onChange={handleDateChange}
              timeFormat={false}
              closeOnSelect
              locale="ru"
              isValidDate={valid}
              inputProps={{
                className: styles["dob-button__date-input"],
                disabled: props.disabledDobSelect,
              }}
              dateFormat="YYYY-MM-DD"
            />
          </div>
        </button>
      </div>
      <div className={styles.row}>
        <CustomButton
          title="Сохранить данные"
          onClick={props.onSubmitHandler}
          type="button"
          className={cn(styles["save-button"], {
            [styles["save-button--disabled"]]: !props.isFormChanged,
          })}
          disabled={!props.isFormChanged}
        />
      </div>
      <CustomNotification showNotification={props.showUserDataNotification} />
    </form>
  );
};

const propTypes = {
  mainUserInfoRef: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  gender: PropTypes.string.isRequired,
  disableGenderSelect: PropTypes.bool,
  disabledDobSelect: PropTypes.bool,
  showUserDataNotification: PropTypes.bool.isRequired,
  onClickSelectGender: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
};

MainUserInfo.propTypes = propTypes;

export default MainUserInfo;
