import PropTypes from "prop-types";

import CustomButton from "../../../../components/CustomButton";
import Pagination from "../../../../components/Pagination";
import ProductCard from "../../../../components/ProductCard";

import cn from "classnames";
import { useMemo } from "react";
import { SkeletonCard } from "../../../../components/UI/Skeleton/SkeletonCard/SkeletonCard";
import styles from "./styles.module.scss";

const CatalogContent = (props) => {
  const shouldRenderShowMoreButton = useMemo(() => {
    const isProductsEnough = props.products?.length >= props.limit;
    const isLastPage = Math.ceil(props.productAmount / props.limit) === props.currentPage;
    return isProductsEnough && !isLastPage;
  }, [props.products, props.limit, props.productAmount, props.currentPage]);

  return (
    <div className={styles.root}>
      <div
        className={cn(styles["products-list"], {
          [styles["products-list--empty"]]: !props.products?.length && !props.isInit,
        })}
      >
        {!props.products?.length ? (
          <>
            {props.isInit ? (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                return (
                  <div key={item} className={styles.card_wrapper}>
                    <SkeletonCard />
                  </div>
                );
              })
            ) : (
              <p className={styles["products-list__title"]}>Товары не найдены</p>
            )}
          </>
        ) : (
          props.products.map((product, idx) => {
            return (
              <div key={`${product.id}${idx}`} className={styles.card_wrapper}>
                {!props.isInit ? <ProductCard product={product} /> : <SkeletonCard />}
              </div>
            );
          })
        )}
      </div>
      {!!props.products?.length && (
        <div className={styles["show-block"]}>
          {shouldRenderShowMoreButton && (
            <CustomButton
              title="Показать еще"
              onClick={props.onClickShowMoreProducts}
              className={styles["show-block__button"]}
            />
          )}
          <Pagination
            totalCount={props.productAmount}
            currentPage={props.currentPage}
            pageSize={props.limit}
            onPageChange={props.onClickChangePage}
            containerStyles={!shouldRenderShowMoreButton ? styles["show-block__pagination"] : ""}
          />
        </div>
      )}
      <div id="diginetica_rec_sys"></div>
    </div>
  );
};

const propTypes = {
  productAmount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onClickChangePage: PropTypes.func.isRequired,
  onClickShowMoreProducts: PropTypes.func.isRequired,
  isInit: PropTypes.bool.isRequired,
  products: PropTypes.array,
};

CatalogContent.propTypes = propTypes;

export default CatalogContent;
