import PropTypes from "prop-types";

import SelectCityInnerContent from "../SelectCityInnerContent";
import { ReactComponent as CrossIcon } from "../../assets/icons/svg/cross-rounded.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import useCitiesSearch from "../../utils/hooks/useCitiesSearch";

const SelectCityModal = (props) => {
  const {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue,
  } = useCitiesSearch({ limit: 15 });

  const selectCity = (city) => {
    props.setCity(city);
    props.onCloseCb();
  };

  return (
    <div
      ref={props.modalRef}
      className={cn(styles.root, props.containerStyles)}
    >
      <CrossIcon className={styles["cross-icon"]} onClick={props.onClose} />
      <SelectCityInnerContent
        searchValue={searchValue}
        searchedCities={searchedCities}
        isLoading={isLoading}
        isNoResultSearch={isNoResultSearch}
        citiesContainerStyles={styles["cities-container"]}
        onChange={onChangeSearchValue}
        onClose={props.onClose}
        selectCity={selectCity}
      />
    </div>
  );
};

const propTypes = {
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseCb: PropTypes.func,
  setCity: PropTypes.func.isRequired,
  containerStyles: PropTypes.string,
};

SelectCityModal.propTypes = propTypes;

export default SelectCityModal;
