
export const errorMessages = {
  "Unexpected token '<'": "Некорректный формат данных",
  "Network Error": "Ошибка сети",
  "Request failed with status code 400": "Некорректный запрос",
  "Request failed with status code 401": "Неавторизованный доступ",
  "Request failed with status code 403": "Доступ запрещен",
  "Request failed with status code 404": "Ресурс не найден",
  "Request failed with status code 500": "Внутренняя ошибка сервера",
  "Request failed with status code 503": "Сервис недоступен",
  "timeout of 0ms exceeded": "Превышено время ожидания запроса",
  "SQLSTATE[23000]: Integrity constraint violation: 1062": "Пользователь с таким номером телефона уже зарегистрирован",
  "default": "Произошла ошибка. Пожалуйста, попробуйте позже."
};

export const getErrorMessage = (error) => {
  if (typeof error === "string") {
    for (const key in errorMessages) {
      if (error.includes(key)) {
        return errorMessages[key];
      }
    }
    return errorMessages["default"];
  }

  if (error.message) {
    return error.message;
  }

  if (error.meta) {
    if (typeof error.meta.error === "string") {
      return error.meta.error;
    }

    if (typeof error.meta.error === "object") {
      if (error.meta.error.message) {
        return error.meta.error.message;
      }
    }
  }

  return "Произошла ошибка";
};
