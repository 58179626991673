import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

const LoadingItem = (props) => {
  return (
    <div className={cn(styles.loading, props.containerStyles)}>
      <div className={cn(styles.loading__figure, props.figureStyles)} />
      <p className={cn(styles.loading__title, props.titleStyles)}>Loading...</p>
    </div>
  );
};

const propTypes = {
  containerStyles: PropTypes.string,
  figureStyles: PropTypes.string,
  titleStyles: PropTypes.string,
};

LoadingItem.propTypes = propTypes;

export default LoadingItem;
