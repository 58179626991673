import { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

const CheckoutOrderItem = ({ item, rootStyles, imageStyles }) => {
  const minPrice = useMemo(() => {
    if (!item.calculated_price) {
      const salePrice = item.offer.price_sale || item.offer.akcia;
      return salePrice || item.offer.price;
    }
    return item.calculated_price;
  }, [item]);

  return (
    <div
      className={cn(styles.root, rootStyles)}
      data-offer_id={item.offer.id}
      data-group_id={item.offer.catalog.id}
    >
      <img
        src={item.offer.catalog.image?.link}
        alt={item.offer.catalog.name}
        className={cn(styles.image, imageStyles)}
      />
      <div className={styles["product-data"]}>
        <p className={styles["product-data__name"]}>
          {item.offer.catalog.name}
        </p>
        <p className={styles["product-data__code"]}>
          {`Артикул: ${item.offer.catalog.vendorCode}`}
        </p>
      </div>
      <div className={styles["price-wrapper"]}>
        {
          item.offer.price === minPrice
            ? <p className={styles["sale-price"]}>
              {`${item.calculated_amount} ₽`}
            </p>
            : <>
              <p className={styles["main-price"]}>
                {`${item.offer.price * item.count} ₽`}
              </p>
              <p className={styles["sale-price"]}>
                {`${minPrice * item.count} ₽`}
              </p>
            </>
        }
        <p className={styles.amount}>
          {`x ${item.count}`}
        </p>
      </div>
    </div>
  );
};

const propTypes = {
  item: PropTypes.object,
  rootStyles: PropTypes.string,
  imageStyles: PropTypes.string,
};

CheckoutOrderItem.propTypes = propTypes;

export default CheckoutOrderItem;