import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  addUserFavoriteProduct,
  getProductOffersByIds,
  removeUserFavoriteProduct,
} from "../../../../../store/user/userThunks/userThunks";
import {
  getMaxProductPrice,
  getMinProductPrice,
} from "../../../../../utils/getProductPrices";

import CustomButton from "../../../../../components/CustomButton";
import deleteIcon from "../../../../../assets/icons/png/delete-icon.png";
import heartFoolIcon from "../../../../../assets/icons/png/heart_fool-icon.png";
import noImgProductLogo from "../../../../../assets/images/no-photo.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import ProductCardOffersModal from "../../../../../components/UI/ProductCardOffersModal/ProductCardOffersModal";
import ModalRedirect from "../../../../../components/ModalRedirect/ModalRedirect";

const SelectedProductItem = ({ isFavorite, item }) => {
  const deviceType = useSelector((state) => state.user.deviceType);
  const [selectedProduct, setSelectedProduct] = useState(item);
  const [isDeleted, setisDeleted] = useState(false);
  const [showSelectOfferModal, setShowSelectOfferModal] = useState(false);
  const [isOpenRedirectModalCart, setIsOpenRedirectModalCart] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const modalRef = useRef(null);
  const modalRedirectCartRef = useRef(null);
  const newParfumartUrlProduct = `${process.env.REACT_APP_REDIRECT_PRODUCT_URL}/${item.id}-${item.product_rewrite_name}`;

  const productPrice = useMemo(() => {
    if (!selectedProduct.offers?.length) {
      return {
        min: "",
        max: "",
      };
    }

    if (selectedProduct.offers?.length === 1) {
      return {
        min: getMinProductPrice(selectedProduct.offers),
        max: "",
      };
    }

    return {
      min: getMinProductPrice(selectedProduct.offers),
      max: getMaxProductPrice(selectedProduct.offers),
    };
  }, [selectedProduct.offers]);

  const deleteButtonTitle = useMemo(() => {
    if (isDeleted) {
      return isFavorite ? "Вернуть в избранное" : "Вернуть в подписки";
    }
    return isFavorite ? "Удалить из избранного" : "Удалить из подписки";
  }, [isFavorite, isDeleted]);

  const productUrl = useMemo(() => {
    return `/product/${selectedProduct.id}-${selectedProduct.product_rewrite_name}`;
  }, [location, selectedProduct.id, selectedProduct.product_rewrite_name]);

  useEffect(() => {
    (async () => {
      try {
        const data = await dispatch(
          getProductOffersByIds(selectedProduct.id)
        ).unwrap();

        setSelectedProduct((prev) => {
          return {
            ...prev,
            offers: data.offers,
          };
        });
      } catch (err) {
        // TODO
      }
    })();
  }, []);

  const onDeleteProduct = () => {
    try {
      setisDeleted(!isDeleted);
      if (isDeleted) {
        dispatch(addUserFavoriteProduct(selectedProduct.id));
      } else {
        dispatch(removeUserFavoriteProduct(selectedProduct.id));
      }
    } catch (err) {
      // TODO
    }
  };

  const onOpenSelectOfferModal = (e) => {
    e.stopPropagation();

    setShowSelectOfferModal(true);
  };

  const handleButtonClick = (e) => {
    if (deviceType.isMobile || deviceType.isTablet) {
      navigate(productUrl);
    } else {
      onOpenSelectOfferModal(e);
    }
  };

  const onCloseSelectOfferModal = () => {
    setShowSelectOfferModal(false);
  };

  const onCloseModal = (e) => {
    if (e) e.stopPropagation();
    setIsOpenRedirectModalCart(false);
  };

  const productMinDescription = useMemo(() => {
    if (!item || !item.offers || item.offers.length === 0) {
      return "";
    }

    const productItemWithVolume = item.offers.find((offer) => {
      if (!offer.offerVolumes) return false;
      return offer.offerVolumes.some((elem) => elem.value_type);
    });

    if (!productItemWithVolume) {
      return "";
    }

    const offerVolumesCopy = [...productItemWithVolume.offerVolumes];
    const productVolume = offerVolumesCopy[0]?.value_type;

    if (!productVolume) {
      return "";
    }

    return `Объем продукта: ${productVolume}`;
  }, [item]);


  return (
    <div
      className={cn(
        styles.root,
        { [styles["root--deleted"]]: isDeleted }
      )}
    >
      <img
        src={selectedProduct.image?.link}
        alt={`${selectedProduct.name}`}
        className={cn(styles.logo, { [styles["logo--deleted"]]: isDeleted })}
      />
      <div className={styles.product}>
        <h4 className={styles.product__title}>{selectedProduct.name}</h4>
        <div className={styles.product__info}>
          <span className={styles["product__vendor-code"]}>
            {`Артикул: ${selectedProduct.vendorCode}`}
          </span>
          {deviceType.isMobile && (
            <button
              className={cn(
                styles["product__action-button"],
                styles["product__action-button--mobile"]
              )}
              onClick={onDeleteProduct}
            >
              <div className={styles["product__action-button-img"]}>
                <img
                  src={!isDeleted ? deleteIcon : heartFoolIcon}
                  alt="action-icon"
                />
              </div>
              <span>{deleteButtonTitle}</span>
            </button>
          )}
          <div className={styles["product__actions-wrapper"]}>
            <Link
              to={isDeleted ? "#" : productUrl}
              className={cn(styles.product__link, {
                [styles["product__link--deleted"]]: isDeleted,
              })}
            >
              Перейти к товару
            </Link>
            {!!productPrice.min && !!productPrice.max && (
              <div className={styles.product__price}>
                <span>{`От ${productPrice.min} ₽`}</span>
                <span className={styles["product__price--second"]}>
                  {`До ${productPrice.max} ₽`}
                </span>
              </div>
            )}
            {!!productPrice.min && !productPrice.max && (
              <div className={styles.product__price}>
                <span>{`${productPrice.min} ₽`}</span>
              </div>
            )}
            <CustomButton
              title="В корзину"
              onClick={handleButtonClick}
              className={cn(styles["product__cart-button"], {
                [styles["product__cart-button--deleted"]]: isDeleted,
              })}
            />
          </div>
        </div>
        {!deviceType.isMobile && (
          <button
            className={cn(
              styles["product__action-button"],
              styles["product__action-button--desktop"]
            )}
            onClick={onDeleteProduct}
          >
            <div className={styles["product__action-button-img"]}>
              <img
                src={!isDeleted ? deleteIcon : heartFoolIcon}
                alt="action-icon"
              />
            </div>
            <span>{deleteButtonTitle}</span>
          </button>
        )}
      </div>
      {showSelectOfferModal && (
        <ProductCardOffersModal
          logoSrc={item.reducedImage?.link ?? noImgProductLogo}
          title={item.name}
          productDescription={productMinDescription}
          offers={item.offers}
          productUrl={productUrl}
          modalRef={modalRef}
          onClose={onCloseSelectOfferModal}
          setIsOpenRedirectModalCart={setIsOpenRedirectModalCart}
        />
      )}
      <ModalRedirect
        onClose={onCloseModal}
        url={newParfumartUrlProduct}
        isOpen={isOpenRedirectModalCart}
        modalRef={modalRedirectCartRef}
        textForRedirect={"оформления заказа"}
      />
    </div>
  );
};

const propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

SelectedProductItem.propTypes = propTypes;

export default SelectedProductItem;
