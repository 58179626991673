import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import useSlider from "../../../../utils/hooks/useSlider";
import { NavLink } from "react-router-dom";

import cn from "classnames";
import { PropTypes } from "prop-types";
import atterCollectionBanner from "../../../../assets/banners/attar-collection_banner.png";
import gigiBanner from "../../../../assets/banners/gigi_banner.png";
import lorealBanner from "../../../../assets/banners/l'oreal_banner.png";
import moleculesBanner from "../../../../assets/banners/molecules_banner.png";
import zarkoperfumeBanner from "../../../../assets/banners/zarkoperfume_banner.png";
import newSiteBanner from "../../../../assets/banners/new-site-parfum.png";

import styles from "./styles.module.scss";


const BANNERS = [
  {
    id: nanoid(),
    title: "",
    subtitle: "",
    src: newSiteBanner,
    alt: "Новая версия сайта",
    isBlackBanner: true,
    url: "/",
  },
  {
    id: nanoid(),
    title: "escentric molecules",
    subtitle: "Новинки легендарного бренда",
    src: moleculesBanner,
    alt: "molecules-banner",
    isBlackBanner: true,
    url: "/catalog?brands=escentric-molecules",
  },
  {
    id: nanoid(),
    title: "L’OREAL PROFESSIONNEL",
    subtitle: "Полная гамма профессиональной косметики",
    src: lorealBanner,
    alt: "'l'oreal'-banner",
    url: "/catalog/kosmetika/?brands=l-oreal-professionnel",
  },
  {
    id: nanoid(),
    title: "ZARKOPERFUME",
    subtitle: "Скандинавский стиль в каждом аромате",
    src: zarkoperfumeBanner,
    alt: "zarkoperfume-banner",
    url: "/catalog?brands=zarkoperfume",
  },
  {
    id: nanoid(),
    title: "GIGI laboratories",
    subtitle: "Эксклюзивная косметика",
    src: gigiBanner,
    alt: "gigi-banner",
    url: "/catalog?brands=gigi",
  },
  {
    id: nanoid(),
    title: "ATTART COLLECTION",
    subtitle: "Нишевая композиция по доступным ценам",
    src: atterCollectionBanner,
    alt: "attar-collection-banner",
    url: "/catalog?brands=attar-collection",
  },
];

const SliderWithBanners = (props) => {
  const { deviceType } = useSelector((state) => state.user);

  const onMouseMove = (e, rotate) => {
    if (!props.refCursorItem) return;
    props.refCursorItem.current.style.display = "block";
    props.setMousePosition({
      x: e.pageX - 25,
      y: e.pageY - 300,
      rotate
    });
  };

  const onMouseLeave = () => {
    props.refCursorItem.current.style.display = "none";
  };

  const {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext,
  } = useSlider({
    shouldBeLooped: true,
    shouldAutoSwitched: true,
  });

  return (
    <section className={styles.root}>

      {!deviceType.isMobile && !deviceType.isTablet && isLoaded && instanceRef.current && (
        <button onMouseMove={(e) => onMouseMove(e, "180deg")} onMouseLeave={onMouseLeave} className={cn(styles["arrow-btn"], styles["arrow-btn--left"])} onClick={onClickPrev} />
      )}

      <div ref={sliderRef} className={cn("keen-slider")}>
        {BANNERS.map((item) => (
          <NavLink key={item.id} to={item.url} className={cn(styles.banner, "keen-slider__slide")}>
            <img src={item.src} alt={item.alt} className={styles.banner_img} />
            <p className={styles.banner__titles}>
              <span
                className={cn(styles.banner__titles_title, {
                  [styles["banner__titles_title--white"]]: item.isBlackBanner,
                })}
              >
                {item.title}
              </span>
              <span
                className={cn(styles.banner__titles_subtitle, {
                  [styles["banner__titles_subtitle--white"]]: item.isBlackBanner,
                })}
              >
                {item.subtitle}
              </span>
            </p>
          </NavLink>
        ))}

      </div>
      <div className={styles.dots}>
        {availableDots.map((idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={styles["dot-wrapper"]}
            >
              <div
                className={cn(styles.dot, {
                  [styles["dot--active"]]: currentSlide === idx,
                })}
              />
            </button>
          );
        })}
      </div>
      {
        !deviceType.isMobile && !deviceType.isTablet && isLoaded && instanceRef.current && (
          <button onMouseMove={(e) => onMouseMove(e, "0deg")} onMouseLeave={onMouseLeave} className={cn(styles["arrow-btn"], styles["arrow-btn--right"])} onClick={onClickNext} />
        )
      }
    </section >
  );
};

const propTypes = {
  refCursorItem: PropTypes.object.isRequired,
  setMousePosition: PropTypes.func,
};

SliderWithBanners.propTypes = propTypes;


export default SliderWithBanners;
