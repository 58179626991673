import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserCart } from "../../store/user/cartThunks/cartThunks";

import SectionTitle from "../../components/SectionTitle";
import CartProducts from "./components/CartProducts";
import OrderData from "./components/OrderData";

import styles from "./styles.module.scss";

const UserCart = () => {
  const { user, cart, checkoutData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.phone) {
      return;
    }

    (async () => {
      try {
        const options = {
          "use_promo_code": checkoutData.promoCode.trim(),
        };
        await dispatch(getUserCart(options)).unwrap();
      } catch (err) {
        // TODO
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className={styles.root}>
        <SectionTitle title="Корзина" />
        <section className={styles.products}>
          <CartProducts products={cart} />
          <OrderData />
        </section>
      </main>
      <div id="diginetica_rec_sys"></div>
    </>
  );
};

export default UserCart;
