import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";

import { PROVIDERS, WAY_TO_GET } from "../../../../../../../utils/constants";
import usePayment from "../../../../../../../utils/hooks/usePayment";

import OrderStringItem from "../../../../../../../components/UI/OrderStringItem/OrderStringItem";
import CustomButton from "../../../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";

const PayOrderRecipientDataBlock = (props) => {
  const { orderData, onOrderCreated } = props;
  const { checkoutData } = useSelector((state) => state.user);
  const { confirmPayment } = usePayment({orderData, onOrderCreated});
  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  // const date = new Date();

  const paymentMethod = useMemo(() => {
    const paymentValue = checkoutData.paymentMethod.title;

    return paymentValue;
  }, [checkoutData.paymentMethod]);

  const wayToGet = useMemo(() => {
    const deliveryTypeTitle = orderData.delivery_type === "pickup" ? WAY_TO_GET.toPoint : WAY_TO_GET.toDoor;
    return deliveryTypeTitle;
  }, [orderData.delivery_type]);

  const productsTotalPrice = useMemo(() => {
    return orderData.orderOffers.reduce((acc, cur) => {
      acc += (cur.sale_price * cur.count);
      return acc;
    }, 0);
  }, [orderData.orderOffers]);

  const totalOrderPrice = useMemo(() => {
    const deliveryPrice = orderData.delivery_amount;
    const productsPrice = productsTotalPrice;
    if (!deliveryPrice) {
      return productsPrice;
    }

    return Number(productsPrice) + Number(deliveryPrice);
  }, [productsTotalPrice, orderData.delivery_amount]);

  const showPaymentButton = useMemo(() => {
    const paymentType = checkoutData.paymentMethod.type;
    const allowedPaymentMethodsTypes = ["cash", "yookassa", "dolymi"];
    const shouldShowPaymentButton = allowedPaymentMethodsTypes.includes(paymentType);
    return shouldShowPaymentButton;
  }, [checkoutData.paymentMethod]);

  const buttonTitle = useMemo(() => {
    return showPaymentButton ? "Перейти к оплате" : "Далее";
  }, [showPaymentButton]);


  return (
    <div className={styles["recipient-data"]}>
      <div className={styles["recipient-data__wrapper-title"]}>
        <h1 className={styles["recipient-data__title"]}>Получатель</h1>
      </div>
      <OrderStringItem
        title="Получатель:"
        subtitle={orderData.orderRecipient.name}
        contanerClassname={styles.item}
      />
      <OrderStringItem
        title="Телефон:"
        subtitle={orderData.orderRecipient.phone}
        contanerClassname={styles.item}
      />
      <OrderStringItem
        title="Адрес:"
        subtitle={orderData.orderRecipient.address}
        contanerClassname={styles.item}
      />
      <OrderStringItem
        title="Способ получения:"
        subtitle={`${wayToGet} ${PROVIDERS[orderData.delivery_provider].title}`}
        contanerClassname={styles.item}
      />
      {/* <OrderStringItem
        title="Дата получения:"
        subtitle={date
          .addDays(checkoutData.providerInfo.daysMin)
          .toLocaleString("ru", options)}
        contanerClassname={styles.item}
      /> */}
      <OrderStringItem
        title="Способ оплаты:"
        subtitle={paymentMethod ? paymentMethod : "Не выбран"}
        contanerClassname={styles.item}
      />
      <div className={styles.line} />
      <OrderStringItem
        title="Стоимость товаров:"
        subtitle={`${productsTotalPrice.toString()} ₽`}
        contanerClassname={styles["payment-item"]}
      />
      <OrderStringItem
        title="Стоимость доставки:"
        subtitle={`${orderData.delivery_amount.toString()} ₽`}
        contanerClassname={styles["payment-item"]}
      />
      <OrderStringItem
        title="Всего к оплате:"
        subtitle={`${totalOrderPrice.toString()} ₽`}
        contanerClassname={styles["payment-item"]}
      />
      <CustomButton
        title={buttonTitle}
        onClick={confirmPayment}
        className={styles["recipient-data__button"]}
      />
    </div>
  );
};

const propTypes = {
  orderData: PropTypes.object,
  onOrderCreated: PropTypes.func.isRequired,
};

PayOrderRecipientDataBlock.propTypes = propTypes;


export default PayOrderRecipientDataBlock;