export const meta = {
  "/catalog": {
    root: {
      title:
        "Купить оригинальные товары с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
        "Быть уникальным легко с оригинальными товарами от Parfumart, доступной теперь с доставкой по всей России",
    },
    brands: {
      title: "{category} от бренда {brand} - купить 100% оригинальные товары | Интернет магазин Parfumart",
      description: "{category} от бренда {brand} - купить в Интернет магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России",
    },
    seria: {
      title: "{category} серии {seria} - 100% оригинальные товары | Интернет магазин Parfumart",
      description: "{category} серии {seria} - купить в Интернет магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России",
    },
    badges: {
      title:
        "Распродажа парфюмерии и косметики: скидки до {percent}% | Интернет магазин Parfumart",
      description:
        "Большая распродажа парфюмерии и косметики! Скидки до {percent}% на ваши любимые ароматы и средства по уходу за собой. Не упустите шанс купить по выгодной цене!",
    },
    "kategoria=selektivnaa": {
      title:
      "Купить селективную парфюмерию с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
      "Выражайте свою индивидуальность с оригинальными селективными ароматами от Parfumart, предлагаемыми с доставкой по России.",
    },
    "kategoria=elitnaa": {
      title:
        "Купить элитную парфюмерию с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
        "Откройте для себя эксклюзивность с оригинальными элитными ароматами, которые мы доставляем по всей России на Parfumart.",
    },
    "kategoria=dla-doma": {
      title:
        "Купить товары для дома с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Оживите свой дом с нашей оригинальной продукцией для дома, и пользуйтесь удобством доставки по всей России на Parfumart.",
    },
  },
  "/catalog/parfumeria": {
    root: {
      title:
        "Купить оригинальную парфюмерию с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
        "Быть уникальным легко с оригинальной парфюмерией от Parfumart, доступной теперь с доставкой по всей России",
    },
    brands: {
      title:
        "Купить оригинальную парфюмерию от {brand} с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Быть уникальным легко с оригинальной парфюмерией от {brand}, доступной теперь с доставкой по всей России",
    },
    "kategoria=dla-doma": {
      title:
        "Купить товары для дома с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Оживите свой дом с нашей оригинальной продукцией для дома, и пользуйтесь удобством доставки по всей России на Parfumart.",
    },
    "kategoria=selektivnaa": {
      title:
        "Купить селективную парфюмерию с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
        "Выражайте свою индивидуальность с оригинальными селективными ароматами от Parfumart, предлагаемыми с доставкой по России.",
    },
    "kategoria=elitnaa": {
      title:
        "Купить элитную парфюмерию с доставкой по всей территории России | Интернет магазин Parfumart",
      description:
        "Откройте для себя эксклюзивность с оригинальными элитными ароматами, которые мы доставляем по всей России на Parfumart.",
    },
    seria: {
      title: "Серия {seria} - Духи, туалетная вода, парфюмерная вода | Интернет магазин Parfumart",
      description: "Серия {seria} - купить в Интернет магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России",
    },
  },
  "/catalog/kosmetika":{
    root: {
      title:
        "Купить профессиональную косметику с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Представляем эксклюзивный ассортимент оригинальной косметики для ежедневного ухода и особых случаев. Купите сертифицированные продукты высшего качества с доставкой по всей России на Parfumart.",
    },
    brands: {
      title:
      "Купить косметику от {brand} с доставкой по РФ | Интернет магазин Parfumart",
      description:
      "Представляем эксклюзивный ассортимент оригинальной косметики от {brand} для ежедневного ухода и особых случаев. Купите сертифицированные продукты высшего качества с доставкой по всей России на Parfumart.",
    },
    "kategoria=dla-volos": {
      title:
        "Купить профессиональную косметику для волос | Интернет магазин Parfumart",
      description:
        "Побалуйте свои волосы профессиональным уходом с нашей линейкой оригинальной косметики для волос. Выберите и купите идеальные средства для вашего типа волос и пользуйтесь удобной доставкой по всей России с Parfumart.",
    },
    "kategoria=dla-lica": {
      title:
        "Купить уходовую косметику для лица с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Откройте для себя инновационные средства по уходу за кожей лица и заказывайте оригинальную продукцию с доставкой по всей России на Parfumart.",
    },
    "kategoria=dla-tela": {
      title:
        "Купить косметику для тела с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Выбирайте оригинальную косметику для тела высшего качества и наслаждайтесь удобной доставкой по всей России с Parfumart.",
    },
    "kategoria=dla-makiaza": {
      title:
        "Купить профессиональную косметику для макияжа с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Превратите каждый день в праздник с нашей коллекцией для профессионального и оригинального макияжа, теперь с доставкой по всей России на Parfumart.",
    },
    "kategoria=dla-polosti-rta": {
      title:
        "Купить продукцию для гигиены полости рта с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Поддерживайте идеальную гигиену полости рта с помощью нашей оригинальной продукции и используйте преимущество доставки по всей России на Parfumart.",
    },
    "kategoria=dla-solnca-i-zagara": {
      title:
        "Купить средства для загара и защиты от солнца с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Защитите кожу и получите равномерный загар с нашими оригинальными средствами, доступными для доставки по всей России на Parfumart.",
    },
    "kategoria=dla-detej": {
      title:
        "Купить косметику и парфюмерию для детей с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Дарите вашему ребенку только лучшее с нашими оригинальными и безопасными продуктами, теперь с доставкой по всей России на Parfumart.",
    },
    "kategoria=dla-nogtej": {
      title:
        "Купить лаки и ухаживающие средства для ногтей с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Создайте идеальный маникюр с оригинальными лаками и ухаживающими продуктами, доступными для заказа с доставкой по всей России на Parfumart.",
    },
    "kategoria=dla-doma": {
      title:
        "Купить товары для дома с доставкой по РФ | Интернет магазин Parfumart",
      description:
        "Оживите свой дом с нашей оригинальной продукцией для дома, и пользуйтесь удобством доставки по всей России на Parfumart.",
    },
    "pol=dla-muzcin": {
      title:
      "Мужская косметика: уход за кожей и бородой | Интернет магазин Parfumart",
      description:
      "Подчеркни свою мужественность с помощью качественной косметики! В Parfumart.ru ты найдешь все для ухода за кожей и бородой: кремы, гели, шампуни, бальзамы и многое другое. Закажи онлайн с доставкой!",
    },
    "pol=dla-zensin": {
      title:
      "Женская косметика: уход за кожей| Интернет магазин Parfumart",
      description:
      "Подчеркни свою женственность с помощью качественной косметики! В Parfumart.ru ты найдешь все для ухода за кожей: кремы, гели, шампуни, бальзамы и многое другое. Закажи онлайн с доставкой!",
    },
    "pol=uniseks": {
      title:
      "Косметика: уход за кожей| Интернет магазин Parfumart",
      description:
      "Подчеркни свою индивидуальность с помощью качественной косметики! В Parfumart.ru ты найдешь все для ухода за кожей: кремы, гели, шампуни, бальзамы и многое другое. Закажи онлайн с доставкой!",
    },
    seria: {
      title: "Косметика серии {seria} | Интернет магазин Parfumart",
      description: "Откройте для себя косметику серии {seria} в интернет-магазине Parfumart.ru ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России",
    },
  },
};