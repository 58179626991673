import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomLink = (props) => {
  return (
    <Link
      to={props.path}
      className={cn(
        props.isPrimary ? styles.primary : styles.secondary,
        { [styles.white]: props.isWhite },
        { [styles.black]: props.isBlack },
        props.className
      )}
    >
      {props.title}
    </Link>
  );
};

const propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
  isWhite: PropTypes.bool,
  isBlack: PropTypes.bool,
  className: PropTypes.string,
};

CustomLink.propTypes = propTypes;

export default CustomLink;