import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import plusIcon from "../../../../../../assets/icons/svg/plus-icon.svg";
import minusIcon from "../../../../../../assets/icons/svg/minus-icon.svg";
import FiltersList from "../FiltersList";

import styles from "./styles.module.scss";

const FilterButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenStatus = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <li className={styles.root}>
      <button className={styles["main-title"]} onClick={toggleOpenStatus}>
        <span>{props.mainTitle}</span>
        <img
          src={isOpen ? minusIcon : plusIcon}
          alt="plus-icon"
          className={styles.icon}
        />
      </button>
      <FiltersList
        isOpen={isOpen}
        filters={props.filters}
        onClickSelectFilter={props.onClickSelectFilter}
        showMoreFilters={props.showMoreFilters}
      />
    </li>
  );
};

const propTypes = {
  mainTitle: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      "rewrite_name": PropTypes.string,
      "main_rewrite_name": PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
  onClickSelectFilter: PropTypes.func.isRequired,
  showMoreFilters: PropTypes.func,
};

FilterButton.propTypes = propTypes;

export default FilterButton;
