import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import OrderItemsData from "../OrderItemsData";
import OrderItem from "../OrderItem/OrderItem";
import OrderItemsSlider from "../OrderItemsSlider/OrderItemsSlider";

import styles from "./styles.module.scss";

const OrderItems = (props) => {
  const { deviceType } = useSelector((state) => state.user);

  return (
    <div className={styles.root}>
      {deviceType.isMobile ? (
        !!props.offers?.length && <OrderItemsSlider offers={props.offers} />
      ) : (
        <div className={styles.items}>
          {props.offers?.map((item, idx) => {
            return (
              <OrderItem
                key={`${item.id}-${idx}`}
                item={item}
                amount={item.count}
              />
            );
          })}
        </div>
      )}
      <OrderItemsData
        orderStatusId={props.orderStatusId}
        offers={props.offers}
        deliveryPrice={props.deliveryPrice}
        paymentStatus={props.paymentStatus}
        paymentMethod={props.paymentMethod}
      />
    </div>
  );
};

const propTypes = {
  offers: PropTypes.array.isRequired,
  deliveryPrice: PropTypes.number,
  paymentStatus: PropTypes.string,
  paymentMethod: PropTypes.string,
  orderStatusId: PropTypes.number,
};

OrderItems.propTypes = propTypes;

export default OrderItems;