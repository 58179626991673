import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import useSearchParamsHandler from "../../../../../utils/hooks/useSearchParamsHandler";
import { getResultStringToSaveInQuery } from "../../../../../utils/helpers/searchParamsHelper";
import { toggleSelectedFilterStatus } from "../../../../../store/catalog";

import SelectedFilters from "../../SelectedFilters/SelectedFilters";

const DesktopSelectedFilters = (props) => {
  const catalog = useSelector((state) => state.catalog);
  const { searchParams, handleChangeSingleSearchParam } =
    useSearchParamsHandler();
  const dispatch = useDispatch();

  const removeSingleFilter = (filter) => {
    const mainKey = filter.main_rewrite_name;

    const resultString = getResultStringToSaveInQuery({
      newItem: filter.rewrite_name,
      currentQuery: searchParams.get(mainKey),
    });
    handleChangeSingleSearchParam({
      key: mainKey,
      queryString: resultString,
    });
    dispatch(toggleSelectedFilterStatus({ filter }));
    props.onResetCurrentPageAndOffset();
  };

  return (
    <SelectedFilters
      selectedFilters={catalog.selectedFilters}
      removeSingleFilter={removeSingleFilter}
    />
  );
};

const propTypes = {
  onResetCurrentPageAndOffset: PropTypes.func.isRequired,
};

DesktopSelectedFilters.propTypes = propTypes;

export default DesktopSelectedFilters;
