import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getProductTitleInGenitiveCase } from "../../../utils/cartUtils";

import PersonalAreaModal from "../PersonalAreaModal";

import cartIcon from "../../../assets/header_icons/cart-icon.svg";
import personalAriaIcon from "../../../assets/header_icons/personal_aria-icon.svg";
import searchIcon from "../../../assets/header_icons/search-icon.svg";

import { RedirectToOldContent } from "../../../components/RedirectToOldContent/RedirectToOldContent";
import RedirectToOldVersionModal from "../../../components/UI/RedirectToOldVersionModal/RedirectToOldVersionModal";
import styles from "./styles.module.scss";

const UserBlock = () => {
  const { user, cart } = useSelector((state) => state.user);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRedirectModal, setIsOpenRedirectModal] = useState(false);
  const [isOpenRedirectModalCart, setIsOpenRedirectModalCart] = useState(false);
  const modalRef = useRef(null);
  const modalRedirectRef = useRef(null);
  const modalRedirectCartRef = useRef(null);
  const redirectProfileIcon = process.env.REACT_APP_REDIRECT_PROFILE_ICON === "true";

  const navigate = useNavigate();

  const price = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc += cur.calculated_amount;
      return acc;
    }, 0);
  }, [cart]);

  const productAmountTitle = useMemo(() => {
    const productAmount = cart.reduce((acc, cur) => {
      acc += cur.count;
      return acc;
    }, 0);

    return `${productAmount} ${getProductTitleInGenitiveCase(productAmount)}`;
  }, [cart]);

  const onClickHandler = () => {
    if (redirectProfileIcon) {
      return setIsOpenRedirectModal(true);
    }

    if (!user?.phone) {
      navigate("/sign-in", { replace: true });
      return;
    }
    setIsOpenModal(true);
  };

  const onClickCloseModal = () => {
    setIsOpenModal(false);
  };

  const onClickCloseRedirectModal = () => {
    setIsOpenRedirectModal(false);
  };

  const onClickCloseRedirectModalCart = () => {
    setIsOpenRedirectModalCart(false);
  };

  return (
    <div className={styles.root}>
      <img src={searchIcon} alt="search-icon" className={styles["search-icon"]} />
      <button onClick={onClickHandler}>
        <img
          src={personalAriaIcon}
          alt="personal_aria-icon"
          className={styles["personal-aria-icon"]}
        />
      </button>

      {process.env.REACT_APP_REDIRECT_CART === "true" ? (
        <button className={styles.cart} onClick={() => setIsOpenRedirectModalCart(true)}>
          <img src={cartIcon} alt="cart-icon" className={styles.cart_icon} />
          <p className={styles.cart__description}>
            <span className={styles.cart__description_price}>{`${price} ₽`}</span>
            <span className={styles.cart__description_amount}>{productAmountTitle}</span>
          </p>
        </button>
      ) : (
        <Link to="/cart" className={styles.cart}>
          <img src={cartIcon} alt="cart-icon" className={styles.cart_icon} />
          <p className={styles.cart__description}>
            <span className={styles.cart__description_price}>{`${price} ₽`}</span>
            <span className={styles.cart__description_amount}>{productAmountTitle}</span>
          </p>
        </Link>
      )}

      {isOpenModal && (
        <PersonalAreaModal isOpen={isOpenModal} modalRef={modalRef} onClose={onClickCloseModal} />
      )}
      {isOpenRedirectModal && (
        <RedirectToOldVersionModal
          modalRef={modalRedirectRef}
          onClose={onClickCloseRedirectModal}
        >
          <RedirectToOldContent
            url={`${process.env.REACT_APP_REDIRECT_PARFUMART_NEW_URL}/auth/login`}
            onClose={onClickCloseRedirectModal}
            textForRedirect={"регистрации/авторизации в личном кабинете"}
          />
        </RedirectToOldVersionModal>
      )}
      {isOpenRedirectModalCart && (
        <RedirectToOldVersionModal
          modalRef={modalRedirectCartRef}
          onClose={onClickCloseRedirectModalCart}
        >
          <RedirectToOldContent
            url={`${process.env.REACT_APP_REDIRECT_PARFUMART_NEW_URL}/main/basket`}
            onClose={onClickCloseRedirectModalCart}
            textForRedirect={"оформления заказа"}
          />
        </RedirectToOldVersionModal>
      )}
    </div>
  );
};

export default UserBlock;
