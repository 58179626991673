import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { setPaymentMethod } from "../../../../../../../store/user/user";

import cashIcon from "../../../../../../../assets/icons/payment-methods/cash.png";
import sbpIcon from "../../../../../../../assets/icons/payment-methods/sbp.png";
import cardIcon from "../../../../../../../assets/icons/payment-methods/card.png";
import sberPayIcon from "../../../../../../../assets/icons/payment-methods/sber-pay.png";
import tinkoffPayIcon from "../../../../../../../assets/icons/payment-methods/tinkoff-pay.png";
import yandexPayIcon from "../../../../../../../assets/icons/payment-methods/yandex-pay.png";
import yandexSplitIcon from "../../../../../../../assets/icons/payment-methods/yandex-split.png";
import dolyamiIcon from "../../../../../../../assets/icons/payment-methods/dolyami.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const ICONS = {
  cash: cashIcon,
  sbp: sbpIcon,
  "card_online": cardIcon,
  "card_receipt": cardIcon,
  "sber_pay": sberPayIcon,
  "tinkoff_pay": tinkoffPayIcon,
  "yandex_pay": yandexPayIcon,
  "yandex_split": yandexSplitIcon,
  "dolymi": dolyamiIcon,
  "yookassa": cardIcon,
};

export const DefaultPaymentMethodItem = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const isSelectedMethod = useMemo(() => {
    return checkoutData.paymentMethod.type === props.type;
  }, [checkoutData.paymentMethod, props.type]);

  const selectMethod = () => {
    dispatch(setPaymentMethod({
      type: props.type,
      title: props.title
    }));
  };

  return(
    <button
      onClick={selectMethod}
      className={cn(
        styles.button,
        styles["default-button"],
        {[styles["button--selected"]]: isSelectedMethod},
      )}
    >
      <img
        src={ICONS[props.type]}
        alt={`${props.type}-icon`}
      />
      <p className={styles.title}>
        {props.title}
      </p>
    </button>
  );
};

const propTypesDefault = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DefaultPaymentMethodItem.propTypes = propTypesDefault;

export const DolyamiPaymentMethodItem = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const price = useMemo(() => {
    const selectedProductsPrice = checkoutData.items.reduce((acc, cur) => {
      if (cur.isSelected) {
        acc += cur.calculated_amount;
      }
      return acc;
    }, 0);
    return Math.ceil(selectedProductsPrice / 4);
  }, [checkoutData.items]);

  const isSelectedMethod = useMemo(() => {
    return checkoutData.paymentMethod.type === props.type;
  }, [checkoutData.paymentMethod, props.type]);

  const selectMethod = () => {
    dispatch(setPaymentMethod({
      type: props.type,
      title: props.title
    }));
  };

  return(
    <button
      onClick={selectMethod}
      className={cn(
        styles["dolyami-button"],
        {[styles["button--selected"]]: isSelectedMethod},
      )}
    >
      <p className={styles.title}>
        {props.title}
      </p>
      <p className={styles.price}>
        {`4 платежа по ${price}₽`}
      </p>
      <img
        src={ICONS[props.type]}
        alt={`${props.type}-icon`}
      />
    </button>
  );
};

const propTypesDolyami = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DolyamiPaymentMethodItem.propTypes = propTypesDolyami;