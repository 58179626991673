import { useEffect, useState } from "react";

import { userApi } from "../../../../api/userApi";
import { ORDER_STATUSES } from "../../../../utils/constants";

import OrdersButtons from "./components/OrdersButtons";
import OrderStatus from "./components/OrderStatus";
import OrderUserInfo from "./components/OrderUserInfo";
import OrderItems from "./components/OrderItems";

import styles from "./styles.module.scss";

const UserOrders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const [statusId, setStatusId] = useState(0);
  const handleChangeStatusId = (id) => {
    setStatusId(id);
  };
  useEffect(() => {
    (async () => {
      try {
        const options = {
          status: [
            ORDER_STATUSES.received.value,
            ORDER_STATUSES["part_received"].value,
          ].join(),
        };

        const data = await userApi.getUserOrders(options);
        setUserOrders(data.response.items);
      } catch (err) {
        // TODO
      }
    })();
  }, []);

  return (
    <section className={styles.root}>
      <OrdersButtons setUserOrders={(orders) => setUserOrders(orders)} onChangeStatusId={handleChangeStatusId}/>
      {!userOrders?.length ? (
        <h3 className={styles.title}>Список пуст</h3>
      ) : (
        userOrders.map((item) => {
          return (
            <div key={item.id} className={styles["order-item"]}>
              <OrderStatus
                orderId={item.id}
                date={item.created_at}
                status={item.status}
              />
              <OrderUserInfo
                orderRecipient={item.orderRecipient}
                deliveryProvider={item.delivery_provider}
                date={item.date}
                trackNumber={item.trackNumber}
              />
              <OrderItems
                orderStatusId={statusId}
                offers={item.orderOffers}
                deliveryPrice={item.delivery_amount}
                paymentStatus={item.payment_status}
                paymentMethod={item.payment_method}
              />
            </div>
          );
        })
      )}
    </section>
  );
};

export default UserOrders;
