import { toast } from "react-toastify";
import { getUserToken, removeTokens } from "../utils/localStorage";
import { getErrorMessage } from "../utils/helpers/errorHelper";

const checkUser = async () => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const signIn = async (user) => {
  const formData = new FormData();

  formData.append("username", user.username);
  formData.append("password", user.password);

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/login`, {
      method: "POST",
      body: formData,
    });

    let data;
    try {
      data = await res.json();
    } catch (jsonError) {
      const text = await res.text();
      const errorMessage = getErrorMessage(text);
      throw new Error(errorMessage);
    }

    if (!res.ok) {
      const errorMessage = data.meta?.error;
      throw new Error(errorMessage);
    }

    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

const refreshUser = async () => {
  const formData = new FormData();

  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const userKey = localStorage.getItem("userKey");

  formData.append("token", accessToken);
  formData.append("refresh_token", refreshToken);
  formData.append("refresh_key", userKey);

  try {

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/login/refresh`, {
      method: "POST",
      body: formData,
    });

    const data = await res.clone().json();

    if (!res.ok) {
      const errorMessage = data?.meta?.error || "Необработанная ошибка при запросе на refresh токен";
      throw new Error(errorMessage);
    }

    return res.json();
  } catch (error) {
    // обнуляем поля токена ключа и рефреш токена при ошибке
    removeTokens();
    toast.info("Авторизация истекла, авторизуйтесь повторно");
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

const signUp = async (user) => {
  const formData = new FormData();

  formData.append("phone", user.phone);
  formData.append("name", user.name);
  formData.append("password", user.password);

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/account/register`, {
      method: "POST",
      body: formData,
    });

    let data;
    try {
      data = await res.clone().json();
    } catch (jsonError) {
      const text = await res.clone().text();
      const errorMessage = getErrorMessage(text);
      throw new Error(errorMessage);
    }

    if (!res.ok) {
      const errorMessage = data.meta?.error;
      throw new Error(errorMessage);
    }

    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }

};

const updateUser = async (user) => {
  const data = {};
  const token = getUserToken();

  for (let key in user) {
    if (user[key]) {
      data[key] = user[key];
    }
  }

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/update`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addDeliveryAdress = async (user) => {
  const data = {};
  const token = getUserToken();

  for (let key in user) {
    if (user[key]) {
      data[key] = user[key];
    }
  }

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const removeDeliveryAdress = async (id) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/delivery-address/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const changeUserPassword = async (data) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/account/change-password`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const getFavoriteProducts = async () => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-products`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addFavoriteProduct = async (id) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`, {
    method: "POST",
    body: JSON.stringify({ product: id }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const removeFavoriteProduct = async (id) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/favorite-product`, {
    method: "DELETE",
    body: JSON.stringify({ product: id }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const getUserOrders = async (options) => {
  const requestData = Object.keys(options)?.map((item, idx) => {
    if (typeof options[item] === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${item}=${options[item]}`;
    }
    return `&${item}=${options[item]}`;
  });

  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/orders${requestData.join("")}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const getUserCart = async (options) => {
  const requestData = Object.keys(options)?.map((item, idx) => {
    if (typeof options[item] === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${item}=${options[item]}`;
    }
    return `&${item}=${options[item]}`;
  });

  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/basket${requestData.join("")}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addProductToCart = async (items) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/basket`, {
    method: "POST",
    body: JSON.stringify({ offers: items }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addProductToCartPublic = async (items) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/basket`, {
    method: "POST",
    body: JSON.stringify({ offers: items }),
  });

  return res.json();
};

const addNewOrderForAuthorizedUsers = async (options) => {
  const token = getUserToken();

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/private/order`, {
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};

const addNewOrderForNonAuthorizedUsers = async (options) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/order`, {
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

const restoreUserPassword = async (options) => {
  if (options.method === "phone" || options.method === "email") {
    const bodyData = {
      method: options.method,
      contact: options.contact,
      code: options.code,
      "new_password": options.new_password
    };


    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/account/password/restore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyData),
    });

    return res.json();
  } else {
    toast("Метод восстановления пароля указан неверно");
  }
};

const getOrderPaymentLink = async (options) => {
  const token = getUserToken();
  const url = token ? `${process.env.REACT_APP_BACKEND_URL}/api/private/payment` : `${process.env.REACT_APP_BACKEND_URL}/api/public/payment`;

  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(options),
    headers,
  });

  return res.json();
};

export const userApi = {
  checkUser,
  signIn,
  refreshUser,
  signUp,
  updateUser,
  addDeliveryAdress,
  removeDeliveryAdress,
  changeUserPassword,
  getFavoriteProducts,
  addFavoriteProduct,
  removeFavoriteProduct,
  getUserOrders,
  getUserCart,
  addProductToCart,
  addProductToCartPublic,
  addNewOrderForAuthorizedUsers,
  addNewOrderForNonAuthorizedUsers,
  restoreUserPassword,
  getOrderPaymentLink,
};
