import { useMemo } from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const CartItemPriceValue = (props) => {
  const minPrice = useMemo(() => {
    if (!props.calculatedPrice) {
      const priceWithDiscount = props.salePrice || props.discountPrice;
      return priceWithDiscount || props.mainPrice;
    }
    return props.calculatedPrice;
  }, [
    props.calculatedPrice,
    props.discountPrice,
    props.salePrice,
    props.mainPrice,
  ]);

  return (
    <div
      className={cn(styles["price-wrapper"], {
        [styles["price-wrapper--single"]]: props.mainPrice === minPrice,
      })}
    >
      {props.mainPrice === minPrice ? (
        <p className={styles["sale-price"]}>{`${props.calculatedPrice} ₽`}</p>
      ) : (
        <>
          <p className={styles["main-price"]}>
            {`${props.mainPrice * props.productAmount} ₽`}
          </p>
          <p className={styles["sale-price"]}>
            {`${minPrice * props.productAmount} ₽`}
          </p>
        </>
      )}
    </div>
  );
};

const propTypes = {
  mainPrice: PropTypes.number.isRequired,
  productAmount: PropTypes.number.isRequired,
  calculatedPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  salePrice: PropTypes.number,
};

CartItemPriceValue.propTypes = propTypes;

export default CartItemPriceValue;
