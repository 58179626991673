import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import whatsupSvg from "../../../../assets/icons/whatsup.svg";
import copySvg from "../../../../assets/icons/copy.svg";
import vkSvg from "../../../../assets/icons/vk.svg";
import telegramSvg from "../../../../assets/icons/telegram.svg";
import okSvg from "../../../../assets/icons/ok.svg";
import mailSvg from "../../../../assets/icons/mail.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/svg/star-icon.svg";

import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct,
} from "../../../../store/user/userThunks/userThunks";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import heartEmptyIcon from "../../../../assets/icons/png/heart_empty-icon.png";
import heartFoolIcon from "../../../../assets/icons/png/heart_fool-icon.png";
import shareIcon from "../../../../assets/icons/png/share-icon.png";

import styles from "./styles.module.scss";
import useOutsideClick from "../../../../utils/hooks/useOutsideClick";
import { SkeletonLine } from "../../../../components/UI/Skeleton/SkeletonLine/SkeletonLine";

const ProductHeaderLine = props => {
  const { user } = useSelector(state => state.user);
  const [isPopup, setIsPopup] = useState(false);
  const dispatch = useDispatch();
  const socialBox = useRef();
  const url = window.location.href;

  const isProductInFavourite = useMemo(() => {
    const idx = user.favouriteProducts.findIndex(
      item => item.id === props.productId
    );
    return idx !== -1;
  }, [user.favouriteProducts]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  const formattedBody = ` Я хочу порекомендовать тебе этот продукт\n ${url}`;

  const shareUrl = [
    {
      name: "vk",
      img: vkSvg,
      url: `https://vk.com/share.php?url=${url}&title=${props.productTitle}&image=${props.image}`,
    },
    {
      name: "whatsapp",
      img: whatsupSvg,
      url: `https://api.whatsapp.com/send/?text=${url}`,
    },
    {
      name: "telegram",
      img: telegramSvg,
      url: `https://t.me/share/url?url=${url}`,
    },
    {
      name: "ok",
      img: okSvg,
      url: `https://connect.ok.ru/offer?url=${url}`,
    },
    {
      name: "mail",
      img: mailSvg,
      url: "mailto:x@y.com?body=" + encodeURIComponent(formattedBody),
    },
  ];

  useOutsideClick({
    ref: socialBox,
    cb: () => setIsPopup(false),
  });

  const onToggleFavoriteStatus = async () => {
    try {
      setIsFavorite(!isFavorite);
      if (!isFavorite) {
        dispatch(addUserFavoriteProduct(props.productId));
      } else {
        dispatch(removeUserFavoriteProduct(props.productId));
      }
    } catch (err) {
      // TODO
    }
  };

  const activeBtn = () => {
    setIsPopup(!isPopup);
  };

  const socialClickHandler = url => {
    setIsPopup(!isPopup);
    window.open(url);
  };

  const copyBtn = () => {
    setIsPopup(!isPopup);
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        {!props.isLoading ? <Breadcrumbs breadcrumbs={props.breadcrumbsValues} /> : <SkeletonLine className={styles["breadcrumbs-skeleton"]} />}
      </div>
      <div className={styles.header__additional}>
        {!props.isLoading ?
          <button
            onClick={onToggleFavoriteStatus}
            className={styles.header__additional__item}
          >
            <img
              src={!isFavorite ? heartEmptyIcon : heartFoolIcon}
              alt='heart-icon'
              className={styles.header__additional__item_icon}
            />
            <span className={styles.header__additional__item_title}>
              Избранное
            </span>
          </button> : <SkeletonLine className={styles["header__additional__item_title-skeleton"]} />}
        {!props.isLoading ?
          <div className={styles.header__additional__item}>
            <img
              src={shareIcon}
              alt='share-icon'
              className={styles.header__additional__item_icon}
            />
            <div className={styles.social_container} ref={socialBox}>
              {isPopup && (
                <ul className={styles.social_container__popup}>
                  <li
                    className={styles.social_container__popup__item}
                    onClick={copyBtn}
                  >
                    <img
                      src={copySvg}
                      className={styles.social_container__popup__item_img}
                    />
                  </li>

                  {shareUrl.map(item => (
                    <li
                      className={styles.social_container__popup__item}
                      key={item.name}
                      onClick={() => socialClickHandler(item.url)}
                    >
                      <img
                        src={item.img}
                        className={styles.social_container__popup__item_img}
                      />
                    </li>
                  ))}
                </ul>
              )}

              <span
                className={styles.header__additional__item_title}
                onClick={activeBtn}
              >
                Поделиться
              </span>
            </div>
          </div>
          : <SkeletonLine className={styles["header__additional__item_title-skeleton"]} />}

        <div>
          {process.env.REACT_APP_COMPONENT_ENABLE_FEEDBACK_FOR_OFFER !==
            "false" &&
            (
              <div className={styles["star-rating"]}>
                <div className={styles["star-rating__list"]}>
                  {[...Array(5)].map((_, idx) => {
                    return (
                      <StarIcon key={idx} className={styles["star-rating__item"]} />
                    );
                  })}
                </div>
                <button className={styles["star-rating__button"]}>25 отзывов</button>
              </div>
            )}
        </div>

      </div>
    </div>
  );
};

const propTypes = {
  productId: PropTypes.number,
  breadcrumbsValues: PropTypes.arrayOf(PropTypes.object),
  productTitle: PropTypes.string,
  image: PropTypes.string,
  isLoading: PropTypes.bool,
};

ProductHeaderLine.propTypes = propTypes;

export default ProductHeaderLine;
