import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import useOutsideClick from "../../utils/hooks/useOutsideClick";

import BurgerButton from "../../components/UI/BurgerButton";
import CatalogTitles from "../AdditionalHeader/CatalogTitles";
import MobileNavigationAdditionalBlock from "./MobileNavigationAdditionalBlock";
import { ReactComponent as CrossIcon } from "../../assets/icons/svg/cross-rounded.svg";

import styles from "./styles.module.scss";

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const navigationRef = useRef(null);
  const prevLocationPath = useRef(location.pathname);

  useOutsideClick({
    ref: navigationRef,
    cb: () => setIsOpen(false),
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setIsOpen(false);
    prevLocationPath.current = location.pathname;
  }, [location.pathname]);

  const onToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.root} ref={navigationRef}>
      {isOpen ? (
        <button className={styles["cross-button"]}>
          <CrossIcon
            onClick={onToggleIsOpen}
            className={styles["cross-icon"]}
          />
        </button>
      ) : (
        <BurgerButton
          containerStyles={styles["burger-button"]}
          onClick={onToggleIsOpen}
        />
      )}
      <CSSTransition
        in={isOpen}
        timeout={isOpen ? 100 : 300}
        classNames={{
          enterActive: styles["content-enter-active"],
          enterDone: styles["content-enter-done"],
          exit: styles["content-exit"],
          exitActive: styles["content-exit-active"],
          exitDone: styles["content-exit-done"],
        }}
        mountOnEnter
      >
        <div className={styles.content}>
          <div className={styles.container}>
            <CatalogTitles containerStyles={styles["catalog-container"]} />
            <MobileNavigationAdditionalBlock onToggleIsOpen={onToggleIsOpen} />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default MobileNavigation;
