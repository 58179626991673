const getAllProducts = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/products`, {
    method: "GET",
  });

  return res.json();
};

const getProductsWithLimit = async (options) => {
  const requestData = Object.keys(options)?.map((item, idx) => {
    if (typeof options[item] === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${item}=${options[item]}`;
    }
    return `&${item}=${options[item]}`;
  });

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/products${requestData.join("")}`, {
    method: "GET",
  });

  return res.json();
};

const getProductById = async (id) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/product/v2/${id}`, {
    method: "GET",
  });

  return res.json();
};

const getDataForProfitSection = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/sale-blocks`, {
    method: "GET",
  });

  return res.json();
};

const getCatalogWithSearchOptions = ({ requestString, signal}) => {

  return new Promise((resolve, reject) => {

    setTimeout(async () => {
      try {

        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public/products${requestString}`, {
          method: "GET",
          signal,
        });

        resolve(res.json());
      } catch (error) {
        reject(error);
      }
    }, 1000);
  });
};

export const productsApi = {
  getAllProducts,
  getProductsWithLimit,
  getProductById,
  getDataForProfitSection,
  getCatalogWithSearchOptions
};