import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import { useEffect, useMemo } from "react";
import useCitiesSearch from "./../../../../../../../../utils/hooks/useCitiesSearch";
import { INIT_CITIES_OBJ } from "../../../../../../../../utils/constants";


export const CityPopup = ({ selectCity, setSearchValue, searchValueСity }) => {

  const {
    searchValue,
    searchedCities,
    onChangeSearchValue,
  } = useCitiesSearch({ limit: 15 });

  useEffect(() => {
    setSearchValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    onChangeSearchValue(searchValueСity);
  }, [searchValueСity]);

  console.log(searchValueСity);   // eslint-disable-line

  const cities = useMemo(() => {
    if (!searchValue) {
      return INIT_CITIES_OBJ.slice(0, 15);
    }
    return searchedCities;
  }, [searchedCities, searchValue]);

  if (!cities[0]) return;
  return (
    <>
      <ul className={styles.row__input__popup}>
        {cities.map((city) => {
          return (
            <li
              className={styles["row__input__popup__item"]}
              key={city.fias_id}
            >
              <button
                className={styles["row__input__popup__item-btn"]}
                onClick={() => selectCity(city.name)}>
                {city.name}
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const propTypes = {
  setSearchValue: PropTypes.func,
  selectCity: PropTypes.func,
  selectedCity: PropTypes.string,
  searchValueСity: PropTypes.string,
};

CityPopup.propTypes = propTypes;

