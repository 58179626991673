import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";

const WorkSchedule = () => {

  const workScheduleData = pageInfo.workSchedule;

  return (
    <InfoPage metaData={workScheduleData}>
      <h1 className={styles.seo}>Инфо страница График работы</h1>
      <SectionTitle
        title='График работы'
        containerStyles={styles["custom-title"]}
      />
      <div className={styles.root}>
        <p className={styles.info}>
          <p>Уважаемые покупатели!</p>
          <p>
            Интернет-магазин парфюмерии
            <Link
              data-cke-saved-href='https://parfumart.ru/'
              to='/'
              target='_blank'
              rel='noopener noreferrer'
              className={styles.item_link}
            >
              {" "}
              Parfumart.ru
            </Link>{" "}
            работает для Вас в следующем режиме:
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Заказ на сайте</h3>
          <p>
            Сделать заказ на сайте можно <strong>24</strong> часа в сутки{" "}
            <strong>7</strong> дней в неделю. Наш менеджер обязательно свяжется
            с Вами в рабочее время для подтверждения заказа.
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Клиентский отдел</h3>
          <p>
            <strong>
              Прием заказов по телефону, консультация и подтверждение заказов:
            </strong>
          </p>
          <p>
            Понедельник-Пятница: с <strong>10:00</strong> до{" "}
            <strong>19:00</strong>;Суббота: с <strong>12:00</strong> до{" "}
            <strong>19:00</strong>
          </p>
          <p>
            Воскресенье: <strong>Выходной</strong>
          </p>
          <p>
            <strong>Без перерыва</strong>
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Пункт выдачи заказов в г. Краснодар</h3>
          <p>
            г. Краснодар, ул. Меланжевая 10.&nbsp;Деловой центр
            AVM-ORSETTO, 1 этаж,&nbsp;офис 102;
          </p>
          <p>
            Понедельник-Пятница: с <strong>10:00</strong> до{" "}
            <strong>19:00</strong>;Суббота: с <strong>12:00</strong> до{" "}
            <strong>19:00</strong>
          </p>
          <p>
            Воскресенье: <strong>Выходной</strong>
          </p>
          <p>
            <strong>Без перерыва</strong>
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>
            Доставка заказов курьером по г. Краснодар
          </h3>
          <p>
            Понедельник-Пятница: с <strong>10:00</strong> до{" "}
            <strong>19:00</strong>;Суббота: с <strong>12:00</strong> до{" "}
            <strong>19:00</strong>
          </p>
          <p>
            Воскресенье: <strong>Выходной</strong>
          </p>
          <p className={styles.title}>Ждём Вас!</p>
        </p>
      </div>
    </InfoPage>
  );
};

export default WorkSchedule;
