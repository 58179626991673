import { PropTypes } from "prop-types";
import { getValueFromStorage, setValueInStorage } from "../localStorage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userApi } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import { getUserCart } from "../../store/user/cartThunks/cartThunks";
import { setPromocode } from "../../store/user/user";

const usePayment = ({orderData, onOrderCreated}) => {
  const { checkoutData } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearPaidItemsFromCart = () => {
    // clear localstorage cart by checkoutItemsIds
    const selectedItemsIdsInStorage = JSON.parse(getValueFromStorage("checkoutItemsIds")) || [];
    const currentCart = JSON.parse(getValueFromStorage("cart")) || [];
    const resultCartItems = currentCart.filter(item => !selectedItemsIdsInStorage.includes(item.id));
    setValueInStorage({ key: "cart", value: JSON.stringify(resultCartItems) });
    // get usercart after changing values of localstorage cart
    dispatch(getUserCart({}));
    dispatch(setPromocode(""));
  };

  const confirmPayment = async () => {
    const paymentType = checkoutData.paymentMethod.type;
    const orderUuid = orderData.uuid;

    if (!paymentType) {
      toast("Пожалуйста выберите способ оплаты");
      return;
    }

    if (!orderUuid) {
      toast("Произошла ошибка. Отсутствует номер заказа. Обратитесь к администрации.");
      return;
    }

    const allowedPaymentProviders = ["sber_pay", "dolymi", "card_online", "tinkoff_pay", "yandex_pay", "sbp", "yandex_split", "yookassa"];
    const isAllowPayment = allowedPaymentProviders.includes(paymentType);

    if (isAllowPayment) {
      const isSberbank = paymentType === "sber_pay" || paymentType === "card_online";
      try {
        const res = await userApi.getOrderPaymentLink({order: orderUuid, "payment_provider": `${isSberbank ? "sberbank" : paymentType}`});

        if (!res) {
          toast("Произошла проблема получения ссылки на оплату. Попробуйте еще раз позже.");
          navigate("/cart");
          throw new Error("Произошла проблема с получением данных об оплате");
        }

        if (res.meta.error) {
          toast("Произошла проблема получения ссылки на оплату. Попробуйте еще раз позже.");
          navigate("/cart");
          throw new Error(res.meta.error);
        }

        if (res.response.pay_link) {
          clearPaidItemsFromCart();
          window.location.href = res.response.pay_link;
        } else {
          return toast("Произошла ошибка. Ссылка на оплату отсутствует");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
      }

    } else {

      if (!orderData.id && !orderData.created_at) {
        toast("Произошла ошибка оформления заказа. Попробуйте перезагрузить страницу.");
        navigate("/cart");
        return;
      }
      clearPaidItemsFromCart();
      onOrderCreated();
    }
  };

  return {
    confirmPayment,
    clearPaidItemsFromCart,
  };
};

usePayment.propTypes = {
  orderData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    "created_at": PropTypes.string.isRequired,
    "delivery_amount": PropTypes.number.isRequired,
    "delivery_provider": PropTypes.string.isRequired,
    "delivery_type": PropTypes.string.isRequired,
    orderOffers: PropTypes.arrayOf(PropTypes.shape({
      count: PropTypes.number.isRequired,
      offer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        catalog: PropTypes.object.isRequired,
        "catalog_name": PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
      "sale_price": PropTypes.number.isRequired,
      "site_price": PropTypes.number.isRequired,
    })).isRequired,
    orderRecipient: PropTypes.shape({
      address: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  onOrderCreated: PropTypes.func.isRequired,
};

export default usePayment;