export const getCatalogPageBreadcrumbs = (product) => {
  const result = [];

  if (!product) {
    return [];
  }

  const categoryRewriteName = product.category?.rewrite_name;

  if (product.category) {
    result.push({
      value: product.category.name,
      link: `/catalog/${categoryRewriteName}`,
    });
  }

  const categoriesFromProperties = product.properties?.reduce((acc, item) => {
    if (
      item.property_value?.property?.rewrite_name?.toLowerCase() === "kategoria"
    ) {
      if (!categoryRewriteName) {
        acc.push({
          value: item.property_value.name,
          link: `/catalog?kategoria=${item.property_value.rewrite_name}`,
        });
      } else {
        acc.push({
          value: item.property_value?.name,
          link: `/catalog/${categoryRewriteName}?kategoria=${item.property_value.rewrite_name}`,
        });
      }
    }
    return acc;
  }, []);

  result.push(...categoriesFromProperties);

  if (product.brands?.length > 0) {
    const findedBrand = product.brands.find((item) => {
      return !!item.brand?.rewrite_name;
    });

    if (findedBrand) {
      const productBrand = {
        value: findedBrand.brand.name,
        link: `/catalog/${categoryRewriteName}?brands=${findedBrand.brand.rewrite_name}`,
      };

      result.push(productBrand);
    }
  }

  const productName = {
    value: product.name,
    link: ""
  };

  result.push(productName);

  return result;
};
