import styles from "./styles.module.scss";

const CashPayment = () => {
  return (
    <>
      <h3 className={styles.title}>Оплата наличными</h3>
      <ol className={styles.list}>
        <li className={styles.list__item}>
          <p className={styles.list__item__title}>
            <span>
              В пункте выдачи заказа г. Краснодар
            </span>
            <span>
              (комиссия 0%).
            </span>
          </p>
          <p className={styles.list__item__text}>
            Оплата производится при получении товара в пункте выдачи заказов по адресу:
            г. Краснодар, ул. Меланжевая 10. Деловой центр AVM-ORSETTO, 1 этаж, офис 102.
          </p>
          <span className={styles.list__item__text_postscript}>
            * Недоступно при получении заказов в пунктах выдачи СДЕК (только для г. Краснодара).
          </span>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__title}>
            <span>
              В пунктах выдачи заказов и курьерам ТК при получении в других регионах РФ и АР Крым
            </span>
            <span>
              (комиссия 2,9%)
            </span>
          </p>
          <p className={styles.list__item__text}>
            <p>
              <span>
                Только в ТК СДЭК и Почта России.
              </span>
              <span>
                Оплата производится при получении товара в пункте выдачи заказов.
              </span>
            </p>
          </p>
          <span className={styles.list__item__text_postscript}>
              * Недоступно при получении заказов в пунктах выдачи Boxberry 5Post.
          </span>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__title}>
            <span>
              Оплата наличными курьеру в г. Краснодар
            </span>
            <span>
              (комиссия 0%).
            </span>
          </p>
          <p className={styles.list__item__text}>
            <p>
                Оплата производится при получении товара наличными курьеру, который доставит
                заказ и выдаст товарный чек об оплате. При получении заказа внимательно осматривайте
                покупку в присутствии курьера: если Вас не устраивает внешний вид товара, или товар
                не соответствует параметрам заказа, Вы можете вернуть товар курьеру, мы обязательно
                его заменим. Вы можете отказаться от заказа полностью в присутствии курьера, оплатив
                только доставку (на основании п.3 ст. 497 ГК РФ о Правилах дистанционной торговли).
                Претензии по внешнему виду принимаются курьером только в момент доставки заказа!
            </p>
          </p>
          <span className={styles.list__item__text_postscript}>
            <span>* Цены, представленные на сайте, окончательные. Все налоги включены.</span>
            <br/>
            <span>* Оплата заказов при получении в пунктах выдачи Boxberry и 5 Post не доступна.</span>
          </span>
        </li>
      </ol>
    </>
  );
};

export default CashPayment;