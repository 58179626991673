import { useState } from "react";
import PropTypes from "prop-types";

import CartHeader from "./components/CartHeader";
import CartProductItem from "./components/CartProductItem/CartProductItem";

import styles from "./styles.module.scss";

const CartProducts = (props) => {
  const [isModal, setIsModal] = useState(false);
  const [idDelProduct, setIdDelProduct] = useState(null);

  return (
    <div className={styles.root}>
      <CartHeader isModal={isModal} setIsModal={setIsModal} idDelProduct={idDelProduct} setIdDelProduct={setIdDelProduct} />
      {!props.products.length &&
        <h3 className={styles["empty-cart"]}>
          Вы еще не добавили товары в корзину.
        </h3>
      }
      {props.products.map((item) => {
        return (
          <CartProductItem
            setIdDelProduct={setIdDelProduct}
            setIsModal={setIsModal}
            key={item.offer.id}
            product={item}
          />
        );
      })}
    </div>
  );
};

const propTypes = {
  products: PropTypes.array.isRequired,
};

CartProducts.propTypes = propTypes;

export default CartProducts;
