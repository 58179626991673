import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import SelectAddressUser from "../SelectAddressUser/SelectAddressUser";
import { deliveryApi } from "../../../../../../../api/deliveryApi";
import { PROVIDERS } from "../../../../../../../utils/constants";
import { getAvailableProviders, getAvailableProvidersWithResultTitle } from "../../../../../../../utils/providerFormatter";
import { toast } from "react-toastify";
import ProviderInfo from "../ProviderInfo/ProviderInfo";
import ProvidersListWithAddresses from "../../../ProvidersListWithAddresses/ProvidersListWithAddresses";

const MobileModalAdress = (props) => {
  const { checkoutData, currentCity } = useSelector((state) => state.user);
  const [temporaryProviders, setTemporaryProviders] = useState([]);
  const [showModal, setShowModal] = useState("PVZList");
  const [providersInfo, setProvidersInfo] = useState(null);
  const [currentProviders, setCurrentProviders] = useState([]);
  const [chosenProvider, setChosenProvider] = useState(null);

  const onCloseModal = () => {
    props.onClose();
  };

  const onToggleShowProviderInfo = () => {
    setShowModal("ProviderInfo");
  };

  const returnToPvzSelect = () => {
    setShowModal("PVZList");
  };

  const onSelectProvider = (provider) => {
    setChosenProvider(provider);
  };

  useEffect(() => {
    if (checkoutData.wayToGet === "Курьером") setShowModal("ToDoor");
    if (checkoutData.wayToGet === "Самовывоз") setShowModal("PVZList");
  }, [checkoutData.wayToGet]);


  useEffect(() => {
    (async () => {
      setTemporaryProviders([]);
      const assessedCost = checkoutData.items.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc += cur.calculated_amount;
        }
        return acc;
      }, 0);

      const data = await deliveryApi.getDeliveryCost({
        city: checkoutData.orderCity || currentCity,
        providers: Object.keys(PROVIDERS),
        assessedCost,
      });

      const formattedAvailableProviders = getAvailableProvidersWithResultTitle(data.response);
      setProvidersInfo(formattedAvailableProviders);

      const availableProviders = getAvailableProviders(data.response);
      availableProviders.forEach(async (provider) => {

        const data = await deliveryApi.getProvidersList({
          city: checkoutData.orderCity || currentCity,
          availableOperations: [2, 3],
          providers: [provider],
        });

        if (data.meta.error) {
          return toast(data.meta.error);
        }

        const providerResponse = Object.entries(data.response);
        const providerInfo = {
          type: provider,
          addresses: providerResponse[0][1],
          selected: true,
        };

        setTemporaryProviders((prev) => {
          return [...prev, {
            type: provider,
            addresses: providerResponse[0][1],
            selected: true,
          }];
        });

        setProvidersInfo((prev) => {
          return {
            ...prev,
            toPoint: prev.toPoint.map((item) => {
              if (item.deliveryProvider !== providerInfo.type) {
                return item;
              }
              return {
                ...item,
                addresses: providerInfo.addresses,
              };
            }),
          };
        });
      });
    })();
  }, [checkoutData.orderCity, currentCity]);

  useEffect(() => {
    const availableProviders = getAvailableProviders(providersInfo);
    if (temporaryProviders.length !== availableProviders.length) {
      return;
    }

    setCurrentProviders(temporaryProviders);
  }, [temporaryProviders]);

  useEffect(() => {
    if (props.showProviderInfo) setShowModal("ProviderInfo");
  }, [props.showProviderInfo]);

  return (
    <div className={styles.root}>
      {showModal === "PVZList" &&
          <ProvidersListWithAddresses
            selectedProvider={props.selectedProvider}
            setSelectedProvider={props.setSelectedProvider}
            providersInfo={providersInfo}
            changeSelectedProviderData={props.changeSelectedProviderData}
            onToggleShowProviderInfo={onToggleShowProviderInfo}
            setCity={props.setCity}
          />
      }
      {showModal === "ToDoor" &&
        <SelectAddressUser {...props}
          onCloseModal={onCloseModal}
          providersInfo={providersInfo}
          currentProviders={currentProviders}
          onSelectProvider={onSelectProvider}
          chosenProvider={chosenProvider}
        />
      }
      {showModal === "ProviderInfo" &&
        <ProviderInfo {...props}
          returnToPvzSelect={returnToPvzSelect}
          providersInfo={providersInfo}
          onCloseModal={props.onClose}
        />
      }
    </div>
  );
};

const propTypes = {
  selectedProviderData: PropTypes.object,
  providersInfo: PropTypes.object,
  selectedProvider: PropTypes.object,
  onToggleShowProviderInfo: PropTypes.func,
  changeSelectedProviderData: PropTypes.func,
  setCity: PropTypes.func,
  setSelectedProvider: PropTypes.func,
  onClose: PropTypes.func,
  showProviderInfo: PropTypes.bool,
};

MobileModalAdress.propTypes = propTypes;

export default MobileModalAdress;