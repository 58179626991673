export const ORDER_STATUSES = {
  created: {
    value: "created",
    title: "Создан"
  },
  received: {
    value: "received",
    title: "Получен",
  },
  "part_received": {
    value: "part_received",
    title: "Получен частично",
  },
  delivered: {
    value: "delivered",
    title: "Доставлен в пункт выдачи",
  },
  delivering: {
    value: "delivering",
    title: "Доставляется",
  },
  "wait_received": {
    value: "wait_received",
    title: "Ожидает получения",
  },
  packed: {
    value: "packed",
    title: "Упакован",
  },
  packing: {
    value: "packing",
    title: "Упаковывается",
  },
  confirmed: {
    value: "confirmed",
    title: "Подтвержден",
  },
  accepted: {
    value: "accepted",
    title: "В обработке",
  },
  canceled: {
    value: "canceled",
    title: "Отменен"
  },
};

export const PAYMENT_METHODS = [
  {
    type: "yookassa",
    title: "Банковская карта онлайн",
  },
  {
    type: "cash",
    title: "Наличные или наложенный платёж при получении",
  },
  {
    type: "sbp",
    title: "Система быстрых платежей",
  },
  {
    type: "card_receipt",
    title: "Банковская карта при получении",
  },
  {
    type: "sber_pay",
    title: "SberPay",
  },
  {
    type: "tinkoff_pay",
    title: "Tinkoff Pay",
  },
  {
    type: "yandex_pay",
    title: "YandexPay",
  },
  {
    type: "yandex_split",
    title: "Яндекс Сплит",
  },
  {
    type: "dolymi",
    title: "Долями",
  }
];

export const PROVIDERS = {
  parfumart: {
    type: "parfumart",
    title: "Parfumart",
  },
  cdek: {
    type: "cdek",
    title: "СДЭК",
  },
  x5: {
    type: "x5",
    title: "5Post",
  },
  boxberry: {
    type: "boxberry",
    title: "Boxberry",
    description: "Только онлайн оплата"
  },
  // rupost: {
  //   type: "rupost",
  //   title: "Почта России",
  // }
};

export const WAY_TO_GET = {
  "toDoor": "Курьером",
  "toPoint": "Самовывоз",
};

export const MEDIA = {
  mobile: 450,
  tablet: 810,
  desktop: 1024,
  largeDesktop: 1440,
};

export const SHEDULE = [
  {
    id: 0,
    title: "Понедельник",
    value: "с 10:00 до 19:00",
  },
  {
    id: 1,
    title: "Вторник",
    value: "с 10:00 до 19:00",
  },
  {
    id: 2,
    title: "Среда",
    value: "с 10:00 до 19:00",
  },
  {
    id: 3,
    title: "Четверг",
    value: "с 10:00 до 19:00",
  },
  {
    id: 4,
    title: "Пятница",
    value: "с 10:00 до 19:00",
  },
  {
    id: 5,
    title: "Суббота",
    value: "с 12:00 до 19:00",
  },
  {
    id: 6,
    title: "Воскресенье",
    value: "Выходной",
  },
];

export const INIT_CITIES = [
  "Москва",
  "Санкт-Петербург",
  "Омск",
  "Самара",
  "Волгоград",
  "Краснодар",
  "Ульяновск",
  "Иркутск",
  "Оренбург",
  "Кемерово",
  "Липецк",
  "Улан-Удэ",
  "Севастополь",
  "Белгород",
  "Орёл",
  "Калуга",
  "Тамбов",
  "Вологда",
  "Химки",
  "Ростов-на-Дону",
  "Дзержинский",
  "Стерлитамак",
  "Королёв",
  "Таганрог",
  "Прокопьевск",
  "Великий Новгород",
];

export const INIT_CITIES_OBJ = [
  {
    "fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
    name: "Москва",
    "reqion_with_type": "г Москва"
  },
  {
    "fias_id": "c2deb16a-0330-4f05-821f-1d09c93331e6",
    name: "Санкт-Петербург",
    "reqion_with_type": "г Санкт-Петербург"
  },
  {
    "fias_id": "7dfa745e-aa19-4688-b121-b655c11e482f",
    name: "Краснодар",
    "reqion_with_type": "Краснодарский край"
  },
  {
    "fias_id":"140e31da-27bf-4519-9ea0-6185d681d44e",
    name: "Омск",
    "reqion_with_type": "Омская обл"
  }
];

export const PAYMENT_STATUSES = {
  error: {
    type: "error",
    title: "Ошибка",
  },
  "not_paid": {
    type: "not_paid",
    title: "Не оплачен",
  },
  paid: {
    type: "paid",
    title: "Оплачен",
  },
  canceled: {
    type: "canceled",
    title: "Отменен",
  },
  refund: {
    type: "refund",
    title: "Частичный возврат",
  },
};

export const DEFAULT_CITY = "Краснодар";