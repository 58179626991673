import { useState, useRef} from "react";
import PropTypes from "prop-types";

import searchIcon from "../../../../../../../assets/header_icons/search-icon.svg";
import { ReactComponent as CrossIcon} from "../../../../../../../assets/icons/svg/cross-icon.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

const SearchInput = (props) => {

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {htmlElRef?.current &&  htmlElRef?.current.focus();};

    return [ htmlElRef,  setFocus ];
  };
  const [inputRef, setInputFocus] = useFocus();

  const [searchValue, setSearchValue] = useState("");


  const onChangeHandler = (e) => {

    const regex = new RegExp(`${e.target.value}`, "gi");
    const currentFilters = props.filters.filter((item) => {
      return item.name.match(regex);
    });

    setSearchValue(e.target.value);
    props.changeCurrentFilters({
      filters: currentFilters ?? [],
      isError: !currentFilters.length && !!e.target.value,
    });
  };

  const onResetSearch = () => {
    props.changeCurrentFilters({ filters: props.filters, isError: false });
    setSearchValue("");
  };

  return(
    <div className={cn(styles.root, props.containerStyles)}>
      <img
        src={searchIcon}
        alt="search-icon"
        value={searchValue}
        className={styles["search-icon"]}
        onClick={setInputFocus}
      />
      <input
        ref={inputRef}
        type="text"
        value={searchValue}
        className={styles.input}
        onChange={onChangeHandler}
        onClick={onChangeHandler}
        onFocus={onChangeHandler}
      />
      {
        !!searchValue.length &&
          <div
            className={styles["cross-button_wrapper"]}
            onClick={onResetSearch}
          >
            <CrossIcon className={styles["cross-button"]} />
          </div>
      }
    </div>
  );
};

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      "rewrite_name": PropTypes.string,
      "main_rewrite_name": PropTypes.string,
    }),
  ),
  changeCurrentFilters: PropTypes.func.isRequired,
  containerStyles: PropTypes.string,
};

SearchInput.propTypes = propTypes;

export default SearchInput;