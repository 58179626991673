import { useCallback } from "react";
import PropTypes from "prop-types";
import { SOCIAL_MEDIA } from "../../utils/constants/authSocialMedia";

import styles from "./styles.module.scss";

const SocialMedia = (props) => {
  const getHref = useCallback((vendorName) => {
    if (!vendorName) {
      return "#";
    }

    return `${process.env.REACT_APP_BACKEND_URL}/api/public/login/vendor/${vendorName}`;
  }, []);

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>
        {`Быстрая ${props.isLogin ? "авторизация" : "регистрация"} через сервисы`}
      </h4>
      <div className={styles["social-media_container"]}>
        {SOCIAL_MEDIA.map((item) => {
          return (
            <a
              key={item.id}
              className={styles["social-media"]}
              href={getHref(item.vendorName)}
              target="_self"
              rel="noopener noreferrer"
            >
              <div className={styles["social-media__icon-wrapper"]}>
                <img
                  src={item.img}
                  alt={item.alt}
                  className={styles["social-media__icon"]}
                />
              </div>
              <span className={styles["social-media__title"]}>
                {item.title}
              </span>
            </a>
          );
        })}
      </div>
    </div>
  );
};

const propTypes = {
  isLogin: PropTypes.bool.isRequired,
};

SocialMedia.propTypes = propTypes;

export default SocialMedia;
