import * as yup from "yup";
import { phoneRegExp } from "./signUpSchema";

export const signInSchema = () => {
  const validText = "Номер введён неправильно";

  return yup.object().shape({
    id: yup.string(),
    login: yup
      .string()
      .required("Номер телефона обязательное поле")
      .matches(phoneRegExp, validText),
    password: yup
      .string()
      .required("Пароль обязательное поле")
      .min(2, "Пароль должен содержать больше 1 символа"),
  });
};
