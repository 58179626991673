import { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

import useShowShadow from "../../../../../utils/hooks/useShowShadow";
import getEnglishLetters from "../../../../../utils/getEnglishLetters";

import cn from "classnames";
import styles from "./styles.module.scss";

const LettersRow = (props) => {
  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow({
    isVertical: true,
  });

  const rowRef = useRef();

  const letters = useMemo(() => {
    return getEnglishLetters();
  }, []);

  useEffect(() => {
    if (!rowRef.current) {
      return;
    }

    changeShowShadow({
      top: false,
      bottom: rowRef.current.scrollWidth > rowRef.current.clientWidth,
    });
  }, []);

  return (
    <div
      className={cn(
        styles.root,
        props.classNameRoot,
        { [styles["start-shadow"]]: showShadow.top },
        { [styles["end-shadow"]]: showShadow.bottom },
      )}
    >
      <div ref={rowRef} className={styles.letters} onScroll={onScrollHandler}>
        {letters.map((letter) => {
          return (
            <button
              key={letter}
              className={cn(styles.letters__item, props.classNameItem, {
                [styles["letters__item--selected"]]: props.selectedLetter === letter,
              })}
              onClick={() => props.onSelectLetter(letter)}
            >
              {letter}
            </button>
          );
        })}
        <button
          key="num"
          className={cn(styles.letters__item, props.classNameItem, {
            [styles["letters__item--selected"]]: props.selectedLetter === "0-9",
          })}
          onClick={() => props.onSelectLetter("0-9")}
        >
          {"0-9"}
        </button>
        <button
          key="rus"
          className={cn(styles.letters__item, props.classNameItem, styles["letters__item--special"], {
            [styles["letters__item--selected"]]: props.selectedLetter === "А-Я",
          })}
          onClick={() => props.onSelectLetter("А-Я")}
        >
          {"A-Я"}
        </button>
      </div>
    </div>
  );
};

const propTypes = {
  selectedLetter: PropTypes.string.isRequired,
  onSelectLetter: PropTypes.func.isRequired,
  classNameRoot: PropTypes.string,
  classNameItem: PropTypes.string,
};

LettersRow.propTypes = propTypes;

export default LettersRow;
