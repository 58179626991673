import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../../../api/userApi";
import { productsApi } from "../../../api/productsApi";

export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (data, { rejectWithValue }) => {
    try {
      const registeredUserData = await userApi.signUp(data);

      if (registeredUserData.meta.error) {
        throw new Error(registeredUserData.meta.error);
      }

      return registeredUserData.response;
    } catch(err) {
      const errorMessage = err?.message;
      return rejectWithValue({ message: errorMessage ?? "Не обработанная ошибка" });
    }
  }
);

export const signInUser = createAsyncThunk(
  "user/signInUser",
  async (data, { rejectWithValue }) => {
    try {
      const userData = await userApi.signIn(data);

      if (userData.meta.error) {
        throw new Error(userData.meta.error);
      }
      return userData.response;
    } catch(err) {
      const errorMessage = err?.message;
      return rejectWithValue({ message: errorMessage ?? "Не обработанная ошибка" });
    }
  }
);

export const updateUserData = createAsyncThunk(
  "user/updateUserData",
  async (data, { rejectWithValue }) => {
    try {
      const userData = await userApi.updateUser(data);

      if (userData.meta.error) {
        throw new Error(userData.meta.error);
      }

      return userData.response;
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const addDeliveryAdress = createAsyncThunk(
  "user/addDeliveryAdress",
  async (data, { rejectWithValue }) => {
    try {
      const userData = await userApi.addDeliveryAdress(data);

      if (userData.meta.error) {
        throw new Error(userData.meta.error);
      }

      return userData.response;
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const removeDeliveryAdress = createAsyncThunk(
  "user/removeDeliveryAdress",
  async (id, { rejectWithValue }) => {
    try {
      const data = await userApi.removeDeliveryAdress(id);

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }
      return data.response;
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const getUserFavoriteProducts = createAsyncThunk(
  "user/getUserFavoriteProducts",
  async (_, { rejectWithValue }) => {
    try {
      const data = await userApi.getFavoriteProducts();

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }

      return Object.values(data.response.items).map((item) => {
        return Object.values(item);
      }).flat();
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const addUserFavoriteProduct = createAsyncThunk(
  "user/addUserFavoriteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const data = await userApi.addFavoriteProduct(id);

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }
      return Object.values(data.response.items).map((item) => {
        return Object.values(item);
      }).flat();
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const removeUserFavoriteProduct = createAsyncThunk(
  "user/removeUserFavoriteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const data = await userApi.removeFavoriteProduct(id);

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }
      return Object.values(data.response.items).map((item) => {
        return Object.values(item);
      }).flat();
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const getProductOffersByIds = createAsyncThunk(
  "user/getProductOffersByIds",
  async (id, { rejectWithValue }) => {
    try {
      const data = await productsApi.getProductById(id);
      if (data.meta.error) {
        return {
          id,
          offers: {},
        };
      }

      return {
        id,
        offers: data.response.offers,
      };
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const restoreUserPassword = createAsyncThunk(
  "user/restoreUserPassword",
  async (options, {rejectWithValue}) => {
    try {
      const restoreResponse = await userApi.restoreUserPassword(options);

      if (restoreResponse.meta.error) {
        throw new Error(restoreResponse.meta.error);
      }

      return restoreResponse;
    } catch(err) {
      return rejectWithValue({ message: err.message });
    }
  }
);