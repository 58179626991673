import { useMemo } from "react";
import { PropTypes } from "prop-types";

import styles from "./styles.module.scss";

const PayOrderCreatedOrderNotification = (props) => {
  const { orderData } = props;

  const currentDate = useMemo(() => {
    const date = new Date(orderData.created_at);
    return date.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }, [orderData]);

  return (
    <div className={styles["notification"]}>
      <h3 className={styles["notification__title"]}>Информация о заказе</h3>
      <p className={styles["notification__message-block"]}>
        <span>
          Заказ № <span className={styles.primary}>{`${orderData.id}`}</span> от {`${currentDate}`}
        </span>
        <span>Успешно оформлен</span>
      </p>
    </div>
  );
};

const propTypes = {
  orderData: PropTypes.object,
};

PayOrderCreatedOrderNotification.propTypes = propTypes;

export default PayOrderCreatedOrderNotification;
