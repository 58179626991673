import { useCallback, useEffect } from "react";

const useOutsideClick = ({ ref, cb, disableClickOutside }) => {
  const handleClickOutside = useCallback(
    ({ target }) => {
      if (ref.current && !ref.current?.contains(target)) {
        if (
          (typeof disableClickOutside === "boolean" && disableClickOutside) ||
          !!target.closest(".Toastify")
        ) {
          return;
        }
        cb();
      }
    },
    [disableClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [disableClickOutside]);
};

export default useOutsideClick;