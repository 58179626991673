import { useCallback, useState } from "react";

const useShowShadow = (isVertical) => {
  const [showShadow, setShowShadow] = useState({
    top: false,
    bottom: false,
  });

  const changeShowShadow = useCallback((value) => {
    setShowShadow(value);
  }, []);

  const onScrollHandler = (e) => {
    const target = e.target;

    if (isVertical) {
      setShowShadow({
        top: target.scrollLeft > 0,
        bottom: target.scrollLeft < target.scrollWidth - target.offsetWidth,
      });
      return;
    }

    setShowShadow({
      top: target.scrollTop > 0,
      bottom: target.scrollTop < target.scrollHeight - target.offsetHeight,
    });
  };

  return {
    showShadow,
    changeShowShadow,
    onScrollHandler,
  };
};

export default useShowShadow;
