import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import cartIcon from "../../assets/header_icons/cart-icon.svg";
import searchIcon from "../../assets/header_icons/search-icon.svg";
import parfumartLogo from "../../assets/parfumart_logo.png";
import MobileNavigation from "../MobileNavigation";

import { RedirectToOldContent } from "../../components/RedirectToOldContent/RedirectToOldContent";
import BottomSheet from "../../components/UI/Bottomsheet/Bottomsheet";
import styles from "./styles.module.scss";

const MobileStickyHeader = () => {
  const { cart } = useSelector((state) => state.user);
  const [isOpenRedirectModal, setIsOpenRedirectModal] = useState(false);
  const isRedirectCart = process.env.REACT_APP_REDIRECT_CART === "true";

  const price = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc += cur.calculated_amount;
      return acc;
    }, 0);
  }, [cart]);

  const productAmount = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc += cur.count;
      return acc;
    }, 0);
  }, [cart]);

  const onClickCloseRedirectModal = () => {
    setIsOpenRedirectModal(false);
  };

  return (
    <header className={styles.root}>
      <MobileNavigation />
      <img src={searchIcon} alt="search-icon" className={styles["search-icon"]} />
      <Link to="/" className={styles["main-link"]}>
        <img src={parfumartLogo} alt="parfumart_logo" className={styles["main-link__logo"]} />
      </Link>
      <div className={styles.cart}>

        {isRedirectCart ? (
          <button onClick={() => setIsOpenRedirectModal(true)} className={styles.cart__link}>
            <img src={cartIcon} alt="cart-icon" className={styles.cart__icon} />
            <span className={styles["cart__products-amount"]}>{productAmount}</span>
          </button>
        ) : (
          <Link to="cart" className={styles.cart__link}>
            <img src={cartIcon} alt="cart-icon" className={styles.cart__icon} />
            <span className={styles["cart__products-amount"]}>{productAmount}</span>
          </Link>
        )}

        <span className={styles["cart__products-price"]}>{`${price} ₽`}</span>
      </div>
      <BottomSheet isOpen={isOpenRedirectModal} onClose={onClickCloseRedirectModal}>
        <RedirectToOldContent
          url={`${process.env.REACT_APP_REDIRECT_PARFUMART_NEW_URL}/main/basket`}
          onClose={onClickCloseRedirectModal}
          textForRedirect={"оформления заказа"}
        />
      </BottomSheet>
    </header>
  );
};

export default MobileStickyHeader;
