import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const ForCompanies = () => {

  const forCompaniesData = pageInfo.forBusiness;

  return (
    <InfoPage metaData={forCompaniesData}>
      <h1 className={styles.seo}>Инфо страница Для юридических лиц</h1>
      <SectionTitle title="Для юридических лиц" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <p className={styles.text}>
          Уважаемые предприниматели и представители крупного бизнеса!
        </p>
        <p className={styles.text}>
          На нашем сайте представлено огромное количество косметики и парфюмерии,
          включая подарочные наборы на любой вкус.
        </p>
        <p className={styles.text}>
          Мы будем рады исполнить любой корпоративный заказ, вне зависимости от его объема и денежного исчисления.
          Для этого у нас есть все необходимое: расчетный счет в банке,
          возможность предоставить необходимые закрывающие сделку документы
          (счет, товарная накладная, договор, по необходимости) и компетентный персонал,
          который проконсультирует по всему представленному на сайте ассортименту и подберет товары на заявленный бюджет.
          Стоимость товаров для Вас останется неизменной, несмотря на безналичный расчет при осуществлении сделки,
          а наш курьер привезет заказанный Вами товар в назначенные время и место.
        </p>
        <p className={styles.text}>
          Срок исполнения любого заказа: от 2 до 10 дней, в зависимости от выбранного товара и его количества.
        </p>
        <p className={styles.text}>
          Стоимость товара для Вас остается неизменной,
          несмотря на безналичный расчет при заключении сделки, наш курьер привозит заказанный Вам товар в назначенное время и место.
        </p>
        <p className={styles.text}>
          Единственный нюанс: мы работаем без НДС.
        </p>
        <p className={styles.text}>
          Будем рады сотрудничеству с Вами и поможем сэкономить Ваш корпоративный бюджет!
        </p>
        <p className={styles.text}>
        Для подачи заявки необходимо позвонить нам по<Link
            to={"/info/kontakty"}
            className={styles.text_link}
          >
            номерам
          </Link> телефона или написать письмо на адрес электронной почты:
          <a
            href="mailto:client@parfumart.ru"
            className={styles.text_link}
          >
            client@parfumart.ru
          </a>.
        </p>
      </div>
    </InfoPage>
  );
};

export default ForCompanies;