import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import TelephoneIcon from "../../../assets/header_icons/phone-icon.svg";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const Delivery = () => {

  const deliveryData = pageInfo.delivery;

  return (
    <InfoPage metaData={deliveryData}>
      <h1 className={styles.seo}>Инфо страница Доставка</h1>
      <SectionTitle title="Доставка" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <p className={styles.info}>
          <h3 className={styles.info__title}>В пункте выдачи заказов
            <Link
              data-cke-saved-href="https://parfumart.ru/"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item_link}
            > Parfumart.ru
            </Link> г. Краснодар</h3>
          <p><span className={styles["strong-text"]}>1-2 дня</span> — при наличии товара на локальном складе в г. Краснодар.</p>
          <p><span className={styles["strong-text"]}>3-7 дней</span> — при наличии товара на центральном складе в г. Москва.</p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Адрес пункта выдачи заказов:</h3>
          <p>
            г. Краснодар, ул. Меланжевая, 10, Деловой центр AVM-ORSETTO, 1 этаж, офис 102.
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Режим работы пункта выдачи заказов:</h3>
          <p>
            Понедельник-Пятница: с <span className={styles["strong-text"]}>10:00</span> до <span className={styles["strong-text"]}>19:00</span>
          </p>
          <p>Суббота: с <span className={styles["strong-text"]}>12:00</span> до <span className={styles["strong-text"]}>19:00</span></p>
          <p>Воскресенье: Выходной</p>
          <p><span className={styles["strong-text"]}>Без перерыва</span></p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>
            <img
              src={TelephoneIcon}
              alt="telephone_icon"
            />
        Телефоны:</h3>
          <p className={styles.text}>
            <span className={styles.phone_number}>8 (861) 944-21-10</span>
            <span className={styles.phone_number}>8 (918) 977-21-10</span>
            <span className={styles["special_offer"]}>Бесплатно при заказе от 1 ₽</span>
          </p>
        </p>
        <p className={styles.info}>
          <h3 className={styles["title--margin-top--0"]}>В пункты выдачи заказов СДЕК г. Краснодар</h3>
          <p><span className={styles["strong-text"]}>2-3 дня</span> — при наличии товара на локальном складе в г. Краснодар.</p>
          <p><span className={styles["strong-text"]}>4-8 дней</span> — при наличии товара на центральном складе в г. Москва.</p>
          <p className={styles["free-from"]}>Бесплатно при заказе от 3 000₽</p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Доставка по г. Краснодар</h3>
          <p><span className={styles["strong-text"]}>1-2 дня</span> — при наличии товара на локальном складе в г. Краснодар.</p>
          <p><span className={styles["strong-text"]}>3-7 дней</span> — при наличии товара на центральном складе в г. Москва.</p>
          <p className={styles["free-from"]}>* Точный срок и дату доставки уточняет менеджер клиентского отдела во время подтверждения заказа.</p>
          <p><span className={styles["special_offer"]}>Стоимость услуги - 0 ₽ (при заказе от 3 000₽) · Стоимость услуги - 100 ₽ (при заказе до 3 000₽)</span></p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.info__title}>Доставка осуществляется курьером службы доставки интернет-магазина
            <Link
              data-cke-saved-href="https://parfumart.ru/"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item_link}
            > Parfumart.ru
            </Link></h3>
          <p className={styles.sub_content}><span className={styles["strong-text"]}>Режим работы службы доставки:</span></p>
          <p className={styles.sub_content}>Понедельник-Пятница: с <span className={styles["strong-text"]}>10:00</span> до <span className={styles["strong-text"]}>19:00</span></p>
          <p className={styles.sub_content}>Суббота-Воскресенье: <span className={styles["strong-text"]}>Выходной</span></p>
        </p>
        <p className={styles.info}>
          <h3 className={styles.title}>Доставка в другие регионы Российской Федерации и АР Крым</h3>
          <p>Услуга осуществляется транспортными компаниями
            <span className={styles["strong-text"]}> СДЕК,
            Почта
            России,
            Boxberry
            </span> и через сеть
            <span className={styles["strong-text"]}> 5Post </span>
            (в магазинах Перекрёсток и Пятёрочка)</p>
          <p>Срок доставки - от <span className={styles["strong-text"]}>2</span> до <span className={styles["strong-text"]}>14</span> дней,
          с момента отправления заказа, в зависимости от региона доставки. Бесплатная доставка по всей России при заказе от
          <span className={styles["strong-text"]}> 7 000₽</span> через сеть
          <span className={styles["strong-text"]}> 5Post</span> (в магазинах <span className={styles["strong-text"]}>Перекрёсток</span> и <span className={styles["strong-text"]}>Пятёрочка</span>) </p>
          <p><span className={styles["special_offer"]}>*Оплата и проверка при получении!</span></p>
          <p>*При выборе <span className={styles["strong-text"]}>Boxberry</span> - только онлайн оплата на сайте <span className={styles["strong-text"]}>(дополнительная скидка 3%)</span></p>
          <p>*При выборе <span className={styles["strong-text"]}>5Post</span> - только онлайн оплата на сайте</p>
          <p>
            <span className={styles["strong-text"]}>Внимание!</span> При сумме заказа менее
            <span className={styles["strong-text"]}> 1000 ₽</span> (без учёта доставки),
            отправка заказа в регионы осуществляется только после полной предоплаты.
          </p>
        </p>
      </div>
    </InfoPage>
  );
};

export default Delivery;
