import React from "react";
import PropTypes from "prop-types";

import { HeadHelmet } from "../../utils";

import SectionTitle from "../../components/SectionTitle";

import styles from "./styles.module.scss";

const InfoPage = ({ metaData, ...props }) => {

  return (
    <React.Fragment>
      <HeadHelmet
        title={metaData && metaData.title ? metaData.title : props.title}
        description={metaData && metaData.description ? metaData.description : props.description}
      />
      <div className={styles.root}>
        {props.title && (<SectionTitle title={props.title} />)}
        {props.children}
      </div>
    </React.Fragment>
  );
};

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  metaData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

const defaultProps = {
  children: undefined,
  description: undefined,
  metaData: undefined,
};

InfoPage.propTypes = propTypes;
InfoPage.defaultProps = defaultProps;

export default InfoPage;