import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useOutsideClick from "../../../../utils/hooks/useOutsideClick";
import useSearchParamsHandler from "../../../../utils/hooks/useSearchParamsHandler";
import { setAdditionalParams } from "../../../../store/catalog";

import SortInnerContent from "../SortInnerContent";
import BottomSheet from "../../../../components/UI/Bottomsheet/Bottomsheet";
import arrowIcon from "../../../../assets/icons/png/small-arrow.png";

import cn from "classnames";
import styles from "./styles.module.scss";

export const SORT_TITLES = [
  {
    id: 0,
    name: "Популярное",
    "rewrite_name": "showed",
    "order_by": "desc",
  },
  {
    id: 1,
    name: "По алфавиту",
    "rewrite_name": "name",
    "order_by": "asc",
  },
  {
    id: 2,
    name: "По возрастанию цены",
    "rewrite_name": "price",
    "order_by": "asc",
  },
  {
    id: 3,
    name: "По убыванию цены",
    "rewrite_name": "price",
    "order_by": "desc",
  },
];

const SortButton = () => {
  const { deviceType } = useSelector(state => state.user);
  const { sort_by: sortByValue, order_by: orderByValue } = useSelector(
    (state) => state.catalog.additionalParams
  );
  const [currentTitle, setCurrentTitle] = useState(SORT_TITLES[0].name);
  const [isOpen, setIsOpen] = useState(false);

  const { onChangeSearchParams } = useSearchParamsHandler();

  const blockRef = useRef(null);

  const dispatch = useDispatch();

  useOutsideClick({
    ref: blockRef,
    cb: () => setIsOpen(false),
  });

  useEffect(() => {
    const findedElem = SORT_TITLES.find(item => {
      return (
        sortByValue === item.rewrite_name && orderByValue === item.order_by
      );
    });
    setCurrentTitle(findedElem?.name ?? SORT_TITLES[0].name);
  }, [sortByValue, orderByValue]);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const onSelectTitle = id => async () => {
    const findedItem = SORT_TITLES.find(item => {
      return item.id === id;
    });
    const condition = currentTitle === findedItem.name;
    if (condition) return;

    const searchOptions = {
      "sort_by": !condition ? findedItem.rewrite_name : "",
      "order_by": !condition ? findedItem.order_by : "",
    };

    setIsOpen(false);
    setCurrentTitle(!condition ? findedItem.name : SORT_TITLES[0].name);
    onChangeSearchParams(searchOptions);
    dispatch(setAdditionalParams({
      value: searchOptions,
      shouldTriggerSearch: true,
    }));
  };

  return (
    <div className={styles.root} ref={blockRef}>
      <button className={styles.button} onClick={onClickHandler}>
        <span className={styles.title}>Сортировать:</span>
        <span className={styles["sort-title"]}>{currentTitle}</span>
        {!deviceType.isMobile && (
          <img
            src={arrowIcon}
            alt="arrow"
            className={cn(styles.arrow, {
              [styles["arrow--rotated"]]: isOpen,
            })}
          />
        )}
      </button>
      {isOpen && !deviceType.isMobile && (
        <SortInnerContent
          currentTitle={currentTitle}
          titles={SORT_TITLES}
          onSelectTitle={onSelectTitle}
          containerStyles={styles["inner-content"]}
        />
      )}
      {!!deviceType.isMobile && (
        <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <SortInnerContent
            currentTitle={currentTitle}
            titles={SORT_TITLES}
            onSelectTitle={onSelectTitle}
            containerStyles={styles.bottomsheet__content}
          />
        </BottomSheet>
      )}
    </div>
  );
};

export default SortButton;
