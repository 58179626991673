import { nanoid } from "@reduxjs/toolkit";
import popularSectionLogos from "./constants/popularSectionLogos";

const getPopularBlockItems = (isMobile) => {
  return [
    {
      id: nanoid(),
      title: "Для макияжа",
      src: popularSectionLogos.makeupIcon,
      link: "/catalog/kosmetika?kategoria=dla-makiaza",
      alt: "makeup-icon",
      width: isMobile ? "70px" : "133px",
      height: isMobile ? "70px" : "133px",
    },
    {
      id: nanoid(),
      title: "Селективная парфюмерия",
      src: popularSectionLogos.selectiveIcon,
      link: "/catalog/parfumeria?kategoria=selektivnaa",
      alt: "selective-icon",
      width: isMobile ? "70px" : "115px",
      height: isMobile ? "70px" : "118px",
    },
    {
      id: nanoid(),
      title: "Мужская косметика",
      src: popularSectionLogos.forMensIcon,
      link: "/catalog/kosmetika?pol=dla-muzcin",
      alt: "for_mens-icon",
      width: isMobile ? "80px" : "146px",
      height: isMobile ? "70px" : "127px",
    },
    {
      id: nanoid(),
      title: "Для детей",
      src: popularSectionLogos.forChildsIcon,
      link: "catalog/kosmetika?kategoria=dla-detej",
      alt: "for_childs-icon",
      width: isMobile ? "70px" : "144px",
      height: isMobile ? "70px" : "143px",
    },
  ];
};

export default getPopularBlockItems;
