import { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { addProductToCart } from "../../../../../store/user/cartThunks/cartThunks";
import getSortedOffersByPriceAndVolume from "../../../../../utils/getSortedOffersByPriceAndVolume";

import saleTicketIcon from "../../../../../assets/icons/png/sale_ticket-icon.png";
import Cosmetics from "../../../../../assets/icons/svg/cosmetics.svg";

import ProductOffersHeader from "../../ProductOffersHeader";
import MobileOffersList from "./components/MobileOffersList";
import DolyamiDropDown from "../../ProductMainContent/components/DropDowns/DolyamiDropDown";
import HelpDropDown from "../../ProductMainContent/components/DropDowns/HelpDropDown";
import CostCalculationDropDown from "../../ProductMainContent/components/DropDowns/CostCalculationDropDown";
import { metric } from "../../../../../utils/yandexMetrics/yandexMetrics";

import styles from "./styles.module.scss";
import BottomSheet from "../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentFoundCheaper from "../../../../../components/UI/ContentFoundCheaper/ContentFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";

const MobileMainProductContent = (props) => {
  const { cart } = useSelector((state) => state.user);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const dispatch = useDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  const [, setSearchParams] = useSearchParams();

  const sortedOffers = useMemo(() => {
    if (!props.product || props.isLoading) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
  }, [props.offers, props.isLoading]);

  const [currentOffer, setCurrentOffer] = useState({
    id: 0,
    description: "",
    promotionPrice: null,
    price: null,
    percent: null,
  });

  const [activeOfferId, setActiveOfferId] = useState(0);

  const onSelectOffer = (options) => {
    setCurrentOffer(options);
    setActiveOfferId(options.id);
    setSearchParams({ type: options.id });
  };

  const wordsArr = props.product?.name.split(" ");
  const fiveWords = wordsArr?.splice(0, 5);
  const text = fiveWords?.lenght <= wordsArr?.length ? fiveWords?.join(" ") : fiveWords?.join(" ") + "...";

  const addProduct = async () => {
    if (process.env.REACT_APP_REDIRECT_CART === "true") return props.setIsOpenRedirectModalCart(true);
    if (!props.product) {
      return;
    }

    try {
      const options = {
        id: currentOffer.id,
        count: 1,
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");
    } catch (err) {
      // TODO
    }
  };
  const productInCart = useMemo(() => cart.find(e => e.offer.id === currentOffer.id), [cart, currentOffer]);

  const addProductAmount = () => {
    try {
      const options = {
        id: currentOffer.id,
        count: productInCart.count + 1,
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");
    } catch (err) {
      // TODO
    }
  };

  const noImageLink = useMemo(() => {
    return props.product?.category.rewrite_name === "kosmetika"
      ?
      "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg" :
      "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category.rewrite_name]);

  const mainSrc = !props.isLoading && props.product?.image?.link ? props.product?.image?.link : noImageLink;

  useEffect(() => {
    if (props.isLoading) return;
    const filteredOffered = sortedOffers.filter(offer => offer.id === Number(type));

    if (filteredOffered[0]) {
      setCurrentOffer({
        id: filteredOffered[0]?.id || null,
        description: filteredOffered[0]?.name ?? "",
        promotionPrice: filteredOffered[0]?.price_sale ?? filteredOffered[0]?.akcia ?? null,
        price: filteredOffered[0]?.price ?? null,
        percent: filteredOffered[0]?.percent ?? null,
      });
      return setActiveOfferId(filteredOffered[0]?.id ?? 0);
    }

    setCurrentOffer({
      id: sortedOffers[0]?.id || null,
      description: sortedOffers[0]?.name ?? "",
      promotionPrice: sortedOffers[0]?.price_sale ?? sortedOffers[0]?.akcia ?? null,
      price: sortedOffers[0]?.price ?? null,
      percent: sortedOffers[0]?.percent ?? null,
    });

    setActiveOfferId(sortedOffers[0]?.id ?? 0);
  }, [sortedOffers]);

  useLayoutEffect(() => {
    if (sortedOffers.length > 0) {
      const firstOffer = sortedOffers[0];
      if (!type) {
        setSearchParams({ type: firstOffer.id });
        setCurrentOffer(firstOffer);
      } else {
        const selectedOffer = sortedOffers.find(offer => offer.id === Number(type));
        if (!selectedOffer) {
          setCurrentOffer({
            id: null,
            description: "",
            promotionPrice: null,
            price: null,
            percent: null,
            available: null,
          });
          return;
        }
        setCurrentOffer(selectedOffer);
      }
    }
  }, [sortedOffers, type, setSearchParams]);

  return (
    <section
      className={styles.root}
    >
      <h4 className={styles["vendor-code"]}>Артикул: {props?.product?.vendorCode}</h4>
      <ProductOffersHeader
        brandRewrite_name={props.product?.brands[0].brand.rewrite_name}
        brandName={props.product?.brands[0].brand.name}
        productName={props.product?.name}
        currentOffer={currentOffer}
        isLoading={props.isLoading}
      />
      <span className={styles["select-title"]}>Выберите вариант товара</span>
      {(props.isLoading || !loadImg) &&
        <img
          src={Cosmetics}
          className={styles["product-image-svg"]}
        />
      }
      {!props.isLoading &&
        <img
          itemProp="image"
          src={mainSrc}
          alt={props.product?.image?.product_rewrite_name}
          className={
            cn(
              styles["product-image"],
              { [styles["product-image-disabled"]]: props.isLoading || !loadImg }
            )}
          onLoad={() => setLoadImg(true)}
        />
      }
      <MobileOffersList
        productId={props.product?.id}
        activeOfferId={activeOfferId}
        offers={sortedOffers}
        onSelectOffer={onSelectOffer}
      />
      <button className={styles["button-cheap"]} onClick={() => setIsOpenBottomSheet(true)}>
        <img
          src={saleTicketIcon}
          alt="sale_ticket-icon"
          className={styles["button-cheap__icon"]}
        />
        <span className={styles["button-cheap__title"]}>
          Нашли дешевле? Сообщите нам!
        </span>
      </button>
      <button
        className={
          cn(styles["button-cart"], { [styles["button-cart-disabled"]]: props.isLoading })
        }
        onClick={!productInCart ? addProduct : addProductAmount}
      >
        {productInCart ?
          <span className={styles["button-cart--title"]}>Добавить ещё</span> :
          <span className={styles["button-cart--title"]}>Добавить в корзину</span>
        }
        {currentOffer.price && <span className={styles["button-cart__price"]}>
          {!currentOffer.promotionPrice ? (
            `${currentOffer.price} ₽`
          ) : (
            <>
              <span className={styles["button-cart__price--promo"]}>
                {`${currentOffer.promotionPrice} ₽ `}
              </span>
              <span className={styles["button-cart__price--main"]}>
                {`${currentOffer.price} ₽ `}
              </span>
            </>
          )}
        </span>
        }

      </button>
      <div className={styles.dropdowns}>
        {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN === "true" && <DolyamiDropDown price={currentOffer.promotionPrice ?? currentOffer.price} />}
        <CostCalculationDropDown price={currentOffer.promotionPrice ?? currentOffer.price} />
        <HelpDropDown />
      </div>
      <BottomSheet
        isOpen={isOpenBottomSheet}
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <ContentFoundCheaper onClose={() => setIsOpenBottomSheet(false)} />
      </BottomSheet>
    </section>
  );
};

const propTypes = {
  product: PropTypes.object,
  offers: PropTypes.array,
  isLoading: PropTypes.bool,
  setIsOpenRedirectModalCart: PropTypes.func,
};

MobileMainProductContent.propTypes = propTypes;

export default MobileMainProductContent;
