import { Children } from "react";
import Portal from "../../HOC/Portal";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";


const CustomModal = (props) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__container} ref={props.modalRef}>
        {Children.map(props.children, (child) => (
          <div>{child}</div>
        ))}
      </div>
      <div className={styles.modal__background} />
    </div>
  );
};

const propTypes = {
  children: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  url: PropTypes.string,
};

CustomModal.propTypes = propTypes;

export default Portal(CustomModal);
