import * as yup from "yup";

const cityRegExp = /^[a-zA-Z, а-яёА-ЯЁ,.'-]+$/i;
const numberRegExp = /\d+/g;
const houseNumberRegExp = /\d+[А-Я|a-я/\d]*/gm;

export const adressSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("\"Название адреса\" является обязательным полем"),
  city: yup.string().matches(cityRegExp, "\"Название города\" недопустимо").required("\"Город\" является обязательным полем"),
  street: yup.string().required("\"Улица\" является обязательным полем"),
  house: yup.string().matches(houseNumberRegExp, "\"Номер дома\" недопустим").required("\"Номер дома\" является обязательным полем"),
  flat: yup.string().matches(numberRegExp, "\"Номер квартиры\" недопустим"),
  floor: yup.string().matches(numberRegExp, "\"Номер этажа\" недопустим"),
  entrance: yup.string().matches(numberRegExp, "\"Номер подъезда\" недопустим"),
});