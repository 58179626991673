import { useRef } from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../../../components/CustomButton";

import styles from "./styles.module.scss";
import useOutsideClick from "../../../../../../utils/hooks/useOutsideClick";

const DeleteAdressModal = (props) => {
  const modalRef = useRef(null);

  useOutsideClick({
    ref: modalRef,
    cb: props.onClose,
  });

  return(
    <div className={styles.root} ref={modalRef}>
      <span className={styles.title}>
          Подтвердить удаление?
      </span>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Да"
          type="button"
          isWhite
          className={styles.button}
          onClick={props.onDelete}
        />
        <CustomButton
          title="Нет"
          type="button"
          className={styles.button}
          onClick={props.onClose}
        />
      </div>
    </div>
  );
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteAdressModal.propTypes = propTypes;

export default DeleteAdressModal;