import { useEffect, useMemo, useRef, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { deliveryApi } from "../../api/deliveryApi";
import {
  setDeliveryCoordinates,
  setUserDeliveryAddress,
} from "../../store/user/user";
import {
  addAvailableProvidersOnMap,
  addProviderCollectionToMap,
  changePlacemartCoordinates,
  getCityCoordinates,
  getPlacemarkOnMap,
  setMapCenter,
} from "../ymapsUtils";
import { PROVIDERS } from "../constants";
import {
  getAvailableProviders,
  getAvailableProvidersWithResultTitle,
} from "../providerFormatter";


const useYandexMap = (options) => {
  const { checkoutData, currentCity } = useSelector((state) => state.user);
  const [map, setMap] = useState(null);
  const [userPlacemark, setUserPlacemark] = useState(null);
  const [currentProviders, setCurrentProviders] = useState([]);
  const [temporaryProviders, setTemporaryProviders] = useState([]);
  const [providersInfo, setProvidersInfo] = useState(null);
  const [features, setFeatures] = useState();
  const [isPanding, startTransition] = useTransition();  // eslint-disable-line
  const [chosenProvider, setChosenProvider] = useState(null);
  const dispatch = useDispatch();

  const objectManagerRef = useRef(null);

  const coordinates = useMemo(() => {
    return checkoutData.coordinates;
  }, [checkoutData.coordinates]);

  useEffect(() => {
    if (!options.showMap) {
      return;
    }

    window.ymaps.ready(async () => {
      try {
        const getCoordinates = async () => {
          try {
            const data = await getCityCoordinates(checkoutData.orderCity || currentCity);
            if (!data) {
              throw new Error("Получаемые координаты отсутствуют или undefined");
            }
            if (!data.length) {
              throw new Error("Ошибка при получении координат. Координаты отсутствуют");
            }
            dispatch(setDeliveryCoordinates(data));
            return data;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Ошибка при получении координат:", error);
            throw new Error("Не удалось получить координаты");
          }
        };

        let currentCoordinates;
        try {
          const isCoordinates = coordinates && Array.isArray(coordinates) && coordinates.length !== 0;
          currentCoordinates = isCoordinates ? coordinates : await getCoordinates();
        } catch (error) {
          // Handle the error when getCoordinates fails
          toast("Координаты выбранного города не были найдены. Попробуйте перезагрузить страницу");
          // eslint-disable-next-line no-console
          console.error("Ошибка в блоке определения координат currentCoordinates:", error);
        }

        const newMap = new window.ymaps.Map("map", {
          center: currentCoordinates,
          zoom: 11,
          controls: [],
        });

        setMap(newMap);
        objectManagerRef.current = new window.ymaps.ObjectManager({
          clusterize: true,
        });
        const customZoomControl = new window.ymaps.control.ZoomControl({
          options: {
            size: "auto",
            position: {
              right: 10,
              top: 10,
            },
          },
        });

        newMap.controls.add(customZoomControl);

        setMapCenter({
          map: newMap,
          coordinates: currentCoordinates,
        });

        const placemark = getPlacemarkOnMap({
          map: newMap,
          coordinates: currentCoordinates,
          isDraggable: true,
          cb: async (coords) => {
            try {
              dispatch(setDeliveryCoordinates(coords));
              const data = await deliveryApi.getDeliveryAddress({
                query: "",
                city: "",
                lat: coords[0],
                lon: coords[1],
              });
              if (data.meta.error) {
                toast(data.meta.error);
                throw new Error(data.meta.error);
              }
              dispatch(setUserDeliveryAddress(data.response.items[0]));
            } catch (err) {
              // TODO
              // eslint-disable-next-line no-console
              console.error("Ошибка при получение адресов доставки", err);
            }
          },
        });

        setUserPlacemark(placemark);

        const promos = document.querySelector(
          ".ymaps-2-1-79-map-copyrights-promo"
        );
        promos.style.display = "none";
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Ошибка при инициализации карты:", error);
        toast("Не удалось инициализировать карту. Попробуйте перезагрузить страницу", { type: "error" });
      }
    });
  }, [options.showMap]);

  useEffect(() => {
    if (!map || !options.showMap) return;
    (async () => {
      try {

        if (objectManagerRef.current) {
          objectManagerRef.current.removeAll();
        }

        const assessedCost = checkoutData.items.reduce((acc, cur) => {
          if (cur.isSelected) {
            acc += cur.calculated_amount;
          }
          return acc;
        }, 0);

        const data = await deliveryApi.getDeliveryCost({
          city: checkoutData.orderCity || currentCity,
          providers: Object.keys(PROVIDERS),
          assessedCost,
        });

        const availableProviders = getAvailableProviders(data.response);

        const formattedAvailableProviders = getAvailableProvidersWithResultTitle(data.response);
        setProvidersInfo(formattedAvailableProviders);

        changePlacemartCoordinates({
          map,
          coordinates,
          userPlacemark,
        });

        setTemporaryProviders([]);
        setFeatures([]);

        addAvailableProvidersOnMap({
          map,
          city: checkoutData.orderCity || currentCity,
          availableProviders,
          objectManager: objectManagerRef.current,
          setAvailableProviders: (provider) => {
            setTemporaryProviders((prev) => {
              return [...prev, provider];
            });
            setProvidersInfo((prev) => {
              return {
                ...prev,
                toPoint: prev.toPoint.map((item) => {
                  if (item.deliveryProvider !== provider.type) {
                    return item;
                  }
                  return {
                    ...item,
                    addresses: provider.addresses,
                  };
                }),
              };
            });
          },
          setFeatures
        });

      } catch (err) {
        // TODO
      }
    })();
  }, [map, coordinates, checkoutData.orderCity]);

  useEffect(() => {
    startTransition(() => {
      if (!features?.[0]) return;
      const promos = document.querySelector(
        ".ymaps-2-1-79-placemark-overlay"
      );
      objectManagerRef.current.removeAll();
      const filteredFeatures = features.filter(e => options.providersActive[e.geometry.providerType]);

      if (checkoutData.wayToGet === "Курьером") {
        objectManagerRef.current.removeAll();
        promos && (promos.style.display = "block");
        changePlacemartCoordinates({
          map,
          coordinates,
          userPlacemark,
        });
      }
      if (checkoutData.wayToGet === "Самовывоз") {
        promos && (promos.style.display = "none");
        addProviderCollectionToMap({
          map,
          collection: {
            type: "FeatureCollection",
            features: filteredFeatures,
          },
          objectManager: objectManagerRef.current,
        });
      }
    });
  }, [features, checkoutData.wayToGet, options.providersActive]);

  useEffect(() => {
    const availableProviders = getAvailableProviders(providersInfo);
    if (temporaryProviders.length !== availableProviders.length) {
      return;
    }
    setCurrentProviders(temporaryProviders);
  }, [temporaryProviders]);

  useEffect(() => {
    if (!objectManagerRef.current) {
      return;
    }
    const unselectedProviders = currentProviders.reduce((acc, cur) => {
      if (!cur.selected) {
        acc.push(cur.type);
        return acc;
      }
      return acc;
    }, []);

    objectManagerRef.current.setFilter((point) => {
      if (unselectedProviders.includes(point.geometry.providerType)) {
        return;
      }
      return point;
    });
  }, [currentProviders]);

  const onSelectProvider = (provider) => {
    setChosenProvider(provider);
  };

  return {
    map,
    mapObjectManager: objectManagerRef.current,
    userPlacemark,
    currentProviders,
    providersInfo,
    onSelectProvider,
    chosenProvider,
  };
};

export default useYandexMap;
