import { useSearchParams } from "react-router-dom";

const useSearchParamsHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDeleteParams = (params) => {
    params.forEach((item) => {
      searchParams.delete(item);
    });
    setSearchParams(searchParams);
  };

  const onSaveParams = (params) => {
    Object.entries(params).forEach((item) => {
      searchParams.set(item[0], item[1]);
    });
    setSearchParams(searchParams);
  };

  const onChangeSearchParams = (params) => {
    Object.entries(params).forEach((item) => {
      if (!item[1]?.length) {
        searchParams.delete(item[0]);
        return;
      }
      searchParams.set(item[0], item[1]);
    });
    setSearchParams(searchParams);
  };

  const handleChangeSingleSearchParam = ({ key, queryString }) => {
    if (!queryString.length) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, queryString);
    }

    setSearchParams(searchParams);
  };

  return {
    searchParams,
    onDeleteParams,
    onSaveParams,
    onChangeSearchParams,
    handleChangeSingleSearchParam,
  };
};

export default useSearchParamsHandler;
