import { useState, useEffect, useRef, memo } from "react";

import CustomDropDown from "../../../../../../../components/CustomDropDown";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/svg/plus-icon.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import useOutsideClick from "../../../../../../../utils/hooks/useOutsideClick";
import { deliveryApi } from "../../../../../../../api/deliveryApi";
import { PROVIDERS } from "../../../../../../../utils/constants";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import { CityPopup } from "./CityPopup/CityPopup";

const CostCalculationDropDown = ({ price, ...props }) => {
  const { currentCity } = useSelector(state => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedCity, setSelectedCity] = useState(currentCity);
  const [searchValue, setSearchValue] = useState("");
  const [priceDelivery, setPriceDelivery] = useState({
    toDoor: null,
    toPoint: null,
  });
  const popupRef = useRef(null);
  const providers = [];

  for (const key in PROVIDERS) {
    providers.push({ ...PROVIDERS[key] });
  }

  const outsideClickHandler = () => {
    setIsOpenPopup(false);
  };

  useOutsideClick({
    ref: popupRef,
    cb: outsideClickHandler
  });

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const selectCity = (city) => {
    setSelectedCity(city);
    setIsOpenPopup(false);
  };

  const getDelivery = (data, method) => {
    return data[method]?.reduce((object, value,) => {
      return { ...object, [value.deliveryProvider]: value };
    }, {});
  };

  const getPriceDelivery = async (selectedCity) => {
    const data = await deliveryApi.getDeliveryCost({
      city: selectedCity,
      providers: Object.keys(PROVIDERS),
      assessedCost: price
    });

    if (!data) return toast("К сожалению произошла ошибка, повторите операцию позже");

    setPriceDelivery({
      toDoor: getDelivery(data.response, "toDoor") || null,
      toPoint: getDelivery(data.response, "toPoint") || null,
    });
  };

  const onFocus = () => {
    setIsOpenPopup(true);
    setSearchValue("");
  };

  useEffect(() => {
    setSearchValue(currentCity);
    setSelectedCity(currentCity);
  }, [currentCity]);

  useEffect(() => {
    if (isOpenPopup) return;
    setSearchValue(selectedCity);
  }, [selectedCity, isOpenPopup]);


  useEffect(() => {
    if (!isOpen) return;
    getPriceDelivery(selectedCity);
  }, [selectedCity, isOpen]);

  return (
    <CustomDropDown
      isOpen={isOpen}
      containerStyles={
        cn(styles.dropdown, {
          [styles["disable"]]: props.isLoading,
        })}
    >
      {{
        buttonElement: (
          <button className={styles.button} onClick={onClickHandler}>
            <p className={styles.button__info_title}>
              Расчёт стоимости доставки
            </p>
            <div className={styles["plus-icon-wrapper"]}>
              <PlusIcon
                className={cn(styles["plus-icon"], {
                  [styles["plus-icon--rotated"]]: isOpen,
                })}
              />
            </div>
          </button>
        ),
        dropdownElement: (
          <div className={styles.table}>
            <div className={cn(styles.table__row, styles.row__city)}>
              <span className={styles.row__title}>Населённый пункт</span>
              <div className={styles.row__wrapper} ref={popupRef}>

                <input
                  type="text"
                  placeholder="Выберите населенный пункт"
                  className={styles.row__input}
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  onFocus={() => onFocus()}
                />

                {isOpenPopup &&
                  <CityPopup
                    setSearchValue={setSearchValue}
                    selectCity={selectCity}
                    searchValueСity={searchValue}
                  />
                }

              </div>
            </div>
            <div className={styles["table-wrapper"]}>
              <div className={styles["table-wrapper__column-right"]}>
                <p className={styles["table-wrapper__column-title"]}>Транспортная компания</p>
                <p className={styles["table-wrapper__column-title"]}>Самовывоз</p>
                <p className={styles["table-wrapper__column-title"]}>Доставка</p>
              </div>
              <div className={styles["table-wrapper_content"]}>
                {providers.map(provider => {
                  const toPoint = priceDelivery.toPoint?.[provider.type] && priceDelivery.toPoint?.[provider.type].deliveryCost;
                  const toDoor = priceDelivery.toDoor?.[provider.type] && priceDelivery.toDoor?.[provider.type].deliveryCost;
                  if (!toPoint && !toDoor) return;

                  return (
                    <div key={provider.type} className={styles["table-wrapper__column"]} >
                      <p className={cn(styles["table-wrapper__column-title"],
                        styles["table-wrapper__column-price"])}>{provider.title}</p>
                      <p className={cn(styles["table-wrapper__column-title"],
                        styles["table-wrapper__column-price"])}>
                        {toPoint ? toPoint + " p" : "-"}
                      </p>
                      <p className={cn(styles["table-wrapper__column-title"],
                        styles["table-wrapper__column-price"])}>
                        {toDoor ? toDoor + " p" : "-"}
                      </p>
                    </div>
                  );
                })}
              </div>

            </div>
          </div>
        ),
      }}
    </CustomDropDown >
  );
};

const propTypes = {
  price: PropTypes.number,
  isLoading: PropTypes.bool,
};

CostCalculationDropDown.propTypes = propTypes;


export default memo(CostCalculationDropDown);
