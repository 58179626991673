import PropTypes from "prop-types";
import close from "../../../assets/icons/svg/cross-rounded.svg";
import Portal from "../../../HOC/Portal";

import cn from "classnames";
import styles from "./styles.module.scss";

const BigModal = (props) => {
  return (
    <div
      ref={props.modalRef}
      className={cn(styles.root, props.containerStyles)}
    >
      <div className={styles.head}>
        <button
          className={styles.button}
          onClick={props.onClose}
        >
          <img src={close} className={styles["button--img"]} />
        </button>
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
};


const propTypes = {
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  containerStyles: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BigModal.propTypes = propTypes;

export default Portal(BigModal);