import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import cn from "classnames";

import { WAY_TO_GET } from "../../../../../utils/constants";
import useOutsideClick from "../../../../../utils/hooks/useOutsideClick";
import useDelivery from "../../../../../utils/hooks/useDelivery";
import {
  setDeliveryCoordinates,
  setProviderInfo,
  setUserDeliveryAddress,
  setWayToGet,
} from "../../../../../store/user/user";

import ProvidersTitlesList from "./components/ProvidersTitlesList/ProviderTitleItem";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";

const SelectAddressForOrder = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState(
    checkoutData.recipient.address
  );
  const [showAutocompleteList, setShowAutocompleteList] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);

  const { deliveryAddresses, setDeliveryAddressByPosition } = useDelivery({
    isFocusInput,
    searchValue,
    setSearchValue,
  });
  const dispatch = useDispatch();

  const autocompleteListRef = useRef(null);
  useOutsideClick({
    ref: autocompleteListRef,
    cb: () => {
      setIsFocusInput(false);
      setShowAutocompleteList(false);
    },
  });

  useEffect(() => {
    setSearchValue(checkoutData.recipient.address);
  }, [checkoutData.recipient.address]);

  const handleChange = async (event) => {
    setSearchValue(event.target.value);
  };

  const selectAddress = async (address) => {
    const result = await window.ymaps.geocode(address);
    const coordinates = result.geoObjects.get(0).geometry.getCoordinates();
    dispatch(setUserDeliveryAddress(address));
    dispatch(setDeliveryCoordinates(coordinates));
    setSearchValue(address);
    setShowAutocompleteList(false);
  };

  const acceptAddress = () => {

    if (!props.chosenProvider) {
      return toast("Выберите фирму доставки");
    }

    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })[0];

    const findedItem = props.providersInfo[wayToGetKey].find(
      (item) => item.deliveryProvider === props.chosenProvider?.type
    );

    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(
      setProviderInfo({
        provider: findedItem.deliveryProvider,
        amount: findedItem.deliveryCost,
        daysMax: findedItem.daysMax,
        daysMin: findedItem.daysMin,
        type: deliveryType,
      })
    );
    props.onCloseModal();
  };

  const onFocusHandler = () => {
    setIsFocusInput(true);
    setShowAutocompleteList(true);
  };

  const selectWayToGet = (way) => {
    dispatch(setWayToGet(way));
  };

  return (
    <div className={styles.root}>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title={WAY_TO_GET.toPoint}
          onClick={() => selectWayToGet(WAY_TO_GET.toPoint)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toPoint}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toPoint}
          className={cn(
            styles.buttons__pickup,
            { [styles["buttons--active"]]: checkoutData.wayToGet === WAY_TO_GET.toPoint }
          )}
        />
        <CustomButton
          title={WAY_TO_GET.toDoor}
          onClick={() => selectWayToGet(WAY_TO_GET.toDoor)}
          isBlack={checkoutData.wayToGet === WAY_TO_GET.toDoor}
          isWhite={checkoutData.wayToGet !== WAY_TO_GET.toDoor}
          className={cn(
            styles.buttons__delivery,
            { [styles["buttons--active"]]: checkoutData.wayToGet === WAY_TO_GET.toDoor }
          )}
        />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Куда доставить заказа?</h2>
        <h2 className={styles.subtitle}>Ваш адрес</h2>
        <div ref={autocompleteListRef} className={styles.autocomplete}>
          <input
            type="text"
            name="adress"
            placeholder="Ваш адрес"
            autoComplete="off"
            value={searchValue}
            onChange={handleChange}
            onFocus={onFocusHandler}
            className={styles.autocomplete__input}
          />
          {showAutocompleteList && deliveryAddresses?.length > 0 && (
            <div className={styles["autocomplete__list-wrapper"]}>
              <div className={styles.autocomplete__list}>
                {deliveryAddresses.map((item, idx) => {
                  return (
                    <button key={idx} onClick={() => selectAddress(item)}>
                      {item}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={styles["btn-wrapper"]}>
          <button
            onClick={setDeliveryAddressByPosition}
            className={styles["additional-button"]}
          >
            Определить моё местоположение
          </button>
        </div>
      </div>
      <div className={styles["provider-wrapper"]}>
        <h2 className={styles.title}>Кто доставит?</h2>
        <ProvidersTitlesList
          list={props.currentProviders}
          providersInfo={props.providersInfo}
          onSelectProvider={props.onSelectProvider}
          chosenProvider={props.chosenProvider}
        />
      </div>
      <CustomButton
        title="Подтвердить выбор"
        onClick={acceptAddress}
        className={styles["select-button"]}
      />
    </div>
  );
};

const propTypes = {
  currentProviders: PropTypes.array,
  providersInfo: PropTypes.object,
  isMapVisible: PropTypes.bool,
  onSelectProvider: PropTypes.func,
  onCloseModal: PropTypes.func,
  chosenProvider: PropTypes.object,
};

SelectAddressForOrder.propTypes = propTypes;

export default SelectAddressForOrder;
