import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getFiltersOrderedByQuery } from "../../../../utils/helpers/catalogPageHelper";
import useSearchParamsHandler from "../../../../utils/hooks/useSearchParamsHandler";

import { ReactComponent as CrossIcon } from "../../../../assets/icons/svg/cross-icon.svg";

import styles from "./styles.module.scss";

const SelectedFiltersContent = (props) => {
  const { deviceType } = useSelector((state) => state.user);
  const { searchParams } = useSearchParamsHandler();

  const formattedSelectedFilters = React.useMemo(() => {
    if (!props.selectedFilters || !Object.keys(props.selectedFilters).length) {
      return [];
    }

    const result = getFiltersOrderedByQuery({
      query: searchParams,
      filters: props.selectedFilters,
    });
    return result;
  }, [props.selectedFilters]);

  if (deviceType.isMobile) {
    return formattedSelectedFilters.map((item, idx) => {
      if (!item?.length) {
        return null;
      }

      return (
        <div key={`${item[0]}_${idx}`} className={styles["item__row-mobile"]}>
          <span className={styles.item__title}>{item[0].main_name}:</span>
          {item.map((el) => {
            return (
              <p key={el.rewrite_name} className={styles.item__value}>
                <span className={styles.value__title}>{el.name}</span>
                <button
                  className={styles.value__button}
                  onClick={() => props.removeSingleFilter(el)}
                >
                  <CrossIcon />
                </button>
              </p>
            );
          })}
        </div>
      );
    });
  }
  return formattedSelectedFilters.map((item, idx) => {
    return (
      <React.Fragment key={`${item[0]}_${idx}`}>
        {item[0]?.main_name && (
          <span className={styles.item__title}>{item[0].main_name}:</span>
        )}
        {item.length > 0 && (
          <div className={styles["item__rows-wrapper"]}>
            {item.map((el) => {
              return (
                <p key={el.rewrite_name} className={styles.item__value}>
                  <span className={styles.value__title}>{el.name}</span>
                  <button
                    className={styles.value__button}
                    onClick={() => props.removeSingleFilter(el)}
                  >
                    <CrossIcon />
                  </button>
                </p>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  });
};

const propTypes = {
  selectedFilters: PropTypes.object,
  removeSingleFilter: PropTypes.func,
};

SelectedFiltersContent.propTypes = propTypes;

export default SelectedFiltersContent;
