import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const INDICATOR_HEIGHT = 36;

const AnchorsBlock = (props) => {
  const [activeAnchorIndex, setActiveAnchor] = useState(0);

  const onClickHandler = (idx) => {
    const contentOffsetTop = props.anchors[idx].ref.current?.offsetTop;

    window.scrollTo({
      top: contentOffsetTop - props.anchors[idx].offsetTop,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    const anchorsPositions = props.anchors.map((item, idx, arr) => {
      if (idx === 0) {
        return [0, item.ref.current?.offsetTop];
      }
      return [arr[idx - 1].ref.current?.offsetTop, item.ref.current?.offsetTop];
    });
    const currentIndex = anchorsPositions.findIndex((item, idx) => {
      if (idx === anchorsPositions.length - 1) {
        return (item[0] <= position && item[1] >= position) || item[1] <= position;
      }
      return item[0] <= position && item[1] >= position;
    });
    setActiveAnchor(currentIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return(
    <div className={styles.anchors}>
      {
        props.anchors.map((item,idx) => {
          return(
            <button
              key={idx}
              onClick={() => onClickHandler(idx)}
              className={styles.anchors__button}
            >
              <span>{item.title}</span>
            </button>
          );
        })
      }
      <div
        style={{ transform: `translateY(${activeAnchorIndex * INDICATOR_HEIGHT}px)` }}
        className={styles.anchors__indicator}
      />
    </div>
  );
};

const propTypes = {
  anchors: PropTypes.array.isRequired,
};

AnchorsBlock.propTypes = propTypes;

export default AnchorsBlock;
