import { useState } from "react";
import PropTypes from "prop-types";

import { userApi } from "../../../../../../api/userApi";
import { ORDER_STATUSES } from "../../../../../../utils/constants";

import CustomButton from "../../../../../../components/CustomButton";

import styles from "./styles.module.scss";
import { toast } from "react-toastify";

const buttonsValues = [
  {
    id: 0,
    title: "Выполненные",
    statuses: [
      ORDER_STATUSES.received.value,
      ORDER_STATUSES["part_received"].value,
    ],
  },
  {
    id: 1,
    title: "Отменённые",
    statuses: [ORDER_STATUSES.canceled.value],
  },
  {
    id: 2,
    title: "Текущие",
    statuses: [
      ORDER_STATUSES.created.value,
      ORDER_STATUSES.delivered.value,
      ORDER_STATUSES.delivering.value,
      ORDER_STATUSES["wait_received"].value,
      ORDER_STATUSES.packed.value,
      ORDER_STATUSES.packing.value,
      ORDER_STATUSES.confirmed.value,
      ORDER_STATUSES.accepted.value,
    ],
  },
];

const OrdersButtons = (props) => {
  const [selectedOrderId, setSelectedOrderId] = useState(0);

  const onSelectOrder = async (id) => {
    if (id === selectedOrderId) {
      return;
    }

    setSelectedOrderId(id);
    props.onChangeStatusId(id);
    try {
      const options = {
        status: buttonsValues[id].statuses.join(),
      };

      const data = await userApi.getUserOrders(options);
      props.setUserOrders(data.response.items);
    } catch (err) {
      if (err) {
        toast.error("Ошибка загрузки заказов");
      }
    }
  };

  return (
    <div className={styles.root}>
      {buttonsValues.map((item) => {
        return (
          <CustomButton
            key={item.id}
            title={item.title}
            isBlack={selectedOrderId === item.id}
            isWhite={selectedOrderId !== item.id}
            className={styles.button}
            onClick={() => onSelectOrder(item.id)}
          />
        );
      })}
    </div>
  );
};

const propTypes = {
  setUserOrders: PropTypes.func.isRequired,
  onChangeStatusId: PropTypes.func.isRequired,
};

OrdersButtons.propTypes = propTypes;

export default OrdersButtons;
