import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import BigModal from "../../../../../components/UI/BigModal/BigModal";
import ProvidersListWithAddresses from "../ProvidersListWithAddresses/ProvidersListWithAddresses";
import cn from "classnames";
import styles from "./styles.module.scss";
import SelectedAddressFromBallonBlock from "../SelectedAddressFromBallonBlock/SelectedAddressFromBallonBlock";
import SelectAddressForOrder from "../MainSelectProviderBlock/MainSelectProviderBlock";

const SelectProviderModal = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [showModal, setShowModal] = useState("PVZList");

  const onCloseModalHandler = () => {
    if (!checkoutData.address) {
      props.onToggleShowProviderInfo(false);
    }
    props.onCloseModal(false);
  };

  useEffect(() => {
    if (props.showProviderInfo) setShowModal("ProviderInfo");
  }, [props.showProviderInfo]);

  useEffect(() => {
    if (checkoutData.wayToGet === "Курьером") setShowModal("ToDoor");
    if (checkoutData.wayToGet === "Самовывоз") setShowModal("PVZList");
  }, [checkoutData.wayToGet]);

  const closeProviderInfo = () => {
    setShowModal("PVZList");
    props.onToggleShowProviderInfo(false);
  };

  const closeModal = () => {
    closeProviderInfo();
    props.onCloseModal();
    setIsMapVisible(false);
  };

  return (
    <BigModal modalRef={props.modalRef} onClose={onCloseModalHandler}>
      <div className={styles.root}>
        <div className={styles.content}>
          {showModal === "PVZList" &&
            <ProvidersListWithAddresses
              selectedProvider={props.selectedProvider}
              setSelectedProvider={props.setSelectedProvider}
              providersInfo={props.providersInfo}
              changeSelectedProviderData={props.changeSelectedProviderData}
              onToggleShowProviderInfo={props.onToggleShowProviderInfo}
              setCity={props.setCity}
            />
          }
          {showModal === "ProviderInfo" &&
            <SelectedAddressFromBallonBlock
              providersInfo={props.providersInfo}
              selectedProviderData={props.selectedProviderData}
              onCloseModal={closeModal}
              returnToPvzSelect={closeProviderInfo}
            />
          }
          {showModal === "ToDoor" &&
            <SelectAddressForOrder
              currentProviders={props.currentProviders}
              providersInfo={props.providersInfo}
              onSelectProvider={props.onSelectProvider}
              isMapVisible={isMapVisible}
              onCloseModal={closeModal}
              chosenProvider={props.chosenProvider}
            />
          }
          <div
            id="map"
            className={cn(styles.map, { [styles["map--hidden"]]: !isMapVisible })}
          />
        </div>
      </div>
    </BigModal >
  );
};

const propTypes = {
  modalRef: PropTypes.object,
  selectedProviderData: PropTypes.object,
  showProviderInfo: PropTypes.bool,
  providersInfo: PropTypes.object,
  selectedProvider: PropTypes.object,
  currentProviders: PropTypes.array,
  onCloseModal: PropTypes.func,
  onToggleShowProviderInfo: PropTypes.func,
  onSelectProvider: PropTypes.func,
  changeSelectedProviderData: PropTypes.func,
  setCity: PropTypes.func,
  setSelectedProvider: PropTypes.func,
  chosenProvider: PropTypes.object,
};

SelectProviderModal.propTypes = propTypes;

export default SelectProviderModal;
