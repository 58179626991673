import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { personalAreaPaths } from "../../utils/constants/routes";

import CustomButton from "../CustomButton/CustomButton";
import userAvatar from "../../assets/icons/png/user_avatar.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const PersonalAreaNavigationContent = (props) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();

  return (
    <div className={cn(styles.root, props.containerStyles)}>
      <img src={userAvatar} alt="user_avatar" className={styles.avatar} />
      <span className={styles["user-data"]}>
        <span>{!user.surname ? "" : `${user.surname} `}</span>
        <span>{!user.name ? "" : user.name}</span>
        <span>{!user.patronymic ? "" : ` ${user.patronymic}`}</span>
      </span>
      <div className={styles["links-wrapper"]}>
        <Link
          to="/personal-area/profile"
          className={cn(styles.link, {
            [styles["link--active"]]:
              personalAreaPaths[0] === location.pathname,
          })}
          onClick={props.onClose}
        >
          Профиль
        </Link>
        <Link
          to="/personal-area/orders"
          className={cn(styles.link, {
            [styles["link--active"]]:
              personalAreaPaths[1] === location.pathname,
          })}
          onClick={props.onClose}
        >
          Заказы
        </Link>
        <Link
          to="/personal-area/products"
          className={cn(styles.link, {
            [styles["link--active"]]:
              personalAreaPaths[2] === location.pathname,
          })}
          onClick={props.onClose}
        >
          Товары
        </Link>
        <Link
          to="/personal-area/settings"
          className={cn(styles.link, {
            [styles["link--active"]]:
              personalAreaPaths[3] === location.pathname,
          })}
          onClick={props.onClose}
        >
          Настройки
        </Link>
      </div>
      <CustomButton
        title="Выйти из аккаунта"
        isWhite
        onClick={props.onContinueExit}
        className={styles.button}
      />
    </div>
  );
};

const propTypes = {
  containerStyles: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onContinueExit: PropTypes.func.isRequired,
};

PersonalAreaNavigationContent.propTypes = propTypes;

export default PersonalAreaNavigationContent;
