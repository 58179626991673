export const getMinProductPrice = (offers) => {
  const initValue = offers[0]?.price_sale || offers[0]?.akcia || offers[0]?.price;

  const minPrice = offers.reduce((acc, cur) => {
    if (cur.price_sale) {
      if (acc > cur.price_sale) {
        acc = cur.price_sale;
      }
      return acc;
    }
    if (cur.akcia) {
      if (acc > cur.akcia) {
        acc = cur.akcia;
      }
      return acc;
    }

    if (acc > cur.price) {
      acc = cur.price;
    }
    return acc;
  }, initValue);

  return minPrice;
};

export const getMaxProductPrice = (offers) => {
  const initValue = offers[0]?.price_sale || offers[0]?.akcia || offers[0]?.price;

  const minPrice = offers.reduce((acc, cur) => {
    if (cur.price_sale) {
      if (acc < cur.price_sale) {
        acc = cur.price_sale;
      }
      return acc;
    }
    if (cur.akcia) {
      if (acc < cur.akcia) {
        acc = cur.akcia;
      }
      return acc;
    }

    if (acc < cur.price) {
      acc = cur.price;
    }
    return acc;
  }, initValue);

  return minPrice;
};

export const getProductPrices = (offers) => {
  if (!offers?.length) {
    return {
      min: "",
      max: "",
    };
  }

  if (offers?.length === 1) {
    return {
      min: getMinProductPrice(offers),
      max: "",
    };
  }

  return {
    min: getMinProductPrice(offers),
    max: getMaxProductPrice(offers),
  };
};