import PropTypes from "prop-types";

import SelectedFiltersContent from "../SelectedFiltersContent";

import styles from "./styles.module.scss";

const SelectedFilters = (props) => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>Выбранные фильтры</p>
      <div className={styles["filters-list"]}>
        <SelectedFiltersContent
          selectedFilters={props.selectedFilters}
          removeSingleFilter={props.removeSingleFilter}
        />
      </div>
    </div>
  );
};

const propTypes = {
  selectedFilters: PropTypes.object.isRequired,
  removeSingleFilter: PropTypes.func.isRequired
};

SelectedFilters.propTypes = propTypes;

export default SelectedFilters;
