import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../../../api/userApi";
import { setTokens } from "../../../utils/localStorage";

export const getUserCart = createAsyncThunk(
  "cart/getUserCart",
  async (options, { rejectWithValue }) => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      const storageValue = localStorage.getItem("cart") || "[]";
      const optionsCart = options.addProduct ? options.offers : [...JSON.parse(storageValue)];
      const filteredValue = optionsCart.filter(e => e.count > 0);

      if (!filteredValue[0]) {
        localStorage.setItem("cart", "[]");
        return [];
      }

      try {
        const data = await userApi.addProductToCartPublic(filteredValue);
        if (data.meta.error) {
          throw new Error(data.meta.error);
        }

        const setLocalStorage = JSON.stringify(filteredValue);
        localStorage.setItem("cart", setLocalStorage);
        return data.response;
      } catch (err) {
        return rejectWithValue({ message: err.message });
      }
    }

    try {
      const data = await userApi.getUserCart(options);

      if (data.meta.error) {
        throw new Error(data.meta.error);
      }
      return data.response;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  }
);

export const addProductToCart = createAsyncThunk(
  "cart/addProductToCart",
  async (options, { rejectWithValue, getState, dispatch }) => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      return addProductToCartPublic(options, getState, dispatch);
    }

    try {
      const data = await userApi.addProductToCart(options);

      if (data?.meta?.error || data?.code) {
        throw new Error(data?.meta?.error || data?.code);
      }

      const selectedProductIds = data.response.items.reduce((acc, cur) => {
        acc.push(cur.offer.id);
        return acc;
      }, []);

      const optionsCalculate = {
        "calculate_orders": selectedProductIds.join(","),
        "use_promo_code": getState().user.checkoutData.promoCode.trim(),
      };

      dispatch(getUserCart(optionsCalculate));
      return;
    } catch (err) {

      if (err?.message === "401") {
        const refreshData = await userApi.refreshUser();

        if (refreshData && typeof refreshData?.response === "object") {
          setTokens({
            token: refreshData.response.token,
            refreshToken: refreshData.response.refresh,
          });

          dispatch(addProductToCart(options));
        }
      }
      return rejectWithValue({ message: err.message });
    }
  }
);

const addProductToCartPublic = async (options, getState, dispatch) => {
  let state = getState().user.cart.map((e) => {
    return { id: e.offer.id, count: e.count };
  });

  options.map((e) => {
    const isFind = state.find((el) => el.id === e.id);
    if (isFind) {
      return state = state.map((el) => {
        if (isFind.id === el.id) return e;
        return el;
      });
    }
    return state.push(e);
  });

  const optionData = { addProduct: true, offers: state };
  dispatch(getUserCart(optionData));
};