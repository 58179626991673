import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { addProductToCart } from "../../../../../../../../store/user/cartThunks/cartThunks";

import styles from "./styles.module.scss";

const SelectProductsAmountButtons = (props) => {
  const dispatch = useDispatch();

  const addProductAmount = () => {
    try {
      if (!props.isAvailable) {
        return;
      }
      const options = {
        id: props.offerId,
        count: props.amount + 1,
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // TODO
    }
  };

  const removeProductAmount = () => {
    try {
      if (props.amount === 1 || !props.isAvailable) {
        return;
      }
      const options = {
        id: props.offerId,
        count: props.amount - 1,
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // TODO
    }
  };

  return (
    <div className={styles["amount-wrapper"]}>
      <button
        className={styles.amount__button}
        onClick={removeProductAmount}
      >
        -
      </button>
      <span>{props.amount}</span>
      <button
        className={styles.amount__button}
        onClick={addProductAmount}
      >
        +
      </button>
    </div>
  );
};

const propTypes = {
  amount: PropTypes.number.isRequired,
  offerId: PropTypes.number.isRequired,
  isAvailable: PropTypes.bool.isRequired,
};

SelectProductsAmountButtons.propTypes = propTypes;

export default SelectProductsAmountButtons;
