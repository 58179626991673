import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";
import TelephoneIcon from "../../../assets/header_icons/phone-icon.svg";

import styles from "./styles.module.scss";
import cn from "classnames";

const Contacts = () => {

  const contactsData = pageInfo.contacts;

  return (
    <InfoPage metaData={contactsData}>
      <h1 className={styles.seo}>Инфо страница Контакты</h1>
      <SectionTitle title="Контакты" containerStyles={styles["custom-title"]} />
      <div className={cn(styles.root, "vcard")}>
        <span className={cn(styles.category, "category")}>Интернет магазин</span>
        <span className={cn(styles.organization, "fn org")}>Parfumart</span>
        <h3 className={styles.title}>Фактический адрес:</h3>
        <p className={cn(styles.text, "adr", styles.adress)}>
          <span className="locality">г. Краснодар</span>
          <span>,&nbsp;</span>
          <span className="street-address">
            ул. Меланжевая, 10.
            Деловой центр AVM-ORSETTO, 1 этаж, офис 102.
          </span>
        </p>
        <h3 className={styles.title}>
          <img
            src={TelephoneIcon}
            alt="telephone_icon"
          />
          Телефоны:</h3>
        <p className={styles.text}>
          <span className={cn(styles.phone_number, "tel")}>8 (861) 290-21-10</span>
          <span className={cn(styles.phone_number, "tel")}>8 (918) 977-21-10</span>
        </p>
        <h3 className={styles.title}>Электронная почта:</h3>
        <div className={styles.text}>
          <div>
            <span>
              Служба поддержки клиентов:
            </span>
            <a href="mailto:client@parfumart.ru" className={cn(styles.link, "email")} >
              client@parfumart.ru
            </a>
          </div>
          <div>
            <span>
              Отдел закупок:
            </span>
            <a href="mailto:sup@parfumart.ru" className={styles.link}>
              sup@parfumart.ru
            </a>
          </div>
          <div>
            <span>
              Написать письмо руководству интернет-магазина:
            </span>
            <a href="mailto:ceo@parfumart.ru" className={styles.link}>
              ceo@parfumart.ru
            </a>
          </div>
        </div>
        <h3 className={styles.title}>Наши реквизиты:</h3>
        <p className={styles.text}>
          <span>
            ИНН : 231294849270
          </span>
          <span>
            ОГРНИП: 312231208000021
          </span>
          <span>
            ОКВЭД: 52.61.2
          </span>
        </p>
        <a
          target="_blank"
          href="https://yandex.ru/maps/org/parfyumart/49927291653/?from=tabbar&ll=39.047002%2C45.032658&source=serp_navig&z=16"
          rel="noreferrer"
          className={styles["route-link"]}
        >
          Построить маршрут
        </a>
      </div>
    </InfoPage>
  );
};

export default Contacts;