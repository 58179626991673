import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import styles from "./styles.module.scss";

const INIT_THUMB_HEIGHT = 2;

const CustomScrollbar = (props) => {
  const contentRef = useRef(null);
  const scrollTrackRef = useRef(null);
  const scrollThumbRef = useRef(null);
  const observer = useRef(null);
  const [thumbHeight, setThumbHeight] = useState(INIT_THUMB_HEIGHT);

  const [scrollStartPosition, setScrollStartPosition] = useState(null);
  const [initialScrollTop, setInitialScrollTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  function handleResize (ref, trackSize) {
    const { clientHeight, scrollHeight } = ref;
    if (!clientHeight && !scrollHeight) {
      return;
    }

    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, INIT_THUMB_HEIGHT));
  }

  const handleTrackClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const { current: trackCurrent } = scrollTrackRef;
    const { current: contentCurrent } = contentRef;

    if (!trackCurrent && !contentCurrent) {
      return;
    }

    const { clientY } = e;
    const target = e.target;
    const rect = target.getBoundingClientRect();
    const trackTop = rect.top;
    const thumbOffset = -(thumbHeight / 2);
    const clickRatio =
        (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
    const scrollAmount = Math.floor(
      clickRatio * contentCurrent.scrollHeight
    );

    contentCurrent.scrollTo({
      top: scrollAmount,
      behavior: "smooth",
    });

  }, [props.children.length]);

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight, clientHeight } =
      contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    const currentThumbHeight = Math.max((clientHeight / contentHeight) * trackHeight, INIT_THUMB_HEIGHT);
    const currentTrackHeight = currentThumbHeight === INIT_THUMB_HEIGHT
      ? trackHeight - INIT_THUMB_HEIGHT + 1
      : trackHeight;
    const newTop = (+contentTop / +contentHeight) * currentTrackHeight;

    props.onShowScrollBarShadows?.({
      showTopShadow: newTop > 0,
      showBottomShadow: +newTop.toFixed(1) < +(currentTrackHeight - currentThumbHeight).toFixed(1),
    });
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop > 0 ? newTop : 0}px`;
  }, [props.children.length]);

  const handleThumbMousedown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setScrollStartPosition(e.clientY);

    if (contentRef.current) {
      setInitialScrollTop(contentRef.current.scrollTop);
    }

    setIsDragging(true);
  }, [props.children.length]);

  const handleThumbMouseup = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging, props.children.length]);

  const handleThumbMousemove = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isDragging) {
      return;
    }

    const {
      scrollHeight: contentScrollHeight,
      offsetHeight: contentOffsetHeight,
    } = contentRef.current;

    const deltaY = (e.clientY - scrollStartPosition) *
          (contentOffsetHeight / thumbHeight);
    const newScrollTop = Math.min(
      initialScrollTop + deltaY,
      contentScrollHeight - contentOffsetHeight
    );

    contentRef.current.scrollTop = newScrollTop;

  }, [isDragging, scrollStartPosition, thumbHeight]);

  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener("scroll", handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener("scroll", handleThumbPosition);
      };
    }
  }, [props.children.length, props.rerenderTrigger, props.isShowScrollbar]);

  useEffect(() => {
    if (!contentRef.current && !scrollTrackRef.current) {
      return;
    }
    const ref = contentRef.current;

    ref.addEventListener("scroll", handleThumbPosition);
    return () => {
      ref.removeEventListener("scroll", handleThumbPosition);
    };

  }, [props.children.length, props.rerenderTrigger, props.isShowScrollbar]);

  useEffect(() => {
    document.addEventListener("mousemove", handleThumbMousemove);
    document.addEventListener("mouseup", handleThumbMouseup);
    document.addEventListener("mouseleave", handleThumbMouseup);
    return () => {
      document.removeEventListener("mousemove", handleThumbMousemove);
      document.removeEventListener("mouseup", handleThumbMouseup);
      document.removeEventListener("mouseleave", handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  return (
    <div className={cn(styles.root, props.scrollbarClassname)} ref={props.scrollbarRef}>
      <div
        className={cn(styles.content, props.childrenClassName)}
        ref={contentRef}
        onScroll={props.onScrollHandler}
      >
        {props.children}
      </div>
      <div className={cn(styles.scrollbar, {[styles["scrollbar--show"]]: props.isShowScrollbar})}>
        <div className={cn(styles.scrollbar__content, props.scrollBarTrackStyles)}>
          <div
            className={styles.scrollbar__content_track}
            style={{ cursor: isDragging && "grabbing" }}
            ref={scrollTrackRef}
            onClick={handleTrackClick}
          />
          <div
            className={styles.scrollbar__content_thumb}
            style={{
              height: `${thumbHeight}px`,
              cursor: isDragging ? "grabbing" : "grab",
              transition: "top 0.1s linear"
            }}
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
          />
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  children: PropTypes.node,
  scrollbarClassname: PropTypes.string,
  childrenClassName: PropTypes.string,
  isShowScrollbar: PropTypes.bool.isRequired,
  scrollBarTrackStyles: PropTypes.string,
  onShowScrollBarShadows: PropTypes.func,
  rerenderTrigger: PropTypes.any,
  scrollbarRef: PropTypes.object,
  onScrollHandler: PropTypes.func,
};

CustomScrollbar.propTypes = propTypes;

export default CustomScrollbar;