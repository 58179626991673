import PropTypes from "prop-types";

import chechMarkIcon from "../../../../../../../assets/icons/png/chech_mark-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";

const FilterButtonItem = (props) => {
  return (
    <li className={styles.root}>
      <button
        className={cn(styles.button, { [styles.disabled]: props.item.isDisabled })}
        onClick={() => props.onClickSelectFilter(props.item)}
        disabled={props.item.isDisabled}
      >
        <div className={styles.checkbox}>
          {props.item.isSelected && <img src={chechMarkIcon} alt="chech_mark-icon" />}
        </div>
        <span className={styles.title}>{props.item.name}</span>
      </button>
    </li>
  );
};

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    "rewrite_name": PropTypes.string,
    "main_rewrite_name": PropTypes.string,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }).isRequired,
  onClickSelectFilter: PropTypes.func.isRequired,
};

FilterButtonItem.propTypes = propTypes;

export default FilterButtonItem;