import { useMemo } from "react";
import PropTypes from "prop-types";

import { ORDER_STATUSES } from "../../../../../../utils/constants";

import styles from "./styles.module.scss";

const OrderStatus = (props) => {
  const currentDate = useMemo(() => {
    const date = new Date(props.date);
    return date.toLocaleString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric" });
  }, [props.date]);

  return(
    <div className={styles.root}>
      <div className={styles["order-data"]}>
        <span>
          Заказ №
          <span className={styles["order-data__number"]}>
            {` ${props.orderId}`}
          </span>
        </span>
        <span>{`От: ${currentDate}`}</span>
      </div>
      <span className={styles["order-status"]}>
        Статус заказа:
        <span className={styles["order-status__status"]}>
          {` ${ORDER_STATUSES[props.status].title}`}
        </span>
      </span>
    </div>
  );
};

const propTypes = {
  orderId: PropTypes.number,
  date: PropTypes.string,
  status: PropTypes.string,
};

OrderStatus.propTypes = propTypes;

export default OrderStatus;