import { useState } from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../../../components/CustomButton";

import styles from "./styles.module.scss";

const NotificationItem = (props) => {
  const [inputText, setInputText] = useState("");

  const onChange = (e) => {
    setInputText(e.target.value);
  };

  return (
    <form className={styles.form}>
      <h3 className={styles.form__title}>
        {props.title}
      </h3>
      <input
        type="email"
        value={inputText}
        placeholder="Введите e-mail"
        onChange={onChange}
        className={styles.form__input}
      />
      <CustomButton
        title="Отправить"
        onClick={() => {}}
        type="submit"
        className={styles.form__button}
      />
    </form>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
};

NotificationItem.propTypes = propTypes;

export default NotificationItem;
