import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./styles.module.scss";

const OrderStringItem = (props) => {
  return(
    <p className={cn(styles.root, props.contanerClassname)}>
      <span className={cn(styles.title, props.titleClassname)}>
        {props.title}
      </span>
      <span className={cn(styles.subtitle, props.subtitleClassname)}>
        {props.subtitle}
      </span>
    </p>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contanerClassname: PropTypes.string,
  titleClassname: PropTypes.string,
  subtitleClassname: PropTypes.string,
};

OrderStringItem.propTypes = propTypes;

export default OrderStringItem;