import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import useOutsideClick from "../utils/hooks/useOutsideClick";

const Portal = (Component) => (props) => {
  const scrollPosition = useRef(null);

  useOutsideClick({
    ref: props.modalRef,
    cb: props.onClose,
  });

  useEffect(() => {
    scrollPosition.current = window.scrollY;
    const body = document.querySelector("body");

    body.style.overflowY = "scroll";
    body.style.position = "fixed";
    body.style.top = `-${scrollPosition.current}px`;
    body.style.width = "100%";

    return () => {
      body.style.overflow = "";
      body.style.position = "";
      body.style.top = "";
      body.style.width = "";

      window.scrollTo(0, scrollPosition.current);
    };
  }, []);

  return createPortal(
    <Component {...props} />,
    document.body
  );
};

export default Portal;