/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  removeDeliveryAdress,
  updateUserData,
} from "../../../../store/user/userThunks/userThunks";
import { personalDataSchema } from "../../../../schemas/personalDataSchema";

import AdressBlock from "./AdressBlock";
import MainUserInfo from "./MainUserInfo";
import AnchorsBlock from "../components/AnchorsBlock/AnchorsBlock";

import styles from "./styles.module.scss";

const PersonalData = () => {
  const { user, deviceType } = useSelector((state) => state.user);

  const [localUserAdress, setLocalUserAdress] = useState(
    !user?.accountDeliveryAddresses.length
      ? [{ emptyId: Math.random() }]
      : user?.accountDeliveryAddresses
  );
  const [gender, setGender] = useState(user.gender || "");
  const [showUserDataNotification, setShowUserDataNotification] =
    useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const mainUserInfoRef = useRef(null);
  const adressBlockRef = useRef(null);

  const dispatch = useDispatch();

  const userPhoneNumber = useMemo(() => {
    if (!user.phone) {
      return "";
    }
    const phoneFirstPartNumbers = `+${user.phone[0]}(${user.phone.slice(
      1,
      4
    )})${user.phone.slice(4, 7)}`;
    const phoneLastPartNumbers = `${user.phone.slice(7, 9)}-${user.phone.slice(
      9,
      11
    )}`;
    return `${phoneFirstPartNumbers}-${phoneLastPartNumbers}`;
  }, [user.phone]);

  const anchors = useMemo(() => {
    return [
      {
        id: 0,
        title: "Основная информация",
        ref: mainUserInfoRef,
        offsetTop: 200,
      },
      {
        id: 1,
        title: "Адреса доставки",
        ref: adressBlockRef,
        offsetTop: 30,
      },
    ];
  }, []);

  const formik = useFormik({
    initialValues: {
      surname: user.surname || "",
      name: user.name || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || "",
    },
    validationSchema: personalDataSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const registeredUserData = await dispatch(
          updateUserData({
            surname:
              user.surname !== formik.values.surname.trim()
                ? formik.values.surname.trim()
                : undefined,
            name:
              user.name !== formik.values.name.trim()
                ? formik.values.name.trim()
                : undefined,
            phone:
              userPhoneNumber !== formik.values.phone
                ? formik.values.phone.replace(/[^\d]/g, "")
                : undefined,
            email:
              user.email !== formik.values.email.trim()
                ? formik.values.email.trim()
                : undefined,
            gender: !user?.gender ? gender : undefined,
            birthday: !user.birthday ? formik.values.dob : undefined,
          })
        ).unwrap();

        if (!registeredUserData) {
          return;
        }

        setShowUserDataNotification(true);

        setTimeout(() => {
          setShowUserDataNotification(false);
        }, 2000);
      } catch (err) {
        return toast(err.message);
      }
    },
  });

  useEffect(() => {
    const initialValues = {
      surname: user.surname || "",
      name: user.name || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || "",
    };

    const checkFormChanges = () => {
      const currentValues = {
        surname: formik.values.surname,
        name: formik.values.name,
        phone: formik.values.phone,
        email: formik.values.email,
        dob: formik.values.dob,
      };

      setIsFormChanged(JSON.stringify(initialValues) !== JSON.stringify(currentValues));
    };

    checkFormChanges();
  }, [formik.values, user, userPhoneNumber]);

  const onClickSelectGender = (gender) => {
    if (!user?.gender) {
      setGender(gender);
    }
  };

  const onClickEmptyAdressAction = (type, idx) => {
    if (type === "inc") {
      setLocalUserAdress((prev) => [...prev, { emptyId: Math.random() }]);
      return;
    }

    const currentAdress = localUserAdress[idx];

    if ("id" in currentAdress) {
      dispatch(removeDeliveryAdress(currentAdress.id));
      if (Object.entries(localUserAdress).length === 1) {
        setLocalUserAdress([{ emptyId: currentAdress.id }]);
      } else {
        setLocalUserAdress((prev) => prev.filter((_, index) => index !== idx));
      }
    } else {
      if (Object.entries(localUserAdress).length === 1) {
        return;
      }
      setLocalUserAdress((prev) => prev.filter((_, index) => index !== idx));
    }
  };

  const setUserAdress = (data) => {
    setLocalUserAdress(data);
  };

  const onSubmitHandler = () => {
    if (!isFormChanged) {
      toast("Измените данные для сохранения.");
      return;
    }
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        Object.values(errors).forEach((error) => {
          toast(error);
        });
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <section className={styles.root}>
      <div className={styles["user-info"]}>
        <MainUserInfo
          mainUserInfoRef={mainUserInfoRef}
          formik={formik}
          gender={gender}
          disableGenderSelect={!!user?.gender}
          disabledDobSelect={!!user?.birthday}
          showUserDataNotification={showUserDataNotification}
          onClickSelectGender={onClickSelectGender}
          onSubmitHandler={onSubmitHandler}
          isFormChanged={isFormChanged}
        />
        <div className={styles.adress} ref={adressBlockRef}>
          {localUserAdress.map((item, idx) => {
            return (
              <AdressBlock
                key={item.id ?? item.emptyId}
                index={idx}
                adressAmount={localUserAdress?.length - 1}
                adressData={item}
                onClickEmptyAdressAction={onClickEmptyAdressAction}
                setUserAdress={setUserAdress}
              />
            );
          })}
        </div>
      </div>
      {!deviceType.isMobile && <AnchorsBlock anchors={anchors} />}
    </section>
  );
};

export default PersonalData;
