import PropTypes from "prop-types";

import NotificationItem from "./NotificationItem";

import styles from "./styles.module.scss";

const NOTIFICATIONS = [
  {
    id: 0,
    title: "Подписка на снижение цены"
  },
  {
    id: 1,
    title: "Подписка на рассылку"
  }
];

const Notifications = (props) => {
  return(
    <div
      ref={props.notificationRef}
      className={styles.root}
    >
      {NOTIFICATIONS.map((item) => {
        return(
          <NotificationItem
            key={item.id}
            title={item.title}
          />
        );
      })}
    </div>
  );
};

const propTypes = {
  notificationRef: PropTypes.object.isRequired,
};

Notifications.propTypes = propTypes;

export default Notifications;