import React from "react";
import { useSelector } from "react-redux";

import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";

const useCatalogPagination = (options) => {
  const catalog = useSelector((state) => state.catalog);

  const [limit, setLimit] = React.useState(
    options.initLimit
  );
  const [offset, setOffset] = React.useState(options.initOffset);
  const [currentPage, setCurrentPage] = React.useState(offset / limit + 1 || 1);

  const { searchParams } = useSearchParamsHandler();

  React.useEffect(() => {
    const offsetFromQuery = searchParams.get("offset");
    if (!offsetFromQuery) {
      onResetCurrentPageAndOffset();
    }
  }, [catalog.searchTrigger]);

  const onChangeLimit = React.useCallback((limit) => {
    setLimit(limit);
  }, []);

  const onChangeOffset = React.useCallback((offset) => {
    setOffset(offset);
  }, []);

  const onChangeCurrentPage = React.useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const onResetCurrentPageAndOffset = React.useCallback(() => {
    onChangeOffset(0);
    onChangeCurrentPage(1);
  }, []);

  return {
    limit,
    offset,
    currentPage,
    onChangeLimit,
    onChangeOffset,
    onChangeCurrentPage,
    onResetCurrentPageAndOffset
  };
};

export default useCatalogPagination;