import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { passwordSchema } from "../../../../schemas/passwordSchema";
import { restoreUserPassword } from "../../../../store/user/userThunks/userThunks";
import { toast } from "react-toastify";

import CustomAuthInput from "../../../../components/CustomAuthInput";
import CustomButton from "../../../../components/CustomButton";

import styles from "./styles.module.scss";

const numberRegExp = /\d+/g;
const emptyStringRegExp = /^\s+$/;

const CODE_INPUTS = [
  {
    id: 0,
    name: "codeFirstValue",
  },
  {
    id: 1,
    name: "codeSecondValue",
  },
  {
    id: 2,
    name: "codeThirdValue",
  },
  {
    id: 3,
    name: "codeFourthValue",
  },
];

const RestoreByEmail = () => {
  const [showCodeInputs, setShowCodeInputs] = useState(false);
  const [showErrorsOnSubmit, setShowErrorsOnSubmit] = useState(false);
  const [errorFromRequest, setErrorFromRequest] = useState(null);

  const inputRefs = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      codeFirstValue: "",
      codeSecondValue: "",
      codeThirdValue: "",
      codeFourthValue: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      id: yup.string(),
      email: yup.string().email("Неправильный формат e-mail").required("E-mail обязательное поле"),
      codeFirstValue: yup.string().matches(numberRegExp),
      codeSecondValue: yup.string().matches(numberRegExp),
      codeThirdValue: yup.string().matches(numberRegExp),
      codeFourthValue: yup.string().matches(numberRegExp),
      password: passwordSchema(),
    }),
    onSubmit: async () => {
      try {
        const restoreData = {
          method: "email",
          contact: formik.values.email,
          code: `${formik.values.codeFirstValue}${formik.values.codeSecondValue}${formik.values.codeThirdValue}${formik.values.codeFourthValue}`,
          "new_password": formik.values.password,
        };

        const restoreResponse = await dispatch(
          restoreUserPassword(restoreData)
        ).unwrap();

        setShowCodeInputs(true);
        toast(restoreResponse.response);

        const successMessage = "Пароль успешно изменен";

        if (restoreResponse.response === successMessage) {
          navigate("/sign-in", { replace: true });
        }
      } catch (err) {
        if (err.message) {
          return setErrorFromRequest(err.message);
        } else {
          const errorMessage = "Произошла ошибка, попробуйте еще раз";
          return setErrorFromRequest(errorMessage);
        }
      }
    },
  });

  const onChangeCodeHandler = (e, type) => {
    const { value } = e.target;
    const isValidValue = formik.values[type].length > 0 && !value.length
      || (!isNaN(+value) && !emptyStringRegExp.test(value));

    if (isValidValue) {
      const index = +e.target.dataset.index;
      const value = e.target.value;

      if (index < CODE_INPUTS.length - 1 && value) {
        inputRefs[index + 1].focus();
        inputRefs[index + 1].select();
      }
      setErrorFromRequest(null);
      formik.handleChange(e);
    }
  };

  const onChangeHandler = (e) => {
    setErrorFromRequest(null);
    formik.handleChange(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setShowErrorsOnSubmit(true);
    formik.handleSubmit();
  };

  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Восстановление пароля</h2>
      <form
        method="post"
        onSubmit={onSubmitHandler}
        className={styles.form}
      >
        {!showCodeInputs ? (
          <CustomAuthInput
            name="email"
            inputType="email"
            placeholder="E-mail"
            value={formik.values.email}
            onChange={onChangeHandler}
            errorValue={formik.errors.email || errorFromRequest}
            showErrorsOnSubmit={showErrorsOnSubmit}
            className={styles.input}
            showErrorIfErrorInResponse={!!errorFromRequest}
          />
        ) : (
          <>
            <div className={styles["code-content"]}>
              <div className={styles["code-content__inputs-wrapper"]}>
                {CODE_INPUTS.map((item) => {
                  return (
                    <input
                      key={item.id}
                      ref={(input) => (inputRefs[item.id] = input)}
                      name={item.name}
                      type="text"
                      data-index={item.id}
                      value={formik.values[item.name]}
                      onChange={(e) => onChangeCodeHandler(e, item.name)}
                      className={styles["code-content__input"]}
                      maxLength={1}
                      autoComplete="off"
                      autoFocus={item.id === 0}
                    />
                  );
                })}
              </div>
              {!!errorFromRequest && (
                <span className={styles["error-message"]}>
                  {errorFromRequest}
                </span>
              )}
            </div>
            <CustomAuthInput
              name="password"
              inputType="password"
              placeholder="Новый пароль"
              value={formik.values.password}
              onChange={onChangeHandler}
              errorValue={formik.errors.password}
              showErrorsOnSubmit={showErrorsOnSubmit}
              className={styles.password}
            />
          </>
        )}
        <CustomButton
          title={showCodeInputs ? "Отправить" : "Отправить код"}
          className={styles.button}
          type="submit"
          onClick={onSubmitHandler}
        />
      </form>
      <div className={styles["links-wrapper"]}>
        <Link to="/sign-in" replace className={styles.link}>
          {"< Шаг назад"}
        </Link>
        <Link to="/sign-in/restore/by-phone" replace className={styles.link}>
        Другой способ
        </Link>
      </div>
    </main>
  );
};

export default RestoreByEmail;
