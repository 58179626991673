import { useFormik } from "formik";

import phoneNumberMask from "../../../../../../../utils/inputMasks/phoneNumberMask";
import { USER_INFO_FIELDS } from "../../../../../../../utils/constants/formItems";
import { userContactsSchema } from "../../../../../../../schemas/userContactsSchema";

import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import CustomButton from "../../../../../../../components/CustomButton/CustomButton";
import { setRecipient } from "../../../../../../../store/user/user";
import { useDispatch } from "react-redux";

const UserContantsFrom = ({ showForm, setShowForm, setAnotherPerson, anotherPerson }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      surname: anotherPerson.surname,
      name: anotherPerson.name,
      phone: anotherPerson.phone,
      email: anotherPerson.email,
    },
    validationSchema: userContactsSchema,
    onSubmit: async (data) => {
      try {
        dispatch(setRecipient(data));
        setAnotherPerson(data);
        setShowForm(false);
      } catch (err) {
        // TODO
      }
    },
  });

  const onChangeHandler = (e, inputType) => {
    if (inputType !== "tel") {
      const fieldName = e.target.name;
      if (fieldName === "name" || fieldName === "surname") {
        // when user types her/his name with non-capital latter we make it capital
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      }
      formik.handleChange(e);
    } else {
      phoneNumberMask(e);
      formik.handleChange(e);
    }
  };

  const submitHandler = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <form className={styles.root} onSubmit={submitHandler}>
        <h4 className={styles.title}>Введите данные получателя</h4>
        {USER_INFO_FIELDS.map((item) => {
          return (
            <div key={item.id} className={styles.row}>
              <input
                name={item.name}
                type={item.inputType}
                value={formik.values[item.name]}
                onChange={(e) => onChangeHandler(e, item.inputType)}
                className={styles.row__input}
                placeholder={item.title}
              />
              {item.name !== "email" && (
                <span className={styles.row__required}>*</span>
              )}
              {!!formik.errors[`${item.name}`] && (
                <span className={styles["row__error-message"]}>
                  {formik.errors[`${item.name}`]}
                </span>
              )}
            </div>
          );
        })}
      </form>
      {showForm &&
        <CustomButton
          className={styles["button"]}
          title="Сохранить"
          onClick={submitHandler}
          type="submit"
        />
      }
    </>
  );
};

UserContantsFrom.propTypes = {
  showForm: PropTypes.bool.isRequired,
  setShowForm: PropTypes.func.isRequired,
  setAnotherPerson: PropTypes.func.isRequired,
  anotherPerson: PropTypes.object.isRequired,
};

export default UserContantsFrom;