import PropTypes from "prop-types";
import ProviderItem from "../ProviderTitleItem/ProviderItem";

import styles from "./styles.module.scss";

const ProviderTitleItem = (props) => {
  return (
    <ul className={styles.root}>
      {props.list.map((item, i) => {
        return(
          <ProviderItem
            key={`${item.type}_${i}`}
            item={item}
            providersInfo={props.providersInfo}
            onSelectProvider={props.onSelectProvider}
            chosenProvider={props.chosenProvider}
          />
        );
      })}
    </ul>
  );
};

const propTypes = {
  list: PropTypes.array,
  providersInfo: PropTypes.object,
  onSelectProvider: PropTypes.func,
  chosenProvider: PropTypes.object,
};

ProviderTitleItem.propTypes = propTypes;

export default ProviderTitleItem;
