import styles from "./styles.module.scss";

const BankCardHalvaPayment = () => {
  return (
    <>
      <h3 className={styles.title}>Оплата заказа банковской картой рассрочки «Халва»</h3>
      <span className={styles.extra_info}>
          * Данная услуга временно недоступна
      </span>
      {/* <ol className={styles.list}>
        <li className={styles.list__item}>
          <div className={styles.list__item__title}>
            <span>
            </span>
            <span>
            </span>
          </div>
          <p className={styles.list__item__text}>
          </p>
          <span className={styles.list__item__text_postscript}>
            * Данная услуга временно недоступна
          </span>
        </li>
        <li className={styles.list__item}>
          <div className={styles.list__item__title}>
            <span>
              Оплата онлайн
            </span>
            <span>
              (комиссия 0%).
            </span>
          </div>
          <p className={styles.list__item__text}>
            <strong>ВНИМАНИЕ!</strong> Оплата картой рассрочки `халва` возможна только в пункте
            самовывоза интернет-магазина parfumart.ru или онлайн. Оплата через
            терминал транспортной компании не пройдет, так как возможна только
            через терминалы партнеров данной программы рассрочки. При оплате
            картой `халва` дополнительные скидки не применяются, так как наша
            компания уже несет расходы по предоставлению и обслуживанию рассрочки.
            Применение подарочного сертификата также невозможно.
          </p>
          <p className={styles.list__item__text}>
            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
            платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача
            информации осуществляется в защищенном режиме с использованием протокола
            шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного
            проведения интернет-платежей Verified By Visa или MasterCard SecureCode
            для проведения платежа также может потребоваться ввод специального пароля.
            Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
            сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная
            информация не будет предоставлена третьим лицам за исключением случаев,
            предусмотренных законодательством РФ. Проведение платежей по банковским
            картам осуществляется в строгом соответствии с требованиями платежных
            систем МИР, Visa Int. и MasterCard Europe Sprl.
          </p>
          <p className={styles.list__item__text}>
            Обращаем Ваше внимание, что наш сайт не хранит введенную вами информацию
            по платежу кредитной картой. Эту информацию вы вводите на сайте 3ds.mmbank.ru.,
            который официально одобрен Visa и Master Card (имеет соответствующие
            логотипы Verified by VISA и MasterCard SecureCode). Безопасность платежей с
            помощью банковских карт обеспечивается технологиями защищенного соединения
            HTTPS и двухфакторной аутентификации пользователя 3D Secure.
          </p>
          <span className={styles.list__item__text_postscript}>
            * В соответствии с ФЗ «О защите прав потребителей» в случае, если вам была не оказана
            услуга или реализован товар ненадлежащего качества, платеж будет возвращен на банковскую
            карту, с которой производилась оплата. Порядок возврата средств уточняйте
            у администрации интернет-магазина.
          </span>
        </li>
      </ol> */}
    </>
  );
};

export default BankCardHalvaPayment;