import { getRequestString } from "../utils/helpers/requestDataHelper";

/**
 * Fetches the delivery address based on the provided query, city, latitude, and longitude.
 *
 * @param {Object} options - An object containing the query, city, latitude, and longitude.
 * @param {string} options.query - The query string.
 * @param {string} options.city - The city name.
 * @param {number} options.lat - The latitude.
 * @param {number} options.lon - The longitude.
 * @return {Promise} A promise that resolves to the JSON response of the fetch request.
 */

const getDeliveryAddress = async (options) => {
  const requestString = getRequestString(options);

  const res = await fetch(`${process.env.REACT_APP_DELIVERY_URL}/api/public/addresses${requestString}`, {
    method: "GET",
  });

  return res.json();
};

/**
 * Retrieves a list of providers based on the specified city, available operations, and providers.
 *
 * @param {Object} options - An object containing the following parameters:
 * @param {String} city (required) - The city for which to retrieve the list of providers.
 * @param {Array} availableOperations (optional) - An array of available operations. Possible values: 1, 2, 3.
 * @param {Array} providers (required) - An array of providers.
 * @return {Promise} A promise that resolves to the JSON response from the API. Possible values: PROVIDERS.type[].
 */

const getProvidersList = async (options) => {
  const requestString = getRequestString(options);

  const res = await fetch(`${process.env.REACT_APP_DELIVERY_URL}/api/public/delivery/pvz${requestString}`, {
    method: "GET",
  });

  return res.json();
};

/**
 * Retrieves the delivery cost by making a GET request to the delivery API.
 *
 * @param {Object} options - The delivery options.
 * @param {String} options.city (required) - The city for the delivery.
 * @param {Array} options.providers (required) - The list of available providers. Possible values: PROVIDERS.type[].
 * @param {Number} options.assessedCost (required) - The assessed cost for the delivery.
 * @return {Promise} A promise that resolves to the JSON response from the API.
 */

const getDeliveryCost = async (options) => {
  const requestString = getRequestString(options);

  const res = await fetch(`${process.env.REACT_APP_DELIVERY_URL}/api/public/delivery/calculate${requestString}`, {
    method: "GET",
  });

  return res.json();
};

export const deliveryApi = {
  getDeliveryAddress,
  getProvidersList,
  getDeliveryCost
};