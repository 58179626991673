import React from "react"; // eslint-disable-line no-unused-vars
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { getSiteTitle } from "./index";
import logo from "../assets/parfumart_logo.png";

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

const defaultProps = {
  description: "Интернет-магазин парфюмерии и косметики. Большой выбор оригинальной парфюмерии и профессиональной косметики с доставкой по РФ. Низкие цены на парфюмы и косметику для женщин и мужчин, постоянные скидки и акции",
};

export default function HeadPageData (props) {
  const {
    title, description,
  } = props;

  const getCanonicalUrl = (url) => {
    const urlObj = new URL(url);
    const baseUrl = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`;
    const searchParams = urlObj.searchParams;

    const paramsToKeep = ["kategoria"];
    let canonicalParams = "";

    paramsToKeep.forEach(param => {
      if (searchParams.has(param)) {
        canonicalParams += `${canonicalParams ? "&" : "?"}${param}=${searchParams.get(param)}`;
      }
    });

    return baseUrl + canonicalParams;
  };

  const canonicalUrl = getCanonicalUrl(window.location.href);

  return (
    <Helmet>
      <title>{getSiteTitle(title)}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:title" content={getSiteTitle(title)} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={logo} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={getSiteTitle(title)} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
}

HeadPageData.propTypes = propTypes;
HeadPageData.defaultProps = defaultProps;
