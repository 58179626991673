import { PAYMENT_METHODS } from "../../../../../utils/constants";
import { DefaultPaymentMethodItem, DolyamiPaymentMethodItem } from "./components/PaymentMethodItem/PaymentMethodItem";

import styles from "./styles.module.scss";

const PaymentMethod = () => {
  const filteredPaymentMethod = (item) => {
    const allowedTypes = ["tinkoff_pay", "yandex_pay", "yandex_split", "sbp", "sber_pay", "dolymi", "card_online", "card_receipt"];
    return allowedTypes.includes(item.type);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <span className={styles.title__step}>
          3/3
        </span>
        <h4 className={styles.title__text}>
          Способ оплаты
        </h4>
        <div />
      </div>
      {PAYMENT_METHODS.map((item, idx) => {
        if (filteredPaymentMethod(item)) return;
        if (item.type === "dolymi") {
          return (
            <DolyamiPaymentMethodItem
              key={`${item.type}-${idx}`}
              type={item.type}
              title={item.title}
            />
          );
        }
        return (
          <DefaultPaymentMethodItem
            key={`${item.type}-${idx}`}
            type={item.type}
            title={item.title}
          />
        );
      })
      }
    </div>
  );
};

export default PaymentMethod;