import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  setProviderInfo,
  setUserDeliveryAddress,
} from "../../../../../store/user/user";
import { WAY_TO_GET } from "../../../../../utils/constants";

import geoIcon from "../../../../../assets/header_icons/icon_geo.svg";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

import styles from "./styles.module.scss";


const SelectedAddressFromBallonBlock = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const acceptAddress = () => {

    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })[0];

    const findedItem = props.providersInfo[wayToGetKey].find(
      (item) => item.deliveryProvider === props.selectedProviderData.deliveryProviderType
    );


    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(setUserDeliveryAddress(props.selectedProviderData.address));
    dispatch(
      setProviderInfo({
        provider: findedItem.deliveryProvider,
        amount: findedItem.deliveryCost, // стоимость доставки,
        daysMax: findedItem.daysMax,
        daysMin: findedItem.daysMin,
        type: deliveryType,
      })
    );
    props.onCloseModal();
  };

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>Как доставить заказ?</h2>
      <div className={styles["delivery-info"]}>
        <h2 className={styles["delivery-info__title"]}>Выбранный ПВЗ</h2>
        <p className={styles["delivery-info__provider"]}>
          {props.selectedProviderData?.deliveryProvider}
        </p>
        <p className={styles["delivery-info__schedule"]}>
          {props.selectedProviderData?.timeTable}
        </p>
        <div className={styles["delivery-info__address-wrapper"]}>
          <img
            src={geoIcon}
            alt="geo_icon"
            className={styles["delivery-info__address-icon"]}
          />
          <p className={styles["delivery-info__address-description"]}>
            {props.selectedProviderData?.address}
          </p>
        </div>
      </div>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Подтвердить выбор"
          onClick={acceptAddress}
          className={styles["select-button"]}
        />
        <button
          onClick={props.returnToPvzSelect}
          className={styles["additional-button"]}
        >
          Вернуться к выбору адреса
        </button>
      </div>
    </section>
  );
};

const propTypes = {
  selectedProviderData: PropTypes.object,
  providersInfo: PropTypes.object,
  onCloseModal: PropTypes.func,
  returnToPvzSelect: PropTypes.func,
};

SelectedAddressFromBallonBlock.propTypes = propTypes;

export default SelectedAddressFromBallonBlock;
