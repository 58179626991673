import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { removeUser } from "../../../store/user/user";
import { removeTokens } from "../../../utils/localStorage";

import Portal from "../../../HOC/Portal";
import PersonalAreaNavigationContent from "../../../components/PersonalAreaNavigationContent";
import ExitModal from "../ExitModal/ExitModal";

import styles from "./styles.module.scss";
import { getUserCart } from "../../../store/user/cartThunks/cartThunks";

const PersonalAreaModal = (props) => {
  const [isShowExitModal, setIsShowExitModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseExitModal = ({ isExit }) => {
    if (isExit) {
      removeTokens();
      dispatch(removeUser());
      navigate("/", { replace: true });
      props.onClose();
      setIsShowExitModal(false);
      dispatch(getUserCart({}));
      return;
    }
    setIsShowExitModal(false);
  };

  return (
    <div className={styles.root}>
      <div ref={props.modalRef}>
        <PersonalAreaNavigationContent
          containerStyles={styles["personal-area__navigation"]}
          onClose={props.onClose}
          onContinueExit={() => setIsShowExitModal(true)}
        />
        {
          isShowExitModal &&
          <ExitModal
            onClose={onCloseExitModal}
          />
        }
      </div>
      <div className={styles.background} />
    </div>
  );
};

const propTypes = {
  modalRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

PersonalAreaModal.propTypes = propTypes;

export default Portal(PersonalAreaModal);