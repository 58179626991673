import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import phoneNumberMask from "../../utils/inputMasks/phoneNumberMask";

import cn from "classnames";
import styles from "./styles.module.scss";

const CustomAuthInput = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);

  const showError = useMemo(() => {
    if (props.isSubmitting === false) return false;
    return !props.value ? props.showErrorsOnSubmit : !!props.errorValue;
  }, [props.showErrorsOnSubmit, props.errorValue, props.value, props.isSubmitting]);

  const maxInputLength = useMemo(() => {
    return props.inputType === "tel" ? "18" : "";
  }, [props.inputType]);

  const onChangeHandler = (event) => {
    if (props.inputType === "tel") {
      phoneNumberMask(event);
    }

    props.onChange(event);
    setInputValue(event.target.value);
  };

  return (
    <div className={cn(styles.root, props.containerClassName)}>
      <input
        name={props.name}
        type={showPassword ? "text" : props.inputType}
        placeholder={props.placeholder}
        className={cn(styles.input, {
          [styles["input--error"]]: showError || props.showErrorIfErrorInResponse,
        }, props.className)}
        value={inputValue}
        onChange={onChangeHandler}
        maxLength={maxInputLength}
      />
      {props.inputType === "password" && inputValue.length > 0 &&
        <button
          className={styles["show-password"]}
          onClick={() => setShowPassword(!showPassword)}
          type="button"
        >
          {!showPassword ? "Показать" : "Скрыть"}
        </button>
      }
      {showError &&
        <span className={styles["error-message"]}>
          {props.errorValue}
        </span>
      }
    </div>
  );
};

const propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorValue: PropTypes.string,
  showErrorsOnSubmit: PropTypes.bool,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  showErrorIfErrorInResponse: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

CustomAuthInput.propTypes = propTypes;

export default CustomAuthInput;