import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import ReactSlider from "react-slider";

import styles from "./styles.module.scss";

const MultiRangeSlider = (props) => {
  const [minVal, setMinVal] = useState(props.minPrice);
  const [maxVal, setMaxVal] = useState(props.maxPrice);

  const minValInputRef = useRef(null);
  const maxValInputRef = useRef(null);

  const onChangePrice = (value, type) => {
    props.onChange(value[0], value[1]);
    if (type === 1) {
      setMaxVal(value[1]);
      return;
    }
    setMinVal(value[0]);
  };

  const onChangeMinPrice = (event) => {
    if(isNaN(event.target.value)) return;

    const value = Math.min(Number(event.target.value), maxVal);
    setMinVal(value < props.min ? props.min : value);
  };

  const onChangeMaxPrice = (event) => {
    if(isNaN(event.target.value)) return;

    const validMaxValue = Math.min(Number(event.target.value), props.max);
    const value = Math.max(validMaxValue, minVal);
    setMaxVal(value);
  };

  const onMouseUpHandler = () => {
    props.onChange(minVal, maxVal);
  };

  useEffect(() => {
    if (!minValInputRef.current && !maxValInputRef.current) return;

    minValInputRef.current.style.width =  `${((String(minVal).length + 1) * 10) + 16}px`;
    maxValInputRef.current.style.width = `${((String(maxVal).length + 1) * 10) + 16}px`;
  }, [minVal, maxVal]);

  useEffect(() => {
    if (!props.currentSelectedPrice.min_price && !props.currentSelectedPrice.max_price) {
      setMinVal(props.min);
      setMaxVal(props.max);
    }
  }, [props.currentSelectedPrice, props.min, props.max]);

  useEffect(() => {
    if (props.minPrice < props.min || !props.minPrice) {
      setMinVal(props.min);
    }
    if (props.maxPrice > props.max || !props.maxPrice) {
      setMaxVal(props.max);
    }
  }, [props.minPrice, props.maxPrice]);

  return (
    <div className={styles.root}>
      <div className={styles["price-content"]}>
        <div className={styles["price-content__min-price"]}>
          <span
            className={styles["price-content__min-price_text"]}
          >
            От
          </span>
          <input
            type="text"
            value={minVal}
            onChange={onChangeMinPrice}
            onBlur={onMouseUpHandler}
            className={styles["price-content__min-price_input"]}
            ref={minValInputRef}
          />
        </div>
        <div className={styles["price-content__max-price"]}>
          <span
            className={styles["price-content__min-price_text"]}
          >
            До
          </span>
          <input
            type="text"
            value={maxVal}
            onChange={onChangeMaxPrice}
            onBlur={onMouseUpHandler}
            className={styles["price-content__max-price_input"]}
            ref={maxValInputRef}
          />
        </div>
      </div>
      <ReactSlider
        className={styles.slider}
        thumbClassName={styles.slider_thumb}
        trackClassName={styles.slider_track}
        min={props.min}
        max={props.max}
        value={[minVal, maxVal]}
        defaultValue={[0, props.max]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        pearling
        minDistance={10}
        onAfterChange={(value, idx) => onChangePrice(value, idx)}
      />
    </div>
  );
};

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  currentSelectedPrice: PropTypes.object,
  onChange: PropTypes.func,
};

MultiRangeSlider.propTypes = propTypes;

export default MultiRangeSlider;
