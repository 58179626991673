import * as yup from "yup";

export const passwordSchema = () => {
  const messageForMinRequirement = "Пароль должен содержать минимум 6 символов";
  const messageForLengthRequirement = "Пароль не должен превышать 128 символов";
  const messageForLanguageRequirement = "Пароль должен содержать только латинские или кириллические буквы, цифры и специальные символы (без пробелов)";
  const messageForDigitRequirement = "Пароль должен содержать по крайней мере одну цифру";
  const messageForSpacesRequirement = "Пароль не должен содержать пробелов";
  const messageForSpecialCharactersRequirement = "Пароль должен содержать по крайней мере один особый символ: ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \\ | ' . , : ;";
  const messageForCaseRequirement = "Пароль должен содержать по крайней мере одну заглавную и одну строчную букву";

  return yup.string()
    .min(6, messageForMinRequirement)
    .max(128, messageForLengthRequirement)
    .matches(/^[a-zA-Zа-яА-Я0-9~!@#$%^&*()_+\-=[\]{};:'",.<>/?\\|]+$/, messageForLanguageRequirement)
    .matches(/[a-zа-я]/, messageForCaseRequirement)
    .matches(/[A-ZА-Я]/, messageForCaseRequirement)
    .matches(/\d/, messageForDigitRequirement)
    .matches(/^\S*$/, messageForSpacesRequirement)
    .matches(/[~!@#$%^&*_\-+=(){}[\]:;'",.<>?/\\|]+/, messageForSpecialCharactersRequirement);
};
