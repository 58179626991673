import { setProviderInfo, setUserDeliveryAddress } from "../../../../../../../store/user/user";
import { WAY_TO_GET } from "../../../../../../../utils/constants";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import CustomButton from "../../../../../../../components/CustomButton/CustomButton";

const ProviderInfo = (props) => {
  const { checkoutData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const acceptAddress = () => {
    const wayToGetKey = Object.entries(WAY_TO_GET).find((item) => {
      return item[1] === checkoutData.wayToGet;
    })[0];

    const findedItem = props.providersInfo[wayToGetKey].find(
      (item) => item.deliveryProvider === props.selectedProviderData.deliveryProviderType
    );

    const deliveryType =
      checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup";

    dispatch(setUserDeliveryAddress(props.selectedProviderData.address));
    dispatch(
      setProviderInfo({
        provider: findedItem.deliveryProvider,
        amount: findedItem.deliveryCost, // стоимость доставки,
        daysMax: findedItem.daysMax,
        daysMin: findedItem.daysMin,
        type: deliveryType,
      })
    );
    props.onCloseModal();
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Как доставить заказ?</h2>
      <div className={styles["delivery-info"]}>
        <h2 className={styles["delivery-info__title"]}>Выбранный ПВЗ</h2>
        <p className={styles["delivery-info__provider"]}>
          {props.selectedProviderData?.deliveryProvider}
        </p>
        <p className={styles["delivery-info__schedule"]}>
          {props.selectedProviderData?.timeTable}
        </p>
        <div className={styles["delivery-info__address-wrapper"]}>
          <p className={styles["delivery-info__address-description"]}>
            {props.selectedProviderData?.address}
          </p>
        </div>
      </div>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Подтвердить выбор"
          onClick={acceptAddress}
          className={styles["select-button"]}
        />
        <button
          onClick={props.returnToPvzSelect}
          className={styles["additional-button"]}
        >
          Вернуться к выбору адреса
        </button>
      </div>
    </div>
  );
};

const propTypes = {
  selectedProviderData: PropTypes.object,
  providersInfo: PropTypes.object,
  onCloseModal: PropTypes.func,
  returnToPvzSelect: PropTypes.func,
};

ProviderInfo.propTypes = propTypes;

export default ProviderInfo;