import CustomLink from "../../../../components/CustomLink";

import styles from "./styles.module.scss";

const RestorePassword = () => {
  return (
    <main className={styles.root}>
      <h2 className={styles.title}>Восстановление пароля</h2>
      <div className={styles["buttons-wrapper"]}>
        <CustomLink
          path="by-phone"
          title="С помощью телефона"
          className={styles.link}
        />
        <CustomLink
          path="by-email"
          title="С помощью e-mail"
          className={styles.link}
        />
      </div>
    </main>
  );
};

export default RestorePassword;
